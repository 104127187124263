import instance from '../../lib/axiosConfig';
import { notification } from 'antd';
import { runInAction, action, observable, decorate } from 'mobx';
import { USERS_SCREEN_NAME } from "./availableScreens";

class UsersStore {
  users = null;
  user = {};
  branches = null;
  allBranches = null;
  branch = {};
  userBranches = null;
  branchBenches = null;
  currentActiveScreen = USERS_SCREEN_NAME;

  setCurrentActiveScreen = (screenName) => {
    this.currentActiveScreen = screenName;
  }

  loadUsers = async (params = {}) => {
    try {
      const result = await instance.get('/users', {
        params
      });

      runInAction(() => {
        this.users = result.data;
      });
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error loading users!',
          description:
            'Something went wrong while loading users! It might be up to server.',
        });
      });
    }
  };

  searchUser = async (userName) => {
    return this.loadUsers({
      term: userName
    });
  };

  loadBranches = async (free) => {
    try {
      const result = await instance.get(`/branches?free=${free}`);

      runInAction(() => {
        this.branches = result.data;
      });
      return result.data
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error loading branches!',
          description:
            'Something went wrong while loading branches! It might be up to server.',
        });
      });
    }
  };

  loadAllBranches = async (params = {
    free: false,
    term: ''
  }) => {
    try {
      const result = await instance.get(`/branches/all`, {
        params
      });

      runInAction(() => {
        this.branches = result.data;
      });
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error loading branches!',
          description:
              'Something went wrong while loading branches! It might be up to server.',
        });
      });
    }
  }

  searchAllBranches = async (term) => {
    return this.loadAllBranches({
      term
    });
  }

  loadUserBranches = (branches) => {
    try {
      runInAction(() => {
        if (branches) {
          this.userBranches = branches;
        }
      });
    } catch (err) {
      notification.error({
        message: 'Error loading user branches!',
        description:
          'Something went wrong while loading user branches! It might be up to server.',
      });
    }
  };

  addUserBranch = (branch) => {
    try {
      runInAction(() => {
        if (this.userBranches) {
          this.userBranches.push(branch);
        }
      });
    } catch (err) {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong while adding user branch.',
      });
    }
  };

  removeUserBranch = (branchId) => {
    try {
      runInAction(() => {
        this.userBranches = this.userBranches.filter(
          (branch) => branch.id !== branchId
        );
      });
    } catch (err) {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong while deleting user branch.',
      });
    }
  };
  loadBranchBenches = (benches) => {
    try {
      runInAction(() => {
        if (benches) {
          this.branchBenches = benches;
        }
      });
    } catch (err) {
      notification.error({
        message: 'Error loading branch benches!',
        description:
          'Something went wrong while loading branch benches! It might be up to server.',
      });
    }
  };

  addBranchBench = (bench) => {
    try {
      runInAction(() => {
        if (this.branchBenches) {
          this.branchBenches.push(bench);
        }
      });
    } catch (err) {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong while adding branch bench.',
      });
    }
  };

  removeBranchBench = (benchId) => {
    try {
      runInAction(() => {
        this.branchBenches = this.branchBenches.filter(
          (bench) => bench.id !== benchId
        );
      });
    } catch (err) {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong while deleting branch bench.',
      });
    }
  };

  getUser = async (userId) => {
    try {
      const result = await instance.get(`/users/${userId}`);

      runInAction(() => {
        this.user = result.data;
      });
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error loading user!',
          description:
            'Error occured while trying to retrieve user data! It might be up to server.',
        });
      });
    }
  };

  getBranch = async (branchId) => {
    try {
      const result = await instance.get(`/branches/${branchId}`);

      runInAction(() => {
        this.branch = result.data;
      });
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error loading user!',
          description:
            'Error occured while trying to retrieve branch data! It might be up to server.',
        });
      });
    }
  };

  addUser = async (userData) => {
    try {
      await instance.post('/users', userData);
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error adding user!',
          description:
            'Most likely you tried to add an user that already exists. Please check your user data again.',
        });
      });
    }
  };

  addBranch = async (branchData) => {
    try {
      await instance.post('/branches', branchData);
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error adding branch!',
          description:
            'Most likely you tried to add a branch that already exists. Please check your branch data again.',
        });
      });
    }
  };

  updateUser = async (userId, userData) => {
    try {
      await instance.put(`/users/${userId}`, userData);
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error updating user!',
          description:
            'There is an error occured while trying to update this user.',
        });
      });
    }
  };

  updateBranch = async (branchId, branchData) => {
    try {
      await instance.put(`/branches/${branchId}`, branchData);
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error updating branch!',
          description:
            'There is an error occured while trying to update this branch.',
        });
      });
    }
  };

  deleteNotifications = async () => {
    try {
      await instance.delete('/users/notifications/');
    } catch (error) {
      console.log(error);
    }
  };

  markNotificationsAsRead = async (userId, notificationsData) => {
    try {
      await instance.put(`/users/notifications/${userId}`, notificationsData);
    } catch (error) {
      console.log(error);
    }
  };

  removeUser = async (userId) => {
    try {
      await instance.delete(`/users/${userId}`);
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error deleting user!',
          description:
            'There is an error occured while trying to delete this user.',
        });
      });
    }
  };

  removeBranch = async (branchId) => {
    try {
      if (this.branch.benches.length > 0) {
        const branch = {
          name: this.branch.name,
          note: this.branch.note,
          benches: [],
        };
        await this.updateBranch(branchId, branch);
      }
      await instance.delete(`/branches/${branchId}`);
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error deleting branch!',
          description:
            'There is an error occured while trying to delete this branch.',
        });
      });
    }
  };
}

decorate(UsersStore, {
  users: observable.ref,
  user: observable.ref,
  branches: observable.ref,
  userBranches: observable.ref,
  branchBenches: observable.ref,
  addUser: action,
  searchUser: action,
  removeUser: action,
  updateUser: action,
  addBranch: action,
  addUserBranch: action,
  removeUserBranch: action,
  addBranchBench: action,
  removeBranchBench: action,
  deleteNotifications: action,
  markNotificationsAsRead: action,
});

export default new UsersStore();
