import React from 'react';
import { Modal as AntModal } from 'antd';

import './modal.style.scss';

const Modal = ({
  afterClose,
  bodyStyle,
  cancelText,
  centered,
  closable,
  closeIcon,
  confirmLoading,
  destroyOnClose,
  footer,
  forceRender,
  getContainer,
  keyboard,
  mask,
  maskClosable,
  maskStyle,
  okText,
  okType,
  okButtonProps,
  cancelButtonProps,
  style,
  title,
  visible,
  width,
  wrapClassName,
  zIndex,
  onCancel,
  onOk,
  ...props
}) => {
  return (
    <AntModal
      afterClose={afterClose}
      bodyStyle={bodyStyle}
      cancelText={cancelText}
      centered={centered}
      closable={closable}
      closeIcon={closeIcon}
      confirmLoading={confirmLoading}
      destroyOnClose={destroyOnClose}
      footer={footer}
      forceRender={forceRender}
      getContainer={getContainer}
      keyboard={keyboard}
      mask={mask}
      maskClosable={maskClosable}
      okText={okText}
      okType={okType}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      style={style}
      title={title}
      visible={visible}
      width={width}
      wrapClassName={wrapClassName}
      zIndex={zIndex}
      onCancel={onCancel}
      onOk={onOk}
      {...props}
    >
      {props.children}
    </AntModal>
  );
};

export default Modal;
