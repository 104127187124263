import React from 'react';
import { List } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import translate from '../../../../i18n/translate';

import Spinner from '../../../ui-elements/Spinner/spinner.component';
import './dashboard-card-list.style.scss';

const DashboardCardList = (props) => {
  return (
    <div className="card-list__container">
      {!props.listItems ? (
        <div style={{ width: '100%' }}>
          <Spinner
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
            size="large"
            style={{ width: '100%' }}
            tip={translate('Loading...')}
          />
        </div>
      ) : props.listItems && props.listItems.length === 0 ? (
        props.type === 'alarms' ? (
          <span className="card-list__empty-list-message">
            {translate('There are no alarms to show!')}
          </span>
        ) : props.type === 'requests' ? (
          <span className="card-list__empty-list-message">
            {translate('There are no requests to show!')}
          </span>
        ) : (
          <span className="card-list__empty-list-message">
            {translate('There are no activities to show!')}
          </span>
        )
      ) : (
        <List
          size="small"
          className="card-list"
          bordered
          dataSource={props.listItems}
          loading={!props.listItems}
          renderItem={(item) => (
            <List.Item
              style={{
                borderBottom:
                  props.listItems &&
                  (props.listItems.length === 0 || props.listItems.length === 1)
                    ? 0
                    : false,
              }}
              className="card-list__item"
              onClick={
                props.type === 'requests' && item && item.name
                  ? () => props.openModalHandler(item)
                  : null
              }
            >
              {props.type === 'alarms' ? (
                item.text ? (
                  <span>{item.text}</span>
                ) : (
                  translate('List is empty.')
                )
              ) : props.type === 'requests' ? (
                item && item.user ? (
                  <span>
                    {item.user.name} {translate('sent content request')}
                  </span>
                ) : (
                  translate('List is empty.')
                )
              ) : item ? (
                `${item.name} (${item.timestamp})`
              ) : (
                translate('List is empty.')
              )}
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default DashboardCardList;
