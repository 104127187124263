import React, { useEffect } from 'react';

import Dashboard from '../components/containers/Dashboard/dashboard.components';

const HomeScreen = (props) => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <React.Fragment>
      <Dashboard />
    </React.Fragment>
  );
};

export default HomeScreen;
