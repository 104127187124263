import React from 'react';
import instance from '../../lib/axiosConfig';
import { notification, Button } from 'antd';
import { runInAction, action, observable, decorate } from 'mobx';
import Auth from '../../utils/auth';
import { ERRORS } from "../../constants/errors";

class BenchStore {
  benches = null;
  bench = {};
  savedBenchData = {};
  report = null;
  aws = {};

  loadPublicBenches = async () => {
    try {
      const result = await instance.get(`/benches`);

      if (result && result.data) {
        runInAction(() => {
          this.benches = result.data;
        });
      }
    } catch (err) {
      console.log('>>>error', err);
    }
  }

  loadBenches = async (free = false, params = {}) => {
    try {
      const result = await instance.get(`/benches/all`, {
        params: {
          free,
          ...params
        }
      });

      if (result && result.data) {
        runInAction(() => {
          this.benches = result.data;
        });
      }
    } catch (err) {
      console.log('>>>error', err);
    }
  };

  getBench = async (benchId, params = {}) => {
    try {
      const result = await instance.get(`/benches/${benchId}`, {
        params
      });

      if (result && result.data) {
        runInAction(() => {
          this.bench = result.data;
        });
      }
    } catch (err) {
      throw ({
        message:'Error loading bench!',
        description:
          ('Something went wrong while loading bench! It might be up to server.'),
      });
    }
  };

  searchBench = async (benchId) => {
    return this.loadBenches(false, {
      term: benchId
    });
  };

  addBench = async (benchData) => {
    this.savedBenchData = benchData;
    try {
      await instance.post('/benches', benchData);
    } catch (err) {
      if(err.response?.data?.error === ERRORS.benchGivenIdNotUnique) {
        return Promise.reject(ERRORS.benchGivenIdNotUnique);
      }

      runInAction(() => {
        notification.error({
          message: 'Error adding bench!',
          description:
            'There is an error occured while adding a bench. Try again?',
          btn: (
            <Button
              type="danger"
              size="middle"
              onClick={() => this.addBench(this.savedBenchData)}
            >
              Retry
            </Button>
          ),
        });
      });
    }
  };

  updateBench = async (benchId, benchData) => {
    try {
      await instance.put(`/benches/${benchId}`, benchData);
    } catch (err) {
      if(err.response?.data?.error === ERRORS.benchGivenIdNotUnique) {
        return Promise.reject(ERRORS.benchGivenIdNotUnique);
      }

      notification.error({
        message: 'Error updating bench!',
        description:
          'Something went wrong while updating bench! It might be up to server.',
      });
    }
  };

  deleteBench = async (benchId) => {
    try {
      await instance.delete(`/benches/${benchId}`);
    } catch (err) {
      notification.error({
        message: 'Error deleting bench!',
        description: 'Something went wrong while deleting bench! It might be up to server.'
      });
      throw new Error("Bench can't be deleted.");
    }
  }

  exportReports = async (reportData) => {
    try {
      let responseReport = await instance.post('benches/reports', reportData, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([responseReport.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      notification.error({
        message: 'Error exporting reports!',
        description:
          'Something went wrong while exporting reports! It might be up to server.',
      });
    }
  };

  getAwsCertificates = async (benchId) => {
    const token = Auth.getToken();
    const decodedToken = Auth.decodeToken(token);

    try {
      const result =
        decodedToken.role === 'superAdmin'
          ? await instance.get(`/benches/rpi/${benchId}`)
          : null;

      if (result) {
        runInAction(() => {
          this.aws = result.data;
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error retrieving aws certificates!',
        description:
          'Something went wrong while retrieving aws cetrificates for this bench! It might be up to server.',
      });
    }
  };
  getAlarms = async () => {
    try {
      let alarmsResponse = await instance.get(`/benches/alarm`);
      return alarmsResponse.data;
    } catch (error) {
      console.log('error', error);
    }
  };
}

decorate(BenchStore, {
  benches: observable.ref,
  bench: observable.ref,
  report: observable,
  aws: observable.ref,
  addBench: action,
  getBench: action,
  loadBenches: action,
  updateBench: action,
  updateBenchTemperature:action,
  searchBench: action,
  exportReports: action,
  getAwsCertificates: action,
});

export default new BenchStore();
