import { LOCALES } from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    'No bench to show': 'There is no bench to show',
    Search: 'Search',
    'Change language': 'Change language',
    'United Kingdom': 'United Kingdom',
    'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
    Notifications: 'Notifications',
    Options: 'Options',
    'Clear all': 'Clear all',
    'Mark all as read': 'Mark all as read',
    'Profile options': 'Profile options',
    'Change password': 'Change password',
    Logout: 'Logout',
    Alarms: 'Alarms',
    Requests: 'Requests',
    History: 'History',
    'There are no alarms to show!': 'There are no alarms to show!',
    'There are no requests to show!': 'There are no requests to show!',
    'There are no activities to show!': 'There are no activities to show!',
    Home: 'Home',
    Users: 'Users',
    Benches: 'Benches',
    Content: 'Content',
    'Add Bench': 'Add Bench',
    'ADD BENCH': 'ADD BENCH',
    'Reset Password': 'Reset Password',
    Password: 'Password',
    'New password': 'New password',
    'Confirm new password': 'Confirm new password',
    'Please enter your password!': 'Please enter your password!',
    'Please enter your new password!': 'Please enter your new password!',
    'Password has to contain at least 6 characters.':
      'Password has to contain at least 6 characters.',
    'Passwords have to match!': 'Passwords have to match!',
    'Please confirm your password!': 'Please confirm your password!',
    Login: 'Login',
    'E-mail': 'E-mail',
    'Please enter a valid E-mail.': 'Please enter a valid E-mail.',
    'Please enter your E-mail.': 'Please enter your E-mail.',
    'Please enter your password.': 'Please enter your password.',
    'Forgot password?': 'Forgot password?',
    'Enter the email address you used when you joined and we’ll send you instructions to reset your password.':
      'Enter the email address you used when you joined and we’ll send you instructions to reset your password.',
    'Email Address': 'Email Address',
    Send: 'Send',
    'Are you sure you want to delete this client?':
      'Are you sure you want to delete this client?',
    'Add Client': 'Add Client',
    'ADD CLIENT': 'ADD CLIENT',
    'Add Branch': 'Add Branch',
    'ADD BRANCH': 'ADD BRANCH',
    'If you want to add or remove branch for a certain client, find wanted client in clents list, go to options button and select "Add/Remove Branch" in drop down menu.':
      'If you want to add or remove branch for a certain client, find wanted client in clents list, go to options button and select "Add/Remove Branch" in drop down menu.',
    'If you want to add or remove a bench for a certain branch, find wanted branch in clents list, go to options button and select "Add/Remove Bench" in drop down menu.':
      'If you want to add or remove a bench for a certain branch, find wanted branch in clents list, go to options button and select "Add/Remove Bench" in drop down menu.',
    CLIENTS: 'CLIENTS',
    BRANCHES: 'BRANCHES',
    'Delete Client': 'Delete Client',
    'Edit Client': 'Edit Client',
    'Add/Remove Branch': 'Add/Remove Branch',
    branches: 'branches',
    'No users found.': 'No users found.',
    Active: 'Active',
    Inactive: 'Inactive',
    'Loading clients...': 'Loading clients...',
    'Loading branches...': 'Loading branches...',
    'Delete Branch': 'Delete Branch',
    'Edit Branch': 'Edit Branch',
    'Add/Remove Bench': 'Add/Remove Bench',
    benches: 'benches',
    'No branches found.': 'No branches found.',
    'Active Bench': 'Active',
    'Inactive Bench': 'Inactive',
    'ADD CONTENT': 'ADD CONTENT',
    'Content Name': 'Content Name',
    'Please enter content name.': 'Please enter content name.',
    'Please select start and end time.': 'Please select start and end time.',
    Branch: 'Branch',
    'Drop content here or': 'Drop content here or',
    browse: 'browse',
    'Start and end time': 'Start and end time',
    'No branch selected.': 'No branch selected.',
    'No benches added.': 'No benches added.',
    'Delete Content': 'Delete Content',
    'Move Content': 'Move Content',
    'No content found.': 'No content found.',
    'Loading content...': 'Loading content...',
    'Bench list': 'Bench list',
    'Please select bench(benches) you want to add content to.':
      'Please select bench(benches) you want to add content to.',
    Pending: 'Pending',
    'Content request': 'Content request',
    'List is empty.': 'List is empty.',
    'You have to select branch or bench first!':
      'You have to select branch or bench first!',
    Download: 'Download',
    Approved: 'Approved',
    Declined: 'Declined',
    'Loading...': 'Loading...',
    Comment: 'Comment',
    'Edit Content': 'Edit Content',
    'Ad Blocker warning!': 'Ad Blocker warning!',
    'Warning!': 'Warning!',
    'If you have your Ad Blocker active or any similar extension, please disable it and click on "Reload" button to refresh the page.':
      'If you have your Ad Blocker active or any similar extension, please disable it and click on "Reload" button to refresh the page.',
    Reload: 'Reload',
    Add: 'Add',
    'Select Period': 'Select Period',
    'CPU Temperature': 'CPU Temperature',
    Export: 'Export',
    'Loading benches...': 'Loading benches...',
    Cancel: 'Cancel',
    'Export Reports': 'Export Reports',
    Accept: 'Accept',
    '°C Offset': '°C Offset',
    'Bench ID': 'Bench ID',
    'Bench ID must be atleast 3 characters':
      'Bench ID must be atleast 3 characters',
    Type: 'Type',
    Wood: 'Wood',
    Solar: 'Solar',
    Wheel: 'Wheel',
    Sensors: 'Sensors',
    'Air (temperature, pressure, humidity)':
      'Air (temperature, pressure, humidity)',
    'GPS (bench location)': 'GPS (bench location)',
    'CO (Carbon Monoxide)': 'CO (Carbon Monoxide)',
    Power: 'Power',
    'Clear All Fields': 'Clear All Fields',
    'Show benches': 'Show benches',
    'Generate config file for bench': 'Generate config file for bench',
    Configuration: 'Configuration',
    Shuffle: 'Shuffle',
    Unavailable: 'Unavailable',
    Humidity: 'Humidity',
    Temperature: 'Temperature',
    'CPU Temperature / °C': 'CPU Temperature / °C',
    'Temperature / °C': 'Temperature / °C',
    'Air Pressure / Mbar': 'Air Pressure / Mbar',
    'Air Quality / Ppm': 'Air Quality / Ppm',
    'Wireless Charging / Wh': 'Wireless Charging / Wh',
    'USB Charging / Wh': 'USB Charging / Wh',
    'Wi-Fi Users / h': 'Wi-Fi Users / h',
    'E-Charging / Wh': 'E-Charging / Wh',
    'Humidity / %': 'Humidity / %',
    'Electricity Consumption / Wh': 'Electric Consumption / Wh',
    'Battery / V': 'Battery / V',
    'Solar / kW': 'Solar / kW',
    'Wireless chargings': 'Wireless chargings',
    'Wifi Chargings': 'Wifi chargings',
    'Wi-fi users': 'Wifi Users',
    'Wh consumed': 'Wh consumed',
    'WiFi Users': 'WiFi Users',
    'Download as:': 'Download as:',
    'There is no branch with this name.': 'There is no branch with this name.',
    'WiFi monitor': 'WiFi monitor',
    'Air pressure': 'Air pressure',
    'Air quality': 'Air quality',
    'Solar power': 'Solar power',
    'USB chargins': 'USB chargings',
    'E-chargings': 'E-chargings',
    'Wireless charging': 'Wireless charging',
    'Sunny Hours': 'Sunny Hours',
    'Battery status': 'Battery status',
    'Electricity generation': 'Electricity generation',
    'Electricity consumption': 'Electricity consumption',
    'Last day': 'Last day',
    'Last 7 days': 'Last 7 days',
    Offset: 'Offset',
    'Temperature offset': 'Temperature offset',
    'WiFi Charging offset': 'WiFi Charging offset',
    'USB Charging offset': 'USB Charging offset',
    'E Charging offset': 'E Charging offset',
    Location: 'Location',
    Owner: 'Owner',
    Status: 'Status',
    'Air Quality': 'Air Quality',
    'Usage summary': 'Usage summary',
    Save: 'Save',
    Bench: 'Bench',
    'There is no bench with this ID.': 'There is no bench with this ID.',
    'There are no benches under this branch.':
      'There are no benches under this branch.',
    Name: 'Name',
    Notes: 'Notes',
    Confirm: 'Confirm',
    'Error uploading image!': 'Error uploading image!',
    'This image is too big. Try with a smaller image(1920x1080 or less).':
      'This image is too big. Try with a smaller image(1920x1080 or less).',
    'Error uploading file!': 'Error uploading file!',
    'This file is too big. Try with the smaller file(less then 50MB).':
      'This file is too big. Try with the smaller file(less then 50MB).',
    'Are you sure you want to delete this content?':
      'Are you sure you want to delete this content?',
    'sent content request': 'sent content request',
    Close: 'Close',
    'E-mail is not valid': 'E-mail is not valid',
    'Please enter your E-mail!': 'Please enter your E-mail!',
    'Something went wrong!!!': 'Something went wrong!!!',
    'Error loading maps...': 'Error loading maps...',
    'Loading map...': 'Loading map...',
    'There are no new notifications!': 'There are no new notifications!',
    'Something went wrong!': 'Something went wrong!',
    'First name': 'First name',
    'Please enter your first name': 'Please enter your first name',
    'Last name': 'Last name',
    'Please enter your last name': 'Please enter your last name',
    'Please enter a password!': 'Please enter a password!',
    'Confirm Password': 'Confirm Password',
    Register: 'Register',
    'There are no branches within this client.':
      'There are no branches within this client.',
    'Clear all fields': 'Clear all fields',
    'Please enter your full name!': 'Please enter your full name!',
    PIB: 'PIB',
    'Please enter your PIB!': 'Please enter your PIB!',
    Address: 'Address',
    'Please enter your address!': 'Please enter your address!',
    'Name and surname (RP)': 'Name and surname (RP)',
    'Name of the RP.': 'Name of the RP.',
    'Please enter RP full name here!': 'Please enter RP full name here!',
    City: 'City',
    'Please enter your city!': 'Please enter your city!',
    State: 'State',
    'Please enter your state!': 'Please enter your state!',
    Phone: 'Phone',
    'Phone number is required': 'Phone number is required',
    'E-mail (RP)': 'E-mail (RP)',
    'Email of the RP.': 'Email of the RP.',
    'Login Email': 'Login Email',
    'Please enter your email!': 'Please enter your email!',
    JIB: 'JIB',
    'Please enter your JIB! At least 13 characters is required':
      'Please enter your JIB! At least 13 characters is required',
    'Receive a newsletter?': 'Receive a newsletter?',
    'Please enter branch name!': 'Please enter branch name!',
    "Please enter branch owner's name!": "Please enter branch owner's name!",
    'Error while logging in!': 'Error while logging in!',
    'Error occured!': 'Error occured!',
    'Success!': 'Success!',
    'Error loading benches!': 'Error loading benches!',
    'Error loading bench!': 'Error loading bench!',
    'Error adding bench!': 'Error adding bench!',
    'Error updating bench!': 'Error updating bench!',
    'Error exporting reports!': 'Error exporting reports!',
    'Please select a date period': 'Please select a date period',
    'Error retrieving aws certificates!': 'Error retrieving aws certificates!',
    'Error loading content!': 'Error loading content!',
    'Error uploading content!': 'Error uploading content!',
    'Error deleting content!': 'Error deleting content!',
    'Error loading users!': 'Error loading users!',
    'Error loading branches!': 'Error loading branches!',
    'Error loading user branches!': 'Error loading user branches!',
    'Error!': 'Error!',
    'Error loading branch benches!': 'Error loading branch benches!',
    'Error loading user!': 'Error loading user!',
    'Error adding user!': 'Error adding user!',
    'Error adding branch!': 'Error adding branch!',
    'Error updating user!': 'Error updating user!',
    'Error updating branch!': 'Error updating branch!',
    'Error deleting user!': 'Error deleting user!',
    'Error deleting branch!': 'Error deleting branch!',
    'Tooltip.': 'Tooltip.',
    'No benches found.': 'No benches found.',
    'No user with this credentials.': 'No user with this credentials.',
    'There is an error occured while trying to reset password. Please check your passwords again':
      'There is an error occured while trying to reset password. Please check your passwords again',
    'Your request is sent successfully! Check your inbox to see new password.':
      'Your request is sent successfully! Check your inbox to see new password.',
    'Please check your email!': 'Please check your email!',
    'Please log in first!': 'Please log in first!',
    'Something went wrong while loading benches! It might be up to server.':
      'Something went wrong while loading benches! It might be up to server.',
    'Something went wrong while loading bench! It might be up to server.':
      'Something went wrong while loading bench! It might be up to server.',
    'There is an error occured while adding a bench. Try again?':
      'There is an error occured while adding a bench. Try again?',
    'Something went wrong while updating bench! It might be up to server.':
      'Something went wrong while updating bench! It might be up to server.',
    'Something went wrong while exporting reports! It might be up to server.':
      'Something went wrong while exporting reports! It might be up to server.',
    'Something went wrong while retrieving aws cetrificates for this bench! It might be up to server.':
      'Something went wrong while retrieving aws cetrificates for this bench! It might be up to server.',
    'Something went wrong while loading content! It might be up to server.':
      'Something went wrong while loading content! It might be up to server.',
    'There is an error occured while trying to upload this content item.':
      'There is an error occured while trying to upload this content item.',
    'There is an error occured while trying to delete this content item.':
      'There is an error occured while trying to delete this content item.',
    'Something went wrong while loading users! It might be up to server.':
      'Something went wrong while loading users! It might be up to server.',
    'Something went wrong while loading branches! It might be up to server.':
      'Something went wrong while loading branches! It might be up to server.',
    'Something went wrong while loading user branches! It might be up to server.':
      'Something went wrong while loading user branches! It might be up to server.',
    'Something went wrong while adding user branch.':
      'Something went wrong while adding user branch.',
    'Something went wrong while deleting user branch.':
      'Something went wrong while deleting user branch.',
    'Something went wrong while loading branch benches! It might be up to server.':
      'Something went wrong while loading branch benches! It might be up to server.',
    'Something went wrong while adding branch bench.':
      'Something went wrong while adding branch bench.',
    'Something went wrong while deleting branch bench.':
      'Something went wrong while deleting branch bench.',
    'Error occured while trying to retrieve user data! It might be up to server.':
      'Error occured while trying to retrieve user data! It might be up to server.',
    'Error occured while trying to retrieve branch data! It might be up to server.':
      'Error occured while trying to retrieve branch data! It might be up to server.',
    'Most likely you tried to add an user that already exists. Please check your user data again.':
      'Most likely you tried to add an user that already exists. Please check your user data again.',
    'Most likely you tried to add a branch that already exists. Please check your branch data again.':
      'Most likely you tried to add a branch that already exists. Please check your branch data again.',
    'There is an error occured while trying to update this user.':
      'There is an error occured while trying to update this user.',
    'There is an error occured while trying to update this branch.':
      'There is an error occured while trying to update this branch.',
    'There is an error occured while trying to delete this user.':
      'There is an error occured while trying to delete this user.',
    'There is an error occured while trying to delete this branch.':
      'There is an error occured while trying to delete this branch.',
    'Download config': 'Download config',
    'Schuko socket': 'Schuko socket',
    'E-charging (Solar)': 'E-charging (Solar)',
    'E-charging (Battery)': 'E-charging (Battery)',
    'E-charging (USB)': 'E-charging (USB)',
    'E-charging (Wireless)': 'E-charging (Wireless)',
    'Please select type!': 'Please select type!',
    'Please select owner!': 'Please select owner!',
    'Please select location!': 'Please select location!',
    'You are currently not allowed to add any content. Please, contact administrator for more deatils':
      'You are currently not allowed to add any content. Please, contact administrator for more deatils',
    'New branch/branches added to your profile.':
      'New branch/branches added to your profile.',
    'Your profile is created.': 'Your profile is created.',
    'Your content request is approved': 'Your content request is approved',
    'Your content request is declined': 'Your content request is declined',
    Client: 'Client',
    'Super Admin': 'Super Admin',
    'Are you sure you want to delete this branch?':
      'Are you sure you want to delete this branch?',
    'View Content': 'View Content',
    'Edit': 'Edit',
    'Delete': 'Delete',
    'Delete bench': 'Delete bench',
    'Are you sure you want to delete this bench?': 'Are you sure you want to delete this bench?',
    'Bench successfully deleted': 'Bench successfully deleted',
    'Bench successfully updated': 'Bench successfully updated',
    'Edit bench': 'Edit bench',
    'Set': 'Set',
    'Hide': 'Hide',
    'wrongPassword': 'Incorrect password',
    'internalServerError': 'Problem occurred on the server',
    'Type your password in order to delete this bench.': 'Type your password in order to delete this bench.',
    'benchGivenIdNotUnique': 'Bench ID is not unique',
    'day': 'day',
    'days': 'days',
    'Type bench ID': 'Type bench ID',
    'Please add at least one bench': 'Please add at least one bench',
    'Please choose at least one sensor': 'Please choose at least one sensor',
    'exportReportsBenchTooltip': 'In order to add one of the benches for the export you need to click on the desired bench in the dropdown menu below.',
    'Problem': 'Problem',
    'Sensor type': 'Sensor type',
    "Bench ID can't be negative": "Bench ID can't be negative",
    'GPS': 'GPS sensor',
    'CPU': 'CPU sensor',
    'TEMPERATURE_PRESSURE_HUMIDITY': 'Temperature pressure and humidity sensor',
    'ELECTRIC_POWER': 'Electric power sensor',
    'AIR_CO': 'Air CO level sensor',
    'WIFI': 'WiFi sensor',
    'ELECTRIC_ACS712': 'Electric ACS712 sensor',
    'ELECTRIC_INA219_SOLAR': 'Electric INA219 solar sensor',
    'ELECTRIC_INA219_BATTERY': 'Electric INA219 battery sensor',
    'ELECTRIC_INA219_USB': 'Electric INA219 usb sensor',
    'ELECTRIC_INA219_WIRELESS': 'Electric INA219 wireless sensor',
    'NOISE': 'Noise sensor',
    'benchDeactivatedDueToLackOfData': 'Deactivated due to lack of data',
    'Noise': 'Noise',
    'Noise / dB': 'Noise / dB',
    'Noise offset': 'Noise offset',
    'UV': 'UV',
    'UV offset': 'UV offset',
  },
};
