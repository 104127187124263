import React, { useState, useEffect } from 'react';
import {
    PlusOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { Tabs, Modal, message, DatePicker, Empty, notification } from 'antd';
import BenchStore from '../stores/bench/benchStore';
import AuthStore from '../stores/auth/authStore';
import translate from '../i18n/translate';
import If from '../utils/If/If';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../components/ui-elements/Button/button.component';
import Spinner from '../components/ui-elements/Spinner/spinner.component';
import Bench from '../components/containers/Bench/bench.component';
import ReportsForm from '../components/containers/Bench/ReportsForm/reports-form.component';
import DownloadReportsForm from '../components/containers/Bench/DownloadReportsForm/download-reports-form.component';
import DownloadReportsFormFooter
    from '../components/containers/Bench/DownloadReportsForm/DownloadReportsFormFooter/download-reports-form-footer.component';
import '../assets/styles/bench.scss';
import benchStore from '../stores/bench/benchStore';
import BenchFormModal from "../components/containers/Bench/BenchForm/bench-form-modal.component";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const BenchesScreen = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showReportsModal, setShowReportsModal] = useState(false);
    const [selectedBench, setSelectedBench] = useState(null);
    const [renderApp, setRenderapp] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState(null);
    const { formatMessage } = useIntl();
    const [showDownloadReportsModal, setShowDownloadReportsModal] = useState(
        false
    );
    const [filterDates, setFilterDates] = useState([]);
    const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
    const FILTER_DAYS_LIMIT = 30;

    useEffect(() => {
        BenchStore.loadBenches().then(() => {
            navigateToBenchTab(BenchStore.benches[0]?.id);
        });

        async function fetchData() {
            await AuthStore.getProfile();
        }

        fetchData();
    }, [renderApp]);

    useEffect(() => {
        async function fetchData() {
            await AuthStore.getProfile();
            setRenderapp(prevState => {
                return !prevState
            })
        }

        fetchData();

    }, [AuthStore]);

    const openDateFilter = () => setIsDateFilterOpen(true);

    const closeDateFilter = () => setIsDateFilterOpen(false);

    const openModalHandler = () => setShowModal(true);

    const openReportsModalHandler = () => setShowReportsModal(true);

    const closeModalHandler = () => setShowModal(false);

    const closeReportsModalHandler = () => setShowReportsModal(false);

    const updateSelectedBench = (benchId) => setSelectedBench(benchId);

    const closeDownloadReportsModalHandler = () =>
        setShowDownloadReportsModal(false);

    const downloadReports = () => {
        message.success('You downloaded file successfully!');
        closeDownloadReportsModalHandler();
    };

    const reloadBenchesScreen = () => {
        setRenderapp(!renderApp);
    }

    const navigateToBenchTab = (benchId) => {
        setTabActiveKey(benchId);
        setFilterDates([]);
        updateSelectedBench(benchId);
    }

    const handleDateFiltersSelection = async (benchId, dates) => {
        setFilterDates(dates ? dates : []);
        const params = {};

        if(dates && dates.length > 1) {
            params.startDate = dates[0]?.startOf('day').format();
            params.endDate = dates[1]?.endOf('day').format();
        }
        await benchStore.getBench(benchId, params);
    }

    const handleSingleDateSelection = (dates) => {
        setFilterDates(dates);
    }

    const disabledDate = (current) => {
        if (!filterDates || filterDates.length === 0) {
            return false;
        }
        const tooLate = filterDates[0] && current.diff(filterDates[0], 'days') > FILTER_DAYS_LIMIT;
        const tooEarly = filterDates[1] && filterDates[1].diff(current, 'days') > FILTER_DAYS_LIMIT;
        return tooEarly || tooLate;
    }

    const isDateFilterApplied = filterDates && filterDates.length > 0;

    useEffect(() => {
        if(selectedBench)
        benchStore.getBench(selectedBench).catch(({message, description}) => {
            notification.error({ message: formatMessage({ id: message }), description: formatMessage({ id: description }) })
        })
    }, [selectedBench]);

    return (
        <div className="benches-screen">
            <BenchFormModal
                visible={showModal}
                onCancel={closeModalHandler}
            />

            <Modal
                className="benches-screen__reports-modal"
                visible={showReportsModal}
                closable={false}
                maskClosable={false}
                centered
                destroyOnClose
                title={
                    <h3 className="benches-form__reports-modal-title">
                        {translate('Export Reports')}
                    </h3>
                }
                footer={false}
            >
                <ReportsForm
                    closeModalHandler={closeReportsModalHandler}
                    store={BenchStore}
                    bench={BenchStore.bench}
                    profile={AuthStore.logedUser}
                    benches={
                        AuthStore.logedUser.role && AuthStore.logedUser.role === 'superAdmin'
                            ? BenchStore.benches
                            : AuthStore.logedUser.benches
                    }
                />
            </Modal>
            <Modal
                className="benches-screen__download-reports-modal"
                visible={showDownloadReportsModal}
                onCancel={closeDownloadReportsModalHandler}
                closable={false}
                maskClosable={false}
                centered
                footer={
                    <DownloadReportsFormFooter
                        downloadReports={downloadReports}
                        closeModalHandler={closeDownloadReportsModalHandler}
                    />
                }
            >
                <DownloadReportsForm />
            </Modal>
            {AuthStore?.logedUser ? (
                <div className="benches-screen__add-bench-button-container">
                    <div className="benches-screen__filter-period-container">
                        <div className="benches-screen__filter-period">
                            {!isDateFilterOpen ? (
                                <span onClick={openDateFilter}>{translate('Select Period')}</span>
                            ) : (
                                <div className="datepicker-container">
                                    <RangePicker
                                        value={filterDates}
                                        onChange={async (dates) => {
                                            await handleDateFiltersSelection(selectedBench, dates);
                                        }}
                                        onCalendarChange={handleSingleDateSelection}
                                        disabledDate={disabledDate}
                                    />
                                    <Button className="done-btn"
                                        onClick={closeDateFilter}
                                        btnText={<FormattedMessage id="Hide" />} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="benches-screen__select-export-container">
                        <div
                            className="benches-screen__export-reports"
                            onClick={openReportsModalHandler}
                        >
                            <span>{translate('Export Reports')}</span>
                        </div>
                    </div>
                    {AuthStore?.logedUser.role === 'superAdmin' && (
                        <Button
                            btnStyle={{ float: 'right' }}
                            className="add-bench-btn"
                            size="large"
                            type="primary"
                            icon={<PlusOutlined />}
                            btnText={translate('ADD BENCH')}
                            onClick={openModalHandler}
                        />
                    )}
                </div>
            ) : (
                    <div className="users-list__spinner-container">
                        <Spinner
                            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
                            size="large"
                            tip={translate('Loading...')}
                        />
                    </div>
                )}
            <If
                condition={
                    (BenchStore.benches && BenchStore.benches.length === 0) ||
                    AuthStore?.logedUser?.branches?.length === 0
                }
            >
                <div className="users-list__spinner-container">
                    <Empty description={<span>{translate('No benches found.')}</span>} />
                </div>
            </If>

            {(BenchStore.benches && (
                <Tabs activeKey={tabActiveKey} onTabClick={navigateToBenchTab} className="benches-screen__tabs">
                    {BenchStore.benches.map((bench) => (
                        <TabPane tab={<h3>{bench.givenId}</h3>} key={bench.id}>
                            <Bench
                                store={BenchStore}
                                authStore={AuthStore}
                                benchId={bench.id}
                                givenId={bench.givenId}
                                profile={AuthStore.logedUser}
                                reloadScreen={reloadBenchesScreen}
                                isDateFilterApplied={isDateFilterApplied}
                            />
                        </TabPane>
                    ))}
                </Tabs>
            )) ||
                (!BenchStore.benches || AuthStore?.logedUser?.branches?.length === 0 ? (
                    <div className="users-list__spinner-container">
                        <Spinner
                            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
                            size="large"
                            tip={translate('Loading benches...')}
                        />
                    </div>
                ) : null)}
        </div>
    );
};

export default inject('BenchStore')(observer(BenchesScreen));
