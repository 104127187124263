import React from 'react';

import './download-reports-form.style.scss';

const DownloadReportsForm = props => {
    return (
        <div className="download-reports-form__container">
            <div className="download-reports-form__file-preview"></div>
        </div>
    );
};

export default DownloadReportsForm;