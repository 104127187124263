import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Avatar as AntAvatar, Badge } from "antd";
import "./avatar.style.scss";

const Avatar = ({
  count, // if avatar is user with badge, count property is a number to show in badge
  shape, // the shape of avatar (circle | square)
  src, // the address of the image for an image avatar
  icon, // custom icon type for an icon avatar (shown when there is no address of an avatar src)
  text, // this property is used when user doesn't have an avatar image or it's not set(usually initials of first and last name are used)
  size, // the size of the avatar	-> number | large | small | default
  withBadge, // it should be set to true if badge is needed alongside the avatar. It's false by default
  className,
  style,
  dot,
}) => {
  const avatarWithBadge = (
    <div className={classnames(`avatar-item ${className}`)}>
      <Badge data-test="badge" count={count} dot={dot}>
        <AntAvatar
          data-test="reusable-avatar"
          src={src}
          size={size}
          shape={shape}
          icon={icon}
          style={style}
        >
          {text}
        </AntAvatar>
      </Badge>
    </div>
  );

  const avatarWithoutBadge = (
    <AntAvatar
      data-test="reusable-avatar"
      src={src}
      size={size}
      shape={shape}
      icon={icon}
      className={className}
      style={style}
    >
      {text}
    </AntAvatar>
  );

  return withBadge ? avatarWithBadge : avatarWithoutBadge;
};

Avatar.defaultProps = {
  count: 0,
  shape: "",
  src: "",
  icon: null,
  text: "",
  size: "",
  withBadge: false,
  dot: false,
};

Avatar.propTypes = {
  count: PropTypes.number,
  shape: PropTypes.string,
  src: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withBadge: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  dot: PropTypes.bool,
};

export default Avatar;
