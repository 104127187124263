import React from 'react';
import {
    UserOutlined,
    HomeOutlined,
    EditOutlined,
    LayoutOutlined
} from '@ant-design/icons';

export const menuItemsList = [
    {
        title: 'Home',
        key: 'home',
        icon: <HomeOutlined/>,
        url: '/home'
    },
    {
        title: 'Users',
        key: 'users',
        icon: <UserOutlined/>,
        url: '/users'
    },
    {
        title: 'Benches',
        key: 'benches',
        icon: <LayoutOutlined/>,
        url: '/benches'
    },
    {
        title: 'Content',
        key: 'content',
        icon: <EditOutlined/>,
        url: '/content'
    }
];