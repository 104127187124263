import React, { useState, useEffect } from 'react';
import MapComponent from '../Map/map.component';

import './public-map-style.scss';

const PublicMapComponent = ({ benchStore }) => {
  const [benches, setBenches] = useState([]);

  useEffect(() => {
    benchStore.loadPublicBenches().then(() => {
      if (benchStore.benches) {
        setBenches(benchStore.benches);
      }
    });
  }, []);

  return (
    <div className="public-map-container">
      <MapComponent height="100vh" benches={benches} />
    </div>
  );
};

export default PublicMapComponent;
