import Cookie from 'js-cookie';
import jwt from 'jsonwebtoken';

const TOKEN = 'token';
const REFRESH_TOKEN = 'refresh_token';

export default class Auth {
  static getToken() {
    return Cookie.get(TOKEN);
  }

  static isAuthorized() {
    return Auth.getToken();
  }

  static removeTokens() {
    Cookie.remove(TOKEN);
    Cookie.remove(REFRESH_TOKEN);
  }

  static decodeToken(params) {
    return jwt.decode(params);
  }
}
