import React, { useEffect, useState } from 'react';
import { AutoComplete, Row, Col, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import If from '../../../../utils/If/If';
import translate from '../../../../i18n/translate';

import Card from '../../../ui-elements/Card/card.component';
import Tag from '../../../ui-elements/Tag/tag.component';
import Spinner from '../../../ui-elements/Spinner/spinner.component';
import ClientBranchFormFooter from './ClientBranchFormFooter/client-branch-form-footer.component';
import './client-branch-form.style.scss';

const ClientBranchForm = (props) => {
  const [form] = Form.useForm();
  const [branchList, setBranchList] = useState(null);
  const [userBranchList, setUserBranchList] = useState(null);
  const [user, setUser] = useState({});
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.userId) {
      props.store.getUser(props.userId).then(() => {
        setUser(props.store.user);
      });
    }

    props.store
      .loadBranches(true)
      .then(() => setBranchList(props.store.branches));

    return () => {
      setUser({});
      setBranchList(null);
    };
  }, [props.store, props.userId]);

  useEffect(() => {
    if (branchList && user.branches) {
      const userBranches = user.branches.map((branch) => branch.id);

      const filteredBranches = branchList
        .filter((branch) => !userBranches.includes(branch.id))
        .map((branch) => {
          return { value: branch.name };
        });

      setOptions(filteredBranches);
      props.store.loadUserBranches(user.branches);
    }

    return () => {};
  }, [props.store, user, branchList]);

  useEffect(() => {
    if (props.store.userBranches) {
      setUserBranchList(props.store.userBranches);
    }

    return () => {};
  }, [props.store, props.store.userBranches, options, userBranchList === null]);

  const initialValues = {
    branch: '',
  };

  const branchSubmitHandler = async (values) => {
    setLoading(true);
    const userData = {
      branches: props.store.userBranches,
    };

    await props.store.updateUser(props.userId, userData);
    form.resetFields();
    await props.store.loadBranches();
    props.closeModalHandler();
    setLoading(false);
  };

  return (
    <div>
      <Form
        name="client-branch-form"
        className="client-branch-form"
        form={form}
        initialValues={initialValues}
        onFinish={branchSubmitHandler}
      >
        {(props.store.userBranches || options) && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item name="branch" label={translate('Branch')} shouldUpdate>
                <AutoComplete
                  style={{ width: 200 }}
                  options={options}
                  allowClear={true}
                  notFoundContent={translate(
                    'There is no branch with this name.'
                  )}
                  placeholder="Start typing branch name"
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={async (value, option) => {
                    const selectedBranch = branchList.filter((branch) => {
                      return branch.name === value;
                    });

                    const filteredOptions = options.filter((branch) => {
                      return branch.value !== selectedBranch[0].name;
                    });

                    setOptions(filteredOptions);
                    await props.store.addUserBranch(selectedBranch[0]);
                    form.resetFields();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <div className="user-branch-list_cards-container">
          {userBranchList ? (
            userBranchList.map((branch) => (
              <Card className="users-branch-list__card" key={branch.id}>
                <Row
                  className="users-branch-list__table"
                  align="middle"
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                >
                  <Col
                    style={{ paddingLeft: 0 }}
                    xl={20}
                    lg={20}
                    md={20}
                    sm={21}
                    xs={20}
                  >
                    <span className="users-branch-list__table-cell">
                      {branch.name}
                    </span>
                  </Col>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={3}
                    xs={4}
                    className="users-list__tag-container"
                  >
                    <div className="users-branch-list__table-cell">
                      <div
                        style={{ display: 'flex' }}
                        onClick={async () => {
                          await options.push({ value: branch.name });
                          await props.store.removeUserBranch(branch.id);
                          form.resetFields();
                        }}
                      >
                        <Tag
                          className="users-branch-list__remove-branch-button"
                          color="red"
                          text="X"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            ))
          ) : (
            <div className="users-list__spinner-container">
              <Spinner
                indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
                size="large"
                tip={translate('Loading branches...')}
              />
            </div>
          )}
          <If condition={userBranchList && userBranchList.length === 0}>
            <div className="client-branch-list__empty-bench-list">
              <span>
                {translate('There are no branches within this client.')}
              </span>
            </div>
          </If>
        </div>

        <Form.Item style={{ marginBottom: 0, marginTop: '10px' }}>
          <div className="ant-modal-footer" style={{ padding: 0 }}>
            <ClientBranchFormFooter
              resetFields={() => {
                form.resetFields();
              }}
              closeModalHandler={async () => {
                await props.closeModalHandler();
                setTimeout(() => setUserBranchList(null), 500);
                await props.store.loadBranches();
                form.resetFields();
              }}
              store={props.store}
              loading={loading}
              disabled={!(props.store.userBranches || options)}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default inject('UsersStore')(observer(ClientBranchForm));
