import React, { useState, useEffect } from 'react';
import { AutoComplete, Row, Col, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import If from '../../../../utils/If/If';
import translate from '../../../../i18n/translate';

import Tag from '../../../ui-elements/Tag/tag.component';
import Card from '../../../ui-elements/Card/card.component';
import Spinner from '../../../ui-elements/Spinner/spinner.component';
import BranchBenchFormFooter from './BranchBenchFormFooter/branch-bench-form-footer.component';
import './branch-bench-form.style.scss';

const BranchBenchForm = (props) => {
  const [form] = Form.useForm();
  const [benchList, setBenchList] = useState(null);
  const [branchBenchList, setBranchBenchList] = useState(null);
  const [branch, setBranch] = useState({});
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.branchId) {
      props.userStore
        .getBranch(props.branchId)
        .then(() => setBranch(props.userStore.branch));
    }

    props.benchStore
      .loadBenches(true)
      .then(() => setBenchList(props.benchStore.benches));

    return () => {
      setBranch({});
      setBenchList(null);
    };
  }, [props.benchStore, props.userStore, props.branchId]);

  useEffect(() => {
    if (benchList && branch.benches) {
      const branchBenches = branch.benches.map((bench) => bench.id);

      const filteredBenches = benchList
        .filter((bench) => !branchBenches.includes(bench.id))
        .map((bench) => {
          return { value: bench.givenId.toString() };
        });

      setOptions(filteredBenches);
      props.userStore.loadBranchBenches(branch.benches);
    }

    return () => {};
  }, [props.userStore, branch, benchList]);

  useEffect(() => {
    if (props.userStore.branchBenches) {
      const benchesList = props.userStore.branchBenches
          .filter(bench => bench.givenId >= 0)
          .sort((a, b) => a.givenId - b.givenId);
      setBranchBenchList(benchesList);
    }
    return () => {};
  }, [props.userStore.branchBenches, branchBenchList === null]);

  const initialValues = {
    bench: '',
  };

  const benchSubmitHandler = async () => {
    setLoading(true);
    const branchData = {
      benches: props.userStore.branchBenches,
    };

    await props.userStore.updateBranch(props.branchId, branchData);
    form.resetFields();
    await props.userStore.loadBranches();
    props.closeModalHandler();
    setLoading(false);
  };
  return (
    <div>
      <Form
        name="branch-client-form"
        className="branch-client-form"
        form={form}
        initialValues={initialValues}
        onFinish={benchSubmitHandler}
      >
        {(branchBenchList || options) && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item name="bench" label={translate('Bench')} shouldUpdate>
                <AutoComplete
                  style={{ width: 200 }}
                  options={options}
                  allowClear={true}
                  notFoundContent={translate('There is no bench with this ID.')}
                  placeholder="Start typing bench ID"
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={async (value, option) => {
                    let selectedBench = benchList.filter((bench) => {
                      return bench?.givenId.toString() === value;
                    });
                    if(selectedBench.length !== 0) {
                      const filteredOptions = options.filter((bench) => {
                        return (
                            bench.value !== selectedBench[0]?.givenId.toString()
                        );
                      });

                      setOptions(filteredOptions);
                      await props.userStore.addBranchBench(selectedBench[0]);
                      form.resetFields();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <div className="branch-bench-list__cards-container">
          {branchBenchList ? (
            branchBenchList.map((bench) => (
              <Card className="branch-bench-list__card" key={bench.givenId}>
                <Row
                  className="branch-bench-list__table"
                  align="middle"
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                >
                  <Col
                    style={{ paddingLeft: 0 }}
                    xl={20}
                    lg={20}
                    md={20}
                    sm={21}
                    xs={20}
                  >
                    <span className="branch-bench-list__table-cell">
                      {bench.givenId}
                    </span>
                  </Col>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={3}
                    xs={4}
                    className="users-list__tag-container"
                  >
                    <span className="branch-bench-list__table-cell">
                      <span
                        style={{ display: 'flex', justifyContent: 'center' }}
                        onClick={async () => {
                          await options.push({
                            value: bench.givenId.toString(),
                          });
                          props.userStore.removeBranchBench(bench.id);
                          props.benchStore.loadBenches(true)
                          form.resetFields();
                        }}
                      >
                        <Tag
                          className="branch-bench-list__remove-branch-button"
                          color="red"
                          text="X"
                        />
                      </span>
                    </span>
                  </Col>
                </Row>
              </Card>
            ))
          ) : (
            <div className="users-list__spinner-container">
              <Spinner
                indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
                size="large"
                tip={translate('Loading benches...')}
              />
            </div>
          )}
          <If condition={branchBenchList && branchBenchList.length === 0}>
            <div className="branch-bench-list__empty-bench-list">
              <span>
                {translate('There are no benches under this branch.')}
              </span>
            </div>
          </If>
        </div>
        <Form.Item style={{ marginBottom: 0, marginTop: '10px' }}>
          <div className="ant-modal-footer" style={{ padding: 0 }}>
            <BranchBenchFormFooter
              resetFields={() => form.resetFields()}
              closeModalHandler={async () => {
                await props.closeModalHandler();
                setTimeout(() => setBranchBenchList(null), 500);
              }}
              store={props.store}
              loading={loading}
              disabled={!(branchBenchList || options)}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

const stores = ['UsersStore', 'BenchStore'];

export default inject(...stores)(observer(BranchBenchForm));
