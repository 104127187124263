import React from 'react';
import { Row, Col } from 'antd';
import If from '../../../../../utils/If/If';
import translate from '../../../../../i18n/translate';

import Button from '../../../../ui-elements/Button/button.component';
import './branch-form-footer.style.scss';

const BranchFormFooter = (props) => {
  return (
    <div className="branch-form__modal-footer">
      <Row align="middle" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xl={8} lg={8} md={9} sm={12} xs={12}>
          <If condition={!props.editing}>
            <Button
              size="large"
              type="primary"
              btnText={translate('Add Branch')}
              block
              htmlType="submit"
            />
          </If>
          <If condition={props.editing}>
            <Button
              size="large"
              block
              type="primary"
              htmlType="submit"
              btnText={translate('Save')}
            />
          </If>
        </Col>
        <Col xl={4} lg={4} md={4} sm={8} xs={8}>
          <span
            onClick={async () => {
              if (props.editing) {
                await props.finishEditing();
              }
              await props.resetFields();
              await props.closeModalHandler();
            }}
            className="branch-form__modal-footer-cancel"
          >
            {translate('Cancel')}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default BranchFormFooter;
