import React from 'react';

export default class If extends React.Component {
    render() {
        const { condition, children, ...props } = this.props;
        return condition ? (
            <div {...props}>
                {children}
            </div>
        ) : null;
    }
}
