
const PRIMARY_LIGHT_GREEN = '#33CC66';

const defaultOptions = {
  use12Hours: false,
  labelText: '',
};

export const chartStyleConfig = {
  borderWidth: 1,
  borderColor: '#005450',
  backgroundColor: '#ebf1f1',
  pointRadius: 2,
  pointHoverRadius: 5,
  pointBorderColor: '#005450',
  pointBackgroundColor: PRIMARY_LIGHT_GREEN,
  pointHoverBackgroundColor: PRIMARY_LIGHT_GREEN,
  pointHoverBorderColor: PRIMARY_LIGHT_GREEN,
};

let globalLineChartConfig = {
  options: {
    tooltips: {
      yAlign: 'bottom',
      xPadding: 10,
      yPadding: 6,
      backgroundColor: PRIMARY_LIGHT_GREEN,
      bodyFontStyle: 'bold',
      callbacks: {
        title: () => '',
        label: () => '',
        afterBody: (items, data) => `${items[0]?.value}`
      }
    },
    responsive: true,
    title: {
      text: '',
      display: true,
      fontColor: '#005450',
      fontSize: '20',
      position: 'top',
    },
    legend: {
      align: 'end',
      onHover: function(e) {
        e.target.style.cursor = 'pointer';
      },
      onClick: function(e, legendItem) {
        if(!legendItem.hidden) {
          return;
        }
        // show/activate the clicked legend, hide/disable others
        const chart = this.chart;
        const currentIndex = legendItem.datasetIndex;
        const datasetIndexes = [0, 1];
        datasetIndexes.forEach(index => {
          chart.getDatasetMeta(index).hidden = currentIndex !== index;
        });
        chart.update();
      },
      labels: {
        fontColor: '#005450',
        fontSize: 16,
        fontStyle: 'bold',
        boxWidth: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  },
};

export const dataUsageChartConfig = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const wifiUsersChart = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const temperatureChartConfig = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const humidityChartData = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const airPressureChart = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const airQualityChart = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const usbChargingsChart = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const eChargingChart = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const wirelessChargingChart = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

//

export const electricityConsumptionChart = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const batteryChartConfig = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const solarChartConfig = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const sunnyHoursChartConfig = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    tooltips: {
      enabled: false
    },
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
})

export const noiseChartConfig = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});

export const uvChartConfig = (text) => ({
  ...globalLineChartConfig,
  options: {
    ...globalLineChartConfig.options,
    title: {
      ...globalLineChartConfig.options.title,
      text,
    },
  },
});


const getGradientBackground = (canvas) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 250);
  gradient.addColorStop(0, 'rgba(196,215,215,1)');
  gradient.addColorStop(1, 'rgba(196,215,215,0)');

  return gradient;
}

const createChart = (labels, datasets = []) => {
  const formattedDatasets = datasets.map(dataset => ({ ...chartStyleConfig, ...dataset }));

  return {
    labels,
    datasets: formattedDatasets,
  }
}

const checkLabelsValidity = (labels = []) => {
  if(labels && labels.length > 0) {
    // ensure the value matches time format of HH:MM
    return labels.every(label => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(label));
  }
  return false;
}

const generate12HoursFormatDatasets = (labels = [], data = []) => {
  try {
    const result = {};
    const newLabels = [];
    for(let x = 1; x <= 12; x++) {
      result[x] = { am: null, pm: null};
      newLabels.push(x);
    }

    labels.forEach((time, i) => {
      const currentData = data[i];
      const hour = +(time.split(':')[0]) + 1;
      const isLargerThan12Hours = hour > 12;
      const hourResult = isLargerThan12Hours ? hour - 12 : hour;

      result[hourResult][isLargerThan12Hours ? 'pm' : 'am'] = currentData;
    });

    const preNoonDataset = [];
    const afterNoonDataset = [];
    newLabels.forEach(hour => {
      preNoonDataset.push(result[hour]?.am);
      afterNoonDataset.push(result[hour]?.pm);
    });

    return {
      labels: newLabels,
      datasets: [
        { data: preNoonDataset, label: 'AM'},
        { data: afterNoonDataset, label: 'PM', hidden: true},
      ],
    }
  } catch (err) {
    console.error(err);
    return { labels, datasets: [{ data }]};
  }

}

const extractLabelsAndDatasets = (initialLabels, initialData, options = defaultOptions) => {
  let datasets;
  let labels;

  if(options.use12Hours && checkLabelsValidity(initialLabels)) {
    const result = generate12HoursFormatDatasets(initialLabels, initialData);
    labels = result.labels;
    datasets = result.datasets;
  } else {
    labels = initialLabels;
    datasets = [{ data: initialData, label: options.labelText }];
  }

  return { labels, datasets };
}


export const createLineChart = (canvas, initialLabels, initialData, options = defaultOptions) => {
  const { labels, datasets } = extractLabelsAndDatasets(initialLabels, initialData, options);

  const gradient = getGradientBackground(canvas);
  return createChart(labels, datasets.map(dataset => ({
    backgroundColor: gradient,
    borderWidth: 2,
    borderColor: 'rgba(0,84,80,1)',
    ...dataset
  })));
}

export const createBarChart = (initialLabels, initialData, options = defaultOptions) => {
  const { labels, datasets } = extractLabelsAndDatasets(initialLabels, initialData, options);

  return createChart(labels, datasets.map(dataset => ({
    backgroundColor: 'rgba(0,84,80,0.3)',
    borderWidth: 0,
    barThickness: 10,
    ...dataset
  })));
}
