import React from 'react';
import { createSiderStore } from './siderStore';
import { useLocalStore } from 'mobx-react';

const SiderContext = React.createContext(null);

export const SiderProvider = ({ children }) => {
    const siderStore = useLocalStore(createSiderStore);

    return (
        <SiderContext.Provider value={siderStore}>
            {children}
        </SiderContext.Provider>
    );
};

export const useSiderStore = () => React.useContext(SiderContext);