import kwConsumed from '../assets/icons/kwh-consumed.svg';
import usbChargingIcon from '../assets/icons/usb-chargings.svg';
import eCharging from '../assets/icons/e-chargings.svg';
import wifiUsers from '../assets/icons/wi-fi-users.svg';
import wirelesChargings from '../assets/icons/wireless-chargings.svg';
import kwhProduced from '../assets/icons/kwh-produced.svg';

export const summaryData = [
  { id: 0, src: wifiUsers, text: 'Wi-fi users' },

  {
    id: 1,
    src: kwConsumed,
    text: 'Wh consumed',
  },
  {
    id: 2,
    src: usbChargingIcon,
    text: 'USB chargins',
  },
  { id: 3, src: eCharging, text: 'E-chargings' },
  { id: 4, src: wirelesChargings, text: 'Wireless chargings' },
];
