import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Row, Col, message } from 'antd';
import { inject, observer } from 'mobx-react';
import translate from '../../../i18n/translate';

import Card from '../../ui-elements/Card/card.component';
import Button from '../../ui-elements/Button/button.component';
import './login.style.scss';

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    setDisable(true);
    let responseLogin = await props.store.loginUser(values);
    form.setFields([
      {
        name: 'password',
        errors: [responseLogin?.message],
      },
    ]);
    setLoading(false);
    setDisable(false);
  };
  console.log(props);

  return (
    <div className="login-form__container">
      <Row justify="center">
        <Col xxl={16} xl={20} lg={20} md={22} sm={20} xs={20}>
          <Card className="login-form__card">
            <Row style={{ height: '100%' }}>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={0}
                xs={0}
                className="login-form__about-section"
              ></Col>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className="login-form__form-container"
              >
                <h1 className="login-form__login-title">
                  {translate('Login')}
                </h1>
                <Form name="login-form" onFinish={onFinish} form={form}>
                  <Input.Group className="login-form__input-group">
                    <Form.Item
                      name="email"
                      label={translate('E-mail')}
                      rules={[
                        {
                          type: 'email',
                          message: translate('Please enter a valid E-mail.'),
                        },
                        {
                          required: true,
                          message: translate('Please enter your E-mail.'),
                        },
                      ]}
                    >
                      <Input
                        className="login-form__input"
                        placeholder="Enter your email"
                      />
                    </Form.Item>
                    <Form.Item
                      label={translate('Password')}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: translate('Please enter your password.'),
                        },
                      ]}
                    >
                      <Input
                        type="password"
                        className="login-form__input"
                        placeholder="Enter your password"
                      />
                    </Form.Item>
                    <Row className="login-form__footer" justify="space-between">
                      <Col xl={6} lg={10} md={9} sm={7} xs={8}>
                        <Button
                          btnStyle={{ float: 'right' }}
                          size="large"
                          type="primary"
                          block
                          btnText={<span>{translate('Login')}</span>}
                          htmlType="submit"
                          loading={loading}
                          disabled={disable}
                        />
                      </Col>
                      <Col xl={8} lg={10} md={10} sm={13} xs={12}>
                        <Link to="/forgot-password">
                          <span className="login-form__forgot-password">
                            {translate('Forgot password?')}
                          </span>
                        </Link>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default inject('AuthStore')(observer(Login));
