import React, {useEffect, useState} from "react";
import ContentStore from "../../../../stores/content/contentStore";
import {Col, Row} from "antd";
import Media from "../../Media/media.component";
import './view-content.scss';

const ViewContent = (props) => {
    const [source, setSource] = useState(null);

    useEffect(() => {
        ContentStore.getContentForRequest(props.contentKey).then(() => {
            if (ContentStore.requestContent) {
                setSource(ContentStore.requestContent);
            }
        });
    }, [props.contentKey]);

    return (
        <Row>
            <Col span={24}>
                {source && (
                    <Media
                        source={source}
                        alt={props.contentName}
                        type={props.contentType}
                    />
                )}
            </Col>
        </Row>
    );
}

export default ViewContent;