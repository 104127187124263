import moment from "moment";

// bench fields that are being processed for the charts visualization
const benchChartFields = [
    { field: 'temperature', key: 'temp' },
    { field: 'coLevel', options: { floor: false } },
    { field: 'humidity' },
    { field: 'pressure' },
    { field: 'ina219Wireless', key: 'chargerWattHour' },
    { field: 'ina219Usb', key: 'chargerWattHour' },
    { field: 'acs712', key: 'eChargerWattHour' },
    { field: 'userWifiConnected', key: 'uniqueUsers', options: { average: false } },
    { field: 'cpu', key: 'cpuTemp' },
    { field: 'electricityConsumptions', key: 'electricityConsumption' },
    { field: 'ina219Battery', key: 'voltage' },
    { field: 'ina219Solar', key: 'voltage' },
    { field: 'noise', key: 'dbLevel' },
    { field: 'uv', key: 'uvLevel' },
];

// the interval in minutes which is used for results grouping by time
const MINUTES_DIFFERENCE_INTERVAL = 60;

/**
 * Normalize the minute.
 * @param minute
 * @returns {number|*}
 */
const normalizeMinute = (minute) => {
    if(minute > 0 && minute < MINUTES_DIFFERENCE_INTERVAL) {
        return 0;
    } else if(minute > MINUTES_DIFFERENCE_INTERVAL) {
        return MINUTES_DIFFERENCE_INTERVAL;
    }
    return minute;
}

/**
 * Process daily values for the charts presentation.
 * @param {array} initialValues
 * @param {string} valueKey
 * @param {any} options
 * @returns {[]|*[]}
 */
const processDailyValuesForChart = (initialValues, valueKey, options = {
    floor: true,
    average: true
}) => {
    if(!initialValues) {
        return [];
    }
    // setup the variables, inject the moment object for every value
    const results = [];
    const values = [...initialValues];
    const valuesWithDate = values.map(value => ({...value, moment: moment(value.timestamp)}));

    valuesWithDate.forEach(value => {
        value.moment.set('minute', normalizeMinute(value.moment.minutes()));

        // form the array of values that need to be calculated
        const valuesForCalculations = [value[valueKey]];

        // iterate over initial values and extract the values that belong to the next defined minutes interval
        valuesWithDate.forEach((filteredValue, i) => {
            const duration = moment.duration(filteredValue.moment.diff(value.moment));
            const minutesDiff = duration.asMinutes();

            // if the current value belongs to the provided minute interval of the initial stat
            // add it to the calculation array
            if(minutesDiff > 0 && minutesDiff < MINUTES_DIFFERENCE_INTERVAL) {
                valuesForCalculations.push(filteredValue[valueKey]);
                valuesWithDate.splice(i, 1);
            }
        });

        // determine which calculating functions should be performed
        const shouldFloor = options?.floor !== undefined ? options.floor : true;
        const shouldAverage = options?.average !== undefined ? options.average : true;

        // calculate the average value of the stats, and then floor the final value
        const floorCoefficient = shouldFloor ? 1 : 100;
        const summedValue = valuesForCalculations.reduce((a, b) => a + b, 0);
        const averageValue = shouldAverage ? summedValue / valuesForCalculations.length : summedValue;
        const actualValue = Math.floor(averageValue * floorCoefficient) / floorCoefficient;

        results.push({
            [valueKey]: actualValue,
            time: value.moment.format('HH:mm')
        });
    });

    return results;
}

/**
 * Process periodic values for chart's representation.
 * @param initialValues
 * @param valueKey
 * @returns {*}
 */
const processPeriodicValuesForChart = (initialValues, valueKey) => {
    if(!initialValues) {
        return [];
    }
    const values = [...initialValues];

    // attach moment instance for every value, then sort the array by it
    return values.map(value => ({
        ...value,
        moment: moment(value.timestamp, 'DD/MM/YYYY')
    })).sort((a, b) => a.moment.diff(b.moment));
}

/**
 * Converts values in array to binary (1 or 0) based on provided limit.
 * @param {array} initialValues
 * @param {string} valueKey
 * @param {number} limit
 * @returns {array}
 */
export const convertValuesToBinary = (initialValues, valueKey, limit) => {
    if(!initialValues) {
        return [];
    }
    return initialValues.map((value) => {
        return {
            ...value,
            [valueKey]: value[valueKey] >= limit ? 1 : 0
        }
    });
}

const processMaxValuesForChart = (initialValues, valueKey, options = {
    floor: true,
    max: false
}) => {
    if (!initialValues) {
        return [];
    }

    const results = [];
    const values = [...initialValues];
    const valuesWithDate = values.map(value => ({ ...value, moment: moment(value.timestamp) }));

    while (valuesWithDate.length > 0) {
        const value = valuesWithDate.shift();
        value.moment.set('minute', normalizeMinute(value.moment.minutes()));
        const valuesForCalculations = [value[valueKey]];

        for (let i = valuesWithDate.length - 1; i >= 0; i--) {
            const filteredValue = valuesWithDate[i];
            const duration = moment.duration(filteredValue.moment.diff(value.moment));
            const minutesDiff = duration.asMinutes();

            if (minutesDiff > 0 && minutesDiff < MINUTES_DIFFERENCE_INTERVAL) {
                valuesForCalculations.push(filteredValue[valueKey]);
                valuesWithDate.splice(i, 1);
            }
        }

        const shouldFloor = options?.floor !== undefined ? options.floor : true;

        let actualValue;

            // Calculate and use the maximum value from the interval
            actualValue = Math.max(...valuesForCalculations);


        results.push({
            [valueKey]: actualValue,
            time: value.moment.format('HH:mm')
        });
    }

    return results;
}


/**
 * Process the bench for the chart's visualization purposes.
 * @param bench
 * @param options
 * @returns {*}
 */
export const processBenchForCharts = (bench, options = {
    isForSingleDay: true
}) => {
    const benchData = {...bench};

    // Mutate every eligible bench field for the charts
    benchChartFields.forEach(field => {
        if (options.isForSingleDay) {
            const fieldKey = field.key ? field.key : field.field;

            if (field.field === 'noise') {
                // Calculate the maximum value for noise instead of averaging
                benchData[field.field] = processMaxValuesForChart(benchData[field.field], fieldKey);
            } else {
                benchData[field.field] = processDailyValuesForChart(benchData[field.field], fieldKey, field.options);
            }
        } else {
            benchData[field.field] = processPeriodicValuesForChart(benchData[field.field]);
        }
    });
    return benchData;
}
