import instance from '../../lib/axiosConfig';
import { notification } from 'antd';
import { runInAction, action, observable, decorate } from 'mobx';

class ContentStore {
  contentList = null;
  awsContentKey = null;
  progress = 0;
  contentId = null;
  requestContent = {};
  contentBenches = null;

  loadContent = async (params = {}) => {
    try {
      const result = await instance.get('/ads', { params });

      if (result) {
        await runInAction(async () => {
          this.contentList = result.data;
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error loading content!',
        description:
          'Something went wrong while loading content! It might be up to server.',
      });
    }
  };

  uploadContent = async (uploadData) => {
    try {
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

          if (percent < 100) {
            this.progress = percent;
          }
        },
      };
      await instance.post('/uploads', uploadData, options).then((res) => {
        if (res.data.key) {
          runInAction(() => {
            this.awsContentKey = res.data.key;
          });
        }

        this.progress = 100;
        setTimeout(() => {
          this.progress = 0;
        }, 1000);
      });
    } catch (error) {
      notification.error({
        message: 'Error uploading content!',
        description:
          'There is an error occured while trying to upload this content item.',
      });
    }
  };

  addContent = async (contentData) => {
    try {
      await instance.post('/ads', contentData).then((item) => {
        const _contentId = item.data.id;

        runInAction(() => {
          this.contentId = _contentId;
        });
      });
    } catch (error) {
      if (error.name) {
        console.log(error.name);
      }
      if (error.message) {
        console.log(error.message);
      }
    }
  };

  updateContent = async (contentId, contentData) => {
    try {
      await instance.put(`/ads/${contentId}`, contentData);
    } catch (error) {
      if (error.name) {
        console.log(error.name);
      }
      if (error.message) {
        console.log(error.message);
      }
    }
  };

  addContentOnBenches = async (contentId, benchList) => {
    try {
      await instance.put(`/ads/${contentId}/benches`, benchList);
    } catch (error) {
      if (error.name) {
        console.log(error.name);
      }
      if (error.message) {
        console.log(error.message);
      }
    }
  };

  removeContent = async (contentId) => {
    try {
      await instance.delete(`/ads/${contentId}`).catch((error) => {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          error.request.onerror((data) => console.log('ERROR DATA: ', data));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
        console.log(error.toJSON());
      });
    } catch (error) {
      runInAction(() => {
        notification.error({
          message: 'Error deleting content!',
          description:
            'There is an error occured while trying to delete this content item.',
        });
      });
    }
  };

  searchContent = async (contentName = '') => {
    await this.loadContent({
      term: contentName
    });
  };

  getContentForRequest = async (key) => {
    try {
      const result = await instance.get(`/contents?key=${key}`);

      if (result) {
        runInAction(() => {
          this.requestContent = result.data;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  loadContentBenches = async () => {
    try {
      const result = await instance.get(`/ads/benches`);

      if (result) {
        runInAction(() => {
          this.contentBenches = result.data;
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  // TO DO GET v1/benches/publish/${benchId}
}

decorate(ContentStore, {
  contentList: observable,
  contentId: observable,
  requestContent: observable,
  uploadContent: action,
  removeContent: action,
  addContentOnBenches: action,
  searchContent: action,
  getContentForRequest: action,
});

export default new ContentStore();
