import React from "react";
import { Tag as AntTag } from "antd";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./tag.style.scss";

const Tag = ({ icon, text, color, borderWidth, minWidth, className }) => (
  <AntTag
    data-test="reusable-tag"
    style={{ borderWidth: borderWidth, minWidth: minWidth ? "85px" : null }}
    className={classnames(`ant-tag-custom ${className}`)}
    color={color}
  >
    {icon}
    {text}
  </AntTag>
);

Tag.defaultProps = {
  icon: null,
  text: "",
  color: "",
  borderWidth: null,
  minWidth: false,
  className: "",
};

Tag.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.any,
  color: PropTypes.string,
  borderWidth: PropTypes.number,
  minWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default Tag;
