import React, { useState, useRef, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Tag,
  Checkbox,
  AutoComplete,
  ConfigProvider,
  Tooltip,
} from 'antd';
import {InfoCircleOutlined, PlusOutlined} from '@ant-design/icons';
import { useIntl } from 'react-intl';
import translate from '../../../../i18n/translate';
import 'moment/locale/sr';
import localeEN from 'antd/lib/locale/en_US';
import localeSR from 'antd/lib/locale/sr_RS';
import ReportsFormFooter from './ReportsFormFooter/reports-form-footer.component';
import './reports-form.style.scss';
import moment from 'moment';
import userRoles from '../../../../utils/user-roles';
const { Option } = Select;
const { RangePicker } = DatePicker;

const ReportsForm = (props) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [benches, setBenches] = useState([]);
  const { formatMessage, locale } = useIntl();
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  // calendar settings and variables
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();

  useEffect(() => {
    updateBenches(props.benches);
  }, [props.benches]);

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      form.setFields([{ name: 'period', errors: [] }]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  const errorsContain = field => formErrors.some(error => error.name === field);

  const removeError = (field) => {
    const updatedErrors = formErrors.filter(error => error.name !== field);
    setFormErrors(updatedErrors);
  }

  const languageMap = {
    'en-us': localeEN,
    'sr-sp': localeSR,
  };

  const updateBenches = (benches) => {
    const updatedBenches = benches.sort((a, b) => a.givenId - b.givenId)
      .map((element) => ({ givenId: element.givenId, id: element.id }));
    setBenches(updatedBenches);
  }

  const inputRef = useRef(null);

  const handleClose = (removedTag) => {
    if (tags) {
      const _tags = tags.filter((tag) => tag !== removedTag);
      setTags(_tags);
      removeError('benches');
      updateBenches([...benches, removedTag]);
    }
  };

  const showInput = async () => {
    await setInputVisible(true);
    inputRef.current.focus();
  };

  const handleInputConfirm = (value) => {
    if (value) {
      setTags((prevState) => {
        if (prevState.length === 0) {
          return [...prevState, { id: value[0], givenId: value[1] }];
        }
        // User can't add two same bench
        let duplicateState = prevState.filter((state) => state.id === value[0]);
        if (duplicateState.length > 0) {
          return prevState;
        }
        return [...prevState, { id: value[0], givenId: value[1] }];
      });

      const updatedBenches = benches.filter(bench => bench.givenId !== value[1]);
      updateBenches(updatedBenches);
    }
    removeError('benches');
    setInputVisible(!inputVisible);
  };

  const sendReportsRequest = async (data) => {
    await props.store.exportReports(data);
  };

  const generateRangeDate = (period) => {
    switch (period) {
      case '1': {
        return [moment(), moment()];
      }
      case '7': {
        return [moment().subtract(7, 'd'), moment()];
      }
      case '14': {
        return [moment().subtract(14, 'd'), moment()];
      }
      default:
        return [moment(), moment()];
    }
  };

  const validateDates = (dates) => {
    return dates &&
      dates.length > 1 &&
      dates.every(date => !!date);
  }

  const validateValues = (data) => {
    const errors = [];

    if(!validateDates(data?.dates)) {
      errors.push({ name: 'period', type: 'required' });
      form.setFields([
        {
          name: 'period',
          errors: [formatMessage({ id: 'Please select a date period'})]
        }
      ]);
    }
    if(!data?.benches || data?.benches?.length === 0) {
      errors.push({ name: 'benches', type: 'required' });
    }
    if(!data?.sensors || data?.sensors?.length === 0) {
      errors.push({ name: 'sensors', type: 'required' });
    }
    return { errors };
  }

  const exportHandler = async (values) => {
    // gather the sensors that are eligible for all roles
    const sensors = {
      usersWifiConnected: values.usersWifiConnected
          ? values.usersWifiConnected
          : null,
      temperature: values.temperature ? values.temperature : null,
      humidity: values.humidity ? values.humidity : null,
      pressure: values.pressure ? values.pressure : null,
      coLevel: values.coLevel ? values.coLevel : null,
      ina219Wireless: values.ina219Wireless ? values.ina219Wireless : null,
      ina219Usb: values.ina219Usb ? values.ina219Usb : null,
      electricityConsumptions: values.electricityConsumptions ? values.electricityConsumptions : null,
      ina219Solar: values.ina219Solar ? values.ina219Solar : null,
      ina219Battery: values.ina219Battery ? values.ina219Battery : null,
      acs712: values.acs712 ? values.acs712 : null,
      sunnyHours: values.sunnyHours ? values.sunnyHours : null,
      noise: values.noise ? values.noise : null,
      uv: values.uv ? values.uv : null,
    };
    // sensors that are only eligible for super admin role
    if(props.profile.role === userRoles.SUPER_ADMIN) {
      sensors.cpu = values.cpu ? values.cpu : null;
    }
    // filter the sensors based if their value is null or not
    const filteredSensors = Object.entries(sensors)
        .map((entry) => (entry[1] ? entry[0] : null))
        .filter((item) => item !== null);

    // perform form data validation
    const { errors } = validateValues({
      dates,
      benches: tags,
      sensors: filteredSensors
    });

    // if there were any form errors, update the state and stop further execution
    if(errors.length > 0) {
      setFormErrors(errors);
      return;
    }

    setLoading(true);
    await sendReportsRequest({
      benches: tags,
      startDate: dates[0].set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      endDate: dates[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      }),
      sensors: filteredSensors,
    });

    setLoading(false);
    closeHandler();
  };

  const closeHandler = () => {
    setTags([]);
    setDates([]);
    setFormErrors([]);
    form.resetFields();
    props.closeModalHandler();
  };

  const sensorsCheckboxes = [
      "cpu", "usersWifiConnected", "temperature", "humidity", "pressure", "coLevel", "ina219Solar", "ina219Usb",
    "acs712", "ina219Wireless", "sunnyHours", "ina219Battery", "electricityConsumptions", "noise", "uv"
  ];

  const onFormChange = (changedValues) => {
    const areSensorsChanged = Object.keys(changedValues)
        .some(field => sensorsCheckboxes.includes(field));

    if(areSensorsChanged) {
      removeError('sensors');
    }
  }

  return (
    <ConfigProvider locale={languageMap[locale.toLowerCase()]}>
      <Form onFinish={exportHandler} form={form} className="reports-form" onValuesChange={onFormChange}>
        <Input.Group>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <p className="label">
                <span>{translate('Benches')}</span>
                <Tooltip
                  placement="top"
                  className="reports-form__benches-tooltip-icon"
                  color="#005450"
                  title={translate('exportReportsBenchTooltip')}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </p>

              {tags?.map((tag) => (
                  <Tag
                    className="edit-tag"
                    key={tag?.id}
                    closable
                    onClose={() => handleClose(tag)}
                  >
                    <span>{tag?.givenId}</span>
                  </Tag>
                )
              )}
              {inputVisible && (
                <AutoComplete
                  ref={inputRef}
                  type="text"
                  size="middle"
                  className="tag-input"
                  placeholder={formatMessage({ id: 'Type bench ID' })}
                  notFoundContent={translate('No benches found.')}
                  onSelect={(value) => handleInputConfirm(value)}
                  filterOption={(inputValue, option) => option.key.includes(inputValue)}
                >
                  {benches.map((bench) => {
                    return (
                      <Option
                        key={bench.givenId}
                        value={[bench.id, bench.givenId]}
                      >
                        {`${bench.givenId}`}
                      </Option>
                    );
                  })}
                </AutoComplete>
              )}
              {!inputVisible && (
                <Tag className="site-tag-plus" onClick={showInput}>
                  <PlusOutlined /> {translate('Add')}
                </Tag>
              )}

              {errorsContain('benches') && (
                <div>
                  <span className="reports-form__error-text">{translate('Please add at least one bench')}</span>
                </div>
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={translate('Select Period')}
                name="period"
                className="reports-form__range-picker-container"
              >
                {props.profile.role && props.profile.role === userRoles.CLIENT ? (
                  <Select
                    placeholder={formatMessage({ id: 'Select Period' })}
                    className="reports-form__select-period"
                    onSelect={(value) => {
                      form.setFields([{ name: 'period', errors: [] }]);
                      const dates = generateRangeDate(value);
                      setDates(dates);
                    }}
                  >
                    <Option value={'1'}>1 {translate('day')}</Option>
                    <Option value={'7'}>7 {translate('days')}</Option>
                    <Option value={'14'}>14 {translate('days')}</Option>
                  </Select>
                ) : (
                  <RangePicker
                    format="DD/MM/YYYY"
                    value={hackValue || value}
                    onCalendarChange={(val) => setDates(val)}
                    onChange={(val) => setValue(val)}
                    onOpenChange={onOpenChange}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          {props.profile.role === userRoles.SUPER_ADMIN && (
            <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
            >
              <Form.Item name="cpu" valuePropName="checked">
                <Checkbox className="reports-form__radio-button">
                  {translate('CPU Temperature')}
                </Checkbox>
              </Form.Item>
            </Col>
          )}

          <Col
            style={{ height: '40px' }}
            className="gutter-row"
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={24}
          >
            <Form.Item name="usersWifiConnected" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('WiFi Users')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
            style={{ height: '40px' }}
            className="gutter-row"
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={24}
          >
            <Form.Item name="temperature" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Temperature')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col
            style={{ height: '40px' }}
            className="gutter-row"
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={24}
          >
            <Form.Item name="humidity" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Humidity')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
            style={{ height: '40px' }}
            className="gutter-row"
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={24}
          >
            <Form.Item name="pressure" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Air pressure')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
            style={{ height: '40px' }}
            className="gutter-row"
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={24}
          >
            <Form.Item name="coLevel" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Air quality')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
            style={{ height: '40px' }}
            className="gutter-row"
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={24}
          >
            <Form.Item name="ina219Solar" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Solar power')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
          >
            <Form.Item name="ina219Usb" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('USB chargins')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
          >
            <Form.Item name="acs712" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('E-chargings')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
          >
            <Form.Item name="ina219Wireless" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Wireless chargings')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
          >
            <Form.Item name="sunnyHours" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Sunny Hours')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
          >
            <Form.Item name="ina219Battery" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Battery status')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
          >
            <Form.Item name="electricityConsumptions" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Electricity consumption')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
          >
            <Form.Item name="noise" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('Noise')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
              style={{ height: '40px' }}
              className="gutter-row"
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
          >
            <Form.Item name="uv" valuePropName="checked">
              <Checkbox className="reports-form__radio-button">
                {translate('UV')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row className="sensors-errors-container">
          {errorsContain('sensors') && (
              <div>
                <span className="reports-form__error-text">{translate('Please choose at least one sensor')}</span>
              </div>
          )}
        </Row>

        <Form.Item name="footer">
          <div
            className="ant-modal-footer"
            style={{ padding: 0, marginTop: '30px' }}
          >
            <ReportsFormFooter
              loading={loading}
              exportReports={exportHandler}
              closeModalHandler={closeHandler}
            />
          </div>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

export default ReportsForm;
