import React from 'react';
import classnames from 'classnames';
import { Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { useSiderStore } from '../../../stores/sider/SiderContext';
import translate from '../../../i18n/translate';
import c2cLogo from '../../../assets/images/c2c-logo.png';

import './sider.style.scss';

const AntSider = Layout.Sider;

const Menu = (props) => {
  const store = useSiderStore();

  return (
    <ul className="sider__menu">
      {store.siderItems.map((item) => (
        <NavLink
          className="sider__menu-item"
          key={item.key}
          activeClassName="ant-menu-item-selected"
          to={item.url}
        >
          <li className="sider__menu-item-title-container">
            <span>{item.icon}</span>
            <span
              className={classnames({
                'sider__menu-item-title-active': true,
                'sider__menu-item-title-hidden': props.collapsed,
              })}
            >
              {translate(item.title)}
            </span>
          </li>
        </NavLink>
      ))}
      {!props.collapsed && (
        <img src={c2cLogo} alt="c2c-logo" className="c2c-logo" />
      )}
    </ul>
  );
};

const Sider = (props) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <AntSider
      breakpoint="xl"
      width={230}
      className={classnames({ sider: true, 'sider-not-collapsed': !collapsed })}
      onBreakpoint={() => {}}
      onCollapse={toggle}
      trigger={null}
      collapsedWidth={60}
      collapsible
      collapsed={collapsed}
    >
      {React.createElement(MenuOutlined, {
        className: 'trigger',
        onClick: toggle,
      })}
      <Menu collapsed={collapsed} />
    </AntSider>
  );
};

export default Sider;
