import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Modal as AntModal } from 'antd';
import { inject, observer } from 'mobx-react';
import { Line, Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { summaryData } from '../../../constants/benchUsageSummary';
import {
  dataUsageChartConfig,
  wifiUsersChart,
  temperatureChartConfig,
  humidityChartData,
  airPressureChart,
  airQualityChart,
  usbChargingsChart,
  eChargingChart,
  wirelessChargingChart,
  electricityConsumptionChart,
  batteryChartConfig,
  solarChartConfig,
  createLineChart,
  createBarChart,
  sunnyHoursChartConfig,
  noiseChartConfig,
  uvChartConfig
} from './ChartsConfig/config';
import Card from '../../ui-elements/Card/card.component';
import Button from '../../ui-elements/Button/button.component';
import './bench.style.scss';
import translate from '../../../i18n/translate';
import benchWorkingStatusIcon from '../../../assets/icons/green_dot.png';
import benchHasProblemStatusIcon from '../../../assets/icons/bench-problem.png';
import benchNotWorkingStatusIcon from '../../../assets/icons/bench-not-working.png';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { isEmpty, round } from "../../../utils/functions";
import userRoles from '../../../utils/user-roles';
import {
  convertValuesToBinary,
  processBenchForCharts,
} from "../../../utils/process-chart-values";
import AuthStore from '../../../stores/auth/authStore';
import authRoles from '../../../utils/user-roles';
import BenchFormModal from "./BenchForm/bench-form-modal.component";
import DeleteBench from "./DeleteBench/delete-bench.component";

const Bench = (props) => {
  const { bench } = props.store;
  const [aws, setAws] = useState({});
  const { formatMessage } = useIntl();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const SUNNY_HOUR_LIMIT = 15;

  const benchMemo = useMemo(() => {
    // process the bench values for the charts
    const benchChartData = processBenchForCharts(bench, { isForSingleDay: !props.isDateFilterApplied });
    const sunnyHoursValues = convertValuesToBinary(benchChartData.ina219Solar, 'voltage', SUNNY_HOUR_LIMIT);

    return {
      tempTime: benchChartData.temperature?.map(({ time }) => time),
      tempValues: benchChartData.temperature?.map(({ temp }) => Math.floor(temp)),
      currentTemp: Math.floor(bench.temperature?.[bench.temperature.length - 1].temp),
      qualityTime: benchChartData.coLevel?.map(({ time }) => time),
      qualityValues: benchChartData.coLevel?.map(({ coLevel }) => coLevel),
      currentQuality: round(bench.coLevel?.[bench.coLevel.length - 1].coLevel, 2, true),
      humidityTime: benchChartData.humidity?.map(({ time }) => time),
      humidityValues: benchChartData.humidity?.map(({ humidity }) => Math.floor(humidity)),
      currentHumidity: Math.floor(bench.humidity?.[bench.humidity.length - 1].humidity),
      pressureTime: benchChartData.pressure?.map(({ time }) => time),
      pressureValues: benchChartData.pressure?.map(({ pressure }) => pressure),
      currentPressure: round(bench.pressure?.[bench.pressure.length - 1].pressure, 2, true),
      WCDTime: benchChartData.ina219Wireless?.map(({ time }) => time),
      WCDValues: benchChartData.ina219Wireless?.map(
        ({ chargerWattHour }) => chargerWattHour
      ),
      wirelessChargingSum: benchChartData.wirelessWattHoursTotal,
      UCDTime: benchChartData.ina219Usb?.map(({ time }) => time),
      UCDValues: benchChartData.ina219Usb?.map(({ chargerWattHour }) => chargerWattHour),
      USBChargingSum: benchChartData.usbWattHoursTotal,
      EChargingTime: benchChartData.acs712?.map(({ time }) => time),
      EChargingValues: benchChartData.acs712?.map(
        ({ eChargerWattHour }) => eChargerWattHour
      ),
      EChargingSum: benchChartData.eWattHoursTotal,
      wifiUserTime: benchChartData.userWifiConnected?.map(({ time }) => time),
      wifiUserValues: benchChartData.userWifiConnected?.map(
        ({ uniqueUsers }) => uniqueUsers
      ),
      wifiUserSum: bench.wifiUsersTotal,
      cpuTempTime: benchChartData.cpu?.map(({ time }) => time),
      cpuTempValues: benchChartData.cpu?.map(({ cpuTemp }) => cpuTemp),
      kwConsumedTime: benchChartData.electricityConsumptions?.map(({ time }) => time),
      kwConsumedValues: benchChartData.electricityConsumptions?.map(
        ({ electricityConsumption }) => electricityConsumption
      ),
      elConsumedSum: benchChartData.electricityConsumptionsTotal,
      batteryConsumedTime: benchChartData.ina219Battery?.map(({ time }) => time),
      batteryConsumedValues: benchChartData.ina219Battery?.map(({ voltage }) => voltage),
      solarConsumedTime: benchChartData.ina219Solar?.map(({ time }) => time),
      solarConsumedValues: benchChartData.ina219Solar?.map(({ voltage }) => voltage),
      sunnyHoursTime: sunnyHoursValues?.map(({ time }) => time),
      sunnyHoursValues: sunnyHoursValues?.map(({ voltage }) => voltage),
      noiseTime: benchChartData.noise.map(({ time }) => time),
      noiseValues: benchChartData.noise.map(({ dbLevel }) => dbLevel),
      uvTime: benchChartData.uv.map(({ time }) => time),
      uvValues: benchChartData.uv.map(({ uvLevel }) => uvLevel),
    };
  }, [bench]);

  const extractBenchSensorData = bench => {
    return {
      acs712: bench.acs712,
      humidity: bench.humidity,
      airQuality: bench.coLevel,
      airPressure: bench.pressure,
      cpuTemperature: bench.cpu,
      electricity: bench.electricityConsumptions,
      ina219Battery: bench.ina219Battery,
      ina219Solar: bench.ina219Solar,
      ina219Usb: bench.ina219Usb,
      ina219Wireless: bench.ina219Wireless,
      temperature: bench.temperature,
      usbChargings: bench.usbChargeHours,
      uv: bench.uv,
      noise: bench.noise,
      wifiUsers: bench.userWifiConnected
    }
  }

  useEffect(() => {
    // print real data from the backend to the console if the role is admin
    if(!isEmpty(bench) && props.profile.role === userRoles.SUPER_ADMIN) {
      const benchSensorsData = extractBenchSensorData(bench);
      console.log(`${formatMessage({ id: 'Bench' })}: ${bench.givenId}`);
      console.log(benchSensorsData);
    }
  }, [bench]);

  const sumData = [
    benchMemo.wifiUserSum ? Math.round(benchMemo.wifiUserSum) : "Failure",
    benchMemo.elConsumedSum ? Math.round(benchMemo.elConsumedSum) : "Failure",
    benchMemo.USBChargingSum ? Math.round(benchMemo.USBChargingSum) : "Failure",
    benchMemo.EChargingSum ? Math.round(benchMemo.EChargingSum) : "Failure",
    benchMemo.wirelessChargingSum ? Math.round(benchMemo.wirelessChargingSum) : "Failure",
  ];

  const chartsDefaultOptions = {
    use12Hours: !props.isDateFilterApplied && !isEmpty(bench)
  };

  const benchChartDataMemo = useMemo(() => {
    return {
      cpuTemperature: (canvas) => createLineChart(canvas, benchMemo.cpuTempTime, benchMemo.cpuTempValues,  {
        ...chartsDefaultOptions,
        labelText: '℃'
      }),
      temperature: createBarChart(benchMemo.tempTime, benchMemo.tempValues, {
        ...chartsDefaultOptions,
        labelText: '℃'
      }),
      airQuality: (canvas) => createLineChart(canvas, benchMemo.qualityTime, benchMemo.qualityValues,  {
        ...chartsDefaultOptions,
        labelText: 'Ppm'
      }),
      airPressure: (canvas) => createLineChart(canvas, benchMemo.pressureTime, benchMemo.pressureValues,  {
        ...chartsDefaultOptions,
        labelText: 'Mbar'
      }),
      wifiUsers: (canvas) => createLineChart(canvas, benchMemo.wifiUserTime, benchMemo.wifiUserValues,  {
        ...chartsDefaultOptions,
        labelText: '/h'
      }),
      humidity: (canvas) => createLineChart(canvas, benchMemo.humidityTime, benchMemo.humidityValues,  {
        ...chartsDefaultOptions,
        labelText: '%'
      }),
      wifiCharging: (canvas) => createLineChart(canvas, benchMemo.WCDTime, benchMemo.WCDValues,  {
        ...chartsDefaultOptions,
        labelText: '/h'
      }),
      usbCharging: (canvas) => createLineChart(canvas, benchMemo.UCDTime, benchMemo.UCDValues,  {
        ...chartsDefaultOptions,
        labelText: '/h'
      }),
      eCharging: (canvas) => createLineChart(canvas, benchMemo.EChargingTime, benchMemo.EChargingValues,  {
        ...chartsDefaultOptions,
        labelText: '/h'
      }),
      kwConsumed: (canvas) => createLineChart(canvas, benchMemo.kwConsumedTime, benchMemo.kwConsumedValues,  {
        ...chartsDefaultOptions,
        labelText: 'W/h'
      }),
      battery: (canvas) => createLineChart(canvas, benchMemo.batteryConsumedTime, benchMemo.batteryConsumedValues, {
        ...chartsDefaultOptions,
        labelText: 'V'
      }),
      solar: (canvas) => createLineChart(canvas, benchMemo.solarConsumedTime, benchMemo.solarConsumedValues,  {
        ...chartsDefaultOptions,
        labelText: 'kW'
      }),
      sunnyHours: createBarChart(benchMemo.sunnyHoursTime, benchMemo.sunnyHoursValues,  {
        ...chartsDefaultOptions,
        labelText: '',
      }),
      noise: (canvas) => createLineChart(canvas, benchMemo.noiseTime, benchMemo.noiseValues, {
        ...chartsDefaultOptions,
        labelText: 'dB'
      }),
      uv: (canvas) => createLineChart(canvas, benchMemo.uvTime, benchMemo.uvValues, {
        ...chartsDefaultOptions,
        labelText: ''
      }),
    }
  }, [benchMemo]);

  const cpuTemperatureChartData = benchChartDataMemo.cpuTemperature;
  const temperatureChartData = benchChartDataMemo.temperature;
  const airQualityChartData = benchChartDataMemo.airQuality;
  const airPressureChartData = benchChartDataMemo.airPressure;
  const WiFiUsersChartData = benchChartDataMemo.wifiUsers;
  const humidityChartDataToShow = benchChartDataMemo.humidity;
  const wifiChargingData = benchChartDataMemo.wifiCharging;
  const usbChargingChartData = benchChartDataMemo.usbCharging;
  const eChargingChartData = benchChartDataMemo.eCharging;
  const kwConsumedChartData = benchChartDataMemo.kwConsumed;
  const batteryChartData = benchChartDataMemo.battery;
  const solarChartData = benchChartDataMemo.solar;
  const sunnyHoursChartData = benchChartDataMemo.sunnyHours;
  const noiseChartData = benchChartDataMemo.noise;
  const uvChartData = benchChartDataMemo.uv;

  const areBenchActionsAvailable = props.profile.role === userRoles.SUPER_ADMIN && !isEmpty(bench);

  useEffect(() => {
    props.store
      .getAwsCertificates(props.benchId)
      .then(() =>
        setAws(
          'text/json;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(props.store.aws, null, 4))
        )
      );
  }, [props.benchId]);

  const HandleBenchStatus = (bench) => {
    if (bench.bench) {
      switch (bench.bench.status) {
        case 'active':
          return (
            <img src={benchWorkingStatusIcon} className="bench_status_icon" alt="Active bench" />
          );
        case 'problem':
          return (
            <img
              src={benchHasProblemStatusIcon}
              className="bench_status_icon"
              alt="Problem with bench"
            />
          );
        case 'inactive':
          return (
            <img
              src={benchNotWorkingStatusIcon}
              className="bench_status_icon"
              alt="Inactive bench"
            />
          );
        default:
          return <p>{bench.status}</p>;
      }
    }
  };

  const openEditBenchModal = () => setShowEditModal(true);

  const closeEditBenchModal = () => setShowEditModal(false);

  const openDeleteBenchModal = () => setShowDeleteModal(true);

  const closeDeleteBenchModal = () => setShowDeleteModal(false);

  const onFinishBenchDelete = () => props.reloadScreen();

  return (
    <div className="benches__container">
      <BenchFormModal
        visible={showEditModal}
        onCancel={closeEditBenchModal}
        bench={bench}
      />

      <AntModal
        visible={showDeleteModal}
        onCancel={closeDeleteBenchModal}
        destroyOnClose={true}
        closable={true}
        maskClosable={true}
        centered
        title={
          <h3 className="benches-form__add-bench-modal-title">
            {formatMessage({ id: 'Delete bench' })} #{bench?.givenId}
          </h3>
        }
        footer={false}
      >
        <DeleteBench
          onClose={closeDeleteBenchModal}
          bench={bench}
          onFinish={onFinishBenchDelete}
        />
      </AntModal>

      {areBenchActionsAvailable && (
        <Row className="bench_action_buttons_container" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col>
            <Button
                btnText={translate('Edit')}
                icon={<EditOutlined />}
                onClick={openEditBenchModal}
            />
            <Button
                btnText={translate('Delete')}
                icon={<DeleteOutlined />}
                onClick={openDeleteBenchModal}
                danger
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={13} xxl={12}>
          <Card>
            <Row gutter={16}>
              <Col md={14} xs={24}>
                <span className="bench_data_section_title">
                  {translate('Location')}
                </span>

                <p className="bench_data_section_units">
                  {bench.location ? bench.location.address : 'X'}
                </p>
              </Col>
              <Col md={7} xs={24}>
                <span className="bench_data_section_title">
                  {translate('Owner')}
                </span>
                <p className="bench_data_section_units">
                  {bench.user ? bench.user.name : 'X'}
                </p>
              </Col>
              <Col md={3} xs={24}>
                <div className="bench_status_wrapper">
                  <span className="bench_data_section_title">
                    {translate('Status')}
                  </span>
                  <HandleBenchStatus bench={bench} />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={12}>
          <Card>
            <Row gutter={16}>
              <Col md={6} xs={24}>
                <span className="bench_data_section_title">
                  {translate('Temperature')}
                </span>
                <p className="bench_data_section_units">
                  {benchMemo.currentTemp ? `${benchMemo.currentTemp} ℃` : 'X'}
                </p>
              </Col>
              <Col md={6} xs={24}>
                <span className="bench_data_section_title">
                  {translate('Humidity')}
                </span>
                <p className="bench_data_section_units">
                  {benchMemo.currentHumidity
                    ? `${benchMemo.currentHumidity}%`
                    : 'X'}
                </p>
              </Col>
              <Col md={6} xs={24}>
                <span className="bench_data_section_title">
                  {translate('Air quality')}
                </span>
                <p className="bench_data_section_units">
                  {benchMemo.currentQuality
                    ? `${benchMemo.currentQuality} / Index`
                    : 'X'}
                </p>
              </Col>
              <Col md={6} xs={24}>
                <span className="bench_data_section_title">
                  {translate('Air pressure')}
                </span>
                <p className="bench_data_section_units">
                  {benchMemo.currentPressure
                    ? `${benchMemo.currentPressure} Mbar`
                    : 'X'}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <span className="benches__card-title_usage_summary">
              {translate('Usage summary')}
            </span>

            <Row
              gutter={[2, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              align="middle"
              justify="start"
            >
              {summaryData.map((data) => (
                <Col key={data.id} xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
                  <div className="data_wrapper" key={data.id}>
                    <img src={data.src} className="usage-summary-icon" alt="usage-summary-icon" />

                    <div className="data_container">
                      <span className="data_value">
                        {sumData[data.id].toString()}
                      </span>
                      <span className="data_unit">{translate(data.text)}</span>
                    </div>
                  </div>
                </Col>
              ))}
              {props.profile.role === userRoles.SUPER_ADMIN && (
                <Button
                  btnStyle={{ float: 'right' }}
                  className="add-bench-btn"
                  size="large"
                  type="primary"
                  disabled={!props.store.aws}
                  btnText={
                    <a href={`data: ${aws}`} download="sys_config.json">
                      {translate('Download config')}
                    </a>
                  }
                />
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 24 }]} justify="start">
        {AuthStore.logedUser.role === authRoles.SUPER_ADMIN && bench?.sensorConfig?.cpu && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={cpuTemperatureChartData}
                options={
                  dataUsageChartConfig(
                    formatMessage({ id: 'CPU Temperature / °C' })
                  ).options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.bme280 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Bar
                data={temperatureChartData}
                options={
                  temperatureChartConfig(
                    formatMessage({ id: 'Temperature / °C' })
                  ).options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.bme280 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={airPressureChartData}
                options={
                  airPressureChart(formatMessage({ id: 'Air Pressure / Mbar' }))
                    .options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.mq7 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={airQualityChartData}
                options={
                  airQualityChart(formatMessage({ id: 'Air Quality / Ppm' }))
                    .options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.ina219Wireless && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={wifiChargingData}
                options={
                  wirelessChargingChart(
                    formatMessage({ id: 'Wireless Charging / Wh' })
                  ).options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.ina219USB && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={usbChargingChartData}
                options={
                  usbChargingsChart(formatMessage({ id: 'USB Charging / Wh' }))
                    .options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.wifimonitor && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={WiFiUsersChartData}
                options={
                  wifiUsersChart(formatMessage({ id: 'Wi-Fi Users / h' }))
                    .options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.acs712 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={eChargingChartData}
                options={
                  eChargingChart(formatMessage({ id: 'E-Charging / Wh' }))
                    .options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.bme280 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={humidityChartDataToShow}
                options={
                  humidityChartData(formatMessage({ id: 'Humidity / %' }))
                    .options
                }
              />
            </Card>
          </Col>
        )}

        {bench.electricityConsumptions !== null && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={kwConsumedChartData}
                options={
                  electricityConsumptionChart(
                    formatMessage({ id: 'Electricity Consumption / Wh' })
                  ).options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.ina219Battery && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={batteryChartData}
                options={
                  batteryChartConfig(formatMessage({ id: 'Battery / V' }))
                    .options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.ina219Solar && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={solarChartData}
                options={
                  solarChartConfig(formatMessage({ id: 'Solar / kW' })).options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.ina219Solar && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Bar
                data={sunnyHoursChartData}
                options={
                  sunnyHoursChartConfig(formatMessage({ id: 'Sunny Hours' })).options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.ky037 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={noiseChartData}
                options={
                  noiseChartConfig(formatMessage({ id: 'Noise / dB' })).options
                }
              />
            </Card>
          </Col>
        )}

        {bench?.sensorConfig?.veml6070 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <Card>
              <Line
                data={uvChartData}
                options={
                  uvChartConfig(formatMessage({ id: 'UV' })).options
                }
              />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default inject('BenchStore')(observer(Bench));
