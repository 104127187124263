import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from './utils/private-route';
import { I18nProvider, LOCALES } from './i18n';
import { inject, observer } from 'mobx-react';
import './App.scss';
import { SiderProvider } from './stores/sider/SiderContext';
import AuthStore from './stores/auth/authStore';
import LocalesStore from './stores/locales/localesStore';
import UsersStore from './stores/users/usersStore';
import BenchStore from './stores/bench/benchStore';
import ContentStore from './stores/content/contentStore';

//Landing Screen
import LandingScreen from './pages/LandingScreen';

// Public Map Screen
import PublicMapScreen from './pages/PublicMapScreen';
// Auth Screens
import LoginScreen from './pages/LoginScreen';
import ForgotPasswordScreen from './pages/ForgotPasswordScreen';
import ResetPasswordScreen from './pages/ResetPasswordScreen';

// Navigation components
import Sider from './components/navigation/Sider/sider.component';
import Header from './components/navigation/Header/header.component';

// Dashboard Screens
import HomeScreen from './pages/HomeScreen';
import UsersScreen from './pages/UsersScreen';
import ContentScreen from './pages/ContentScreen';
import BenchesScreen from './pages/BenchesScreen';

const { Content } = Layout;

const App = () => {
  const [locale, setLocale] = useState(LOCALES.ENGLISH);
  useEffect(() => {
    LocalesStore.loadLanguage();
    if (LocalesStore.language) {
      switch (LocalesStore.language) {
        case 'bosnian':
          setLocale(LOCALES.SERBIAN);
          break;
        default:
          setLocale(LOCALES.ENGLISH);
      }
    }
  }, [LocalesStore.language]);

  return (
    <div data-test="component-data">
      <I18nProvider locale={locale}>
        <Switch>
          <Route exact path="/">
            <LandingScreen />
          </Route>

          <Route exact path="/public-map">
            <PublicMapScreen />
          </Route>

          <Route exact path="/login">
            <LoginScreen />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPasswordScreen />
          </Route>

          <>
            <Layout style={{ minHeight: '100vh' }} className="layout">
              <SiderProvider>
                <Sider />
              </SiderProvider>
              <Layout className="site-layout">
                <Header
                  usersStore={UsersStore}
                  localesStore={LocalesStore}
                  authStore={AuthStore}
                  benchStore={BenchStore}
                  contentStore={ContentStore}
                />
                <Content
                  className="site-layout-background"
                  style={{
                    margin: '24px 40px',
                    padding: 24,
                    minHeight: 280,
                  }}
                >
                  <Switch>
                    <PrivateRoute exact path="/reset_password">
                      <ResetPasswordScreen />
                    </PrivateRoute>
                  </Switch>
                  <Switch>
                    <PrivateRoute exact path="/home" component={HomeScreen} />
                  </Switch>
                  <Switch>
                    <PrivateRoute exact path="/users" component={UsersScreen} />
                  </Switch>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/benches"
                      component={BenchesScreen}
                    />
                  </Switch>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/content"
                      component={ContentScreen}
                    />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </>
        </Switch>
      </I18nProvider>
    </div>
  );
};

export default inject('LocalesStore')(observer(App));
