import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button as AntButton } from 'antd';

import './button.style.scss';

const Button = ({
  disabled,
  ghost,
  href,
  icon,
  loading,
  shape,
  size,
  target,
  type,
  onClick,
  block,
  danger,
  className,
  btnStyle,
  btnText,
  htmlType,
  transparent = false
}) => {
  return (
    <AntButton
      data-test="reusable-button"
      className={classnames(`btn-primary ${danger ? 'btn-danger' : ''} ${transparent ? 'btn-transparent' : ''} ${className ? className : ''}`)}
      disabled={disabled}
      ghost={ghost}
      href={href}
      icon={icon}
      loading={loading}
      shape={shape}
      size={size}
      target={target}
      type={type}
      onClick={onClick}
      block={block}
      danger={danger}
      style={btnStyle}
      htmlType={htmlType}
    >
      {btnText}
    </AntButton>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.node,
  loading: PropTypes.bool,
  shape: PropTypes.string,
  size: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  block: PropTypes.bool,
  danger: PropTypes.bool,
  btnStyle: PropTypes.object,
  className: PropTypes.string,
  btnText: PropTypes.any,
  htmlType: PropTypes.string,
};

export default Button;
