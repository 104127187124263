/**
 * Check if the variable is empty whether it is object, array or string
 * @param variable
 * @returns {boolean}
 */
export const isEmpty = (variable) => {
    if(!variable) {
        return false;
    }

    if(variable.constructor === Object) {
        return Object.keys(variable).length === 0;
    }

    return variable.length === 0;
}

/**
 * Add number of leading zeroes to the string or number.
 * @param {any} num
 * @param places
 * @returns {string}
 */
export const addLeadingZeroes = (num, places) => String(num).padStart(places, '0');

/**
 * Round the number to the desired number of decimals.
 * @param num
 * @param places
 * @param asString
 * @returns {string|number|void}
 */
export const round = (num, places = 2, asString = false) => {
    if(!num) {
        return;
    }
    const result = num.toFixed(places);
    if(asString) {
        return result;
    }
    return +result;
}
