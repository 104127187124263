import React from 'react';
import { Card as AntCard } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './card.style.scss';

const Card = ({
  actions,
  activeTabKey,
  headStyle,
  bodyStyle,
  bordered,
  cover,
  defaultActiveTabKey,
  extra,
  hoverable,
  loading,
  tabList,
  tabBarExtraContent,
  size,
  title,
  type,
  onTabChange,
  className,
  style,
  ...props
}) => (
  <AntCard
    data-test="reusable-card"
    actions={actions}
    activeTabKey={activeTabKey}
    headStyle={headStyle}
    bodyStyle={bodyStyle}
    bordered={bordered}
    cover={cover}
    defaultActiveTabKey={defaultActiveTabKey}
    extra={extra}
    hoverable={hoverable}
    loading={loading}
    tabList={tabList}
    tabBarExtraContent={tabBarExtraContent}
    size={size}
    title={title}
    type={type}
    onTabChange={onTabChange}
    className={classnames(`ant-card-custom ${className}`)}
    style={style}
  >
    {props.children}
  </AntCard>
);

Card.propsTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  activeTabKey: PropTypes.string,
  headStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
  bordered: PropTypes.bool,
  cover: PropTypes.node,
  defaultActiveTabKey: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hoverable: PropTypes.bool,
  loading: PropTypes.bool,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      tab: PropTypes.node,
    })
  ),
  tabBarExtraContent: PropTypes.node,
  size: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onTabChange: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Card;
