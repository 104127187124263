import React from 'react';
import { Form, Input, Row, Col, message } from 'antd';
import Auth from '../../../utils/auth';
import { inject, observer } from 'mobx-react';
import translate from '../../../i18n/translate';

import Card from '../../ui-elements/Card/card.component';
import Button from '../../ui-elements/Button/button.component';

import './reset-password.style.scss';

const ResetPassword = (props) => {
  const token = Auth.getToken();
  const decodedToken = Auth.decodeToken(token);

  const onFinish = (values) => {
    const userData = {
      oldPassword: values.password,
      newPassword: values['new-password'],
    };
    props.store.resetPassword(decodedToken.id, userData);
  };

  return (
    <div className="reset-password__container">
      <Row justify="center">
        <Col xxl={20} xl={23} lg={23} md={23} sm={23} xs={23}>
          <Card className="reset-password__card">
            <Row style={{ height: '100%' }}>
              <Col
                xxl={12}
                xl={12}
                lg={14}
                md={0}
                sm={0}
                xs={0}
                className="reset-password__about-section"
              ></Col>
              <Col
                xxl={12}
                xl={12}
                lg={10}
                md={24}
                sm={24}
                xs={24}
                className="reset-password__form-container"
              >
                <h1 className="reset-password__login-title">
                  {translate('Reset Password')}
                </h1>
                <Form name="reset-password-form" onFinish={onFinish}>
                  <Input.Group className="reset-password__input-group">
                    <Form.Item
                      name="password"
                      label={translate('Password')}
                      rules={[
                        {
                          required: true,
                          message: translate('Please enter your password!'),
                        },
                      ]}
                    >
                      <Input
                        type="password"
                        className="reset-password__input"
                        placeholder="Enter your password"
                      />
                    </Form.Item>
                    <Form.Item
                      label={translate('New password')}
                      name="new-password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: translate('Please enter your new password!'),
                        },
                        {
                          min: 6,
                          max: 99,
                          message: translate(
                            'Password has to contain at least 6 characters.'
                          ),
                        },
                      ]}
                    >
                      <Input
                        type="password"
                        className="reset-password__input"
                        placeholder="Enter your new password"
                      />
                    </Form.Item>
                    <Form.Item
                      label={translate('Confirm new password')}
                      name="confirm-password"
                      dependencies={['new-password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: translate('Please confirm your password!'),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue('new-password') === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              translate('Passwords have to match!')
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        type="password"
                        className="reset-password__input"
                        placeholder="Enter your new password again"
                      />
                    </Form.Item>
                    <Row>
                      <Col xl={6} lg={8} md={6} sm={8} xs={12}>
                        <Button
                          btnStyle={{ float: 'right' }}
                          size="large"
                          type="primary"
                          htmlType="submit"
                          block
                          btnText={<span>{translate('Save')}</span>}
                        />
                      </Col>
                    </Row>
                  </Input.Group>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default inject('AuthStore')(observer(ResetPassword));
