import axios from 'axios';
import { message, notification } from 'antd';
import Auth from '../utils/auth';
import translate from '../i18n/translate';

const config = process.env.REACT_APP_API_HOST;
const instance = axios.create({
  baseURL: config,
});
instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.headers.post['Content-Type'] =
  'application/json, charset=utf-8';
instance.defaults.adapter = require('axios/lib/adapters/http');
instance.interceptors.request.use(
  (config) => {
    const token = Auth.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   function (error) {
//     const originalRequest = error.config;
//     if (
//       error.response.status === 401 &&
//       originalRequest.url === `${instance.baseURL}/auth/token`
//     ) {
//       return Promise.reject(error);
//     }
//     originalRequest._retry = false;
//     if (error.response.status === 401) {
//       // if (
//       //   window.location.pathname !== '/' ||
//       //   window.location.pathname !== '/login' ||
//       //   window.location.pathname !== '/forgot-password'
//       // ) {
//       //   return (window.location.href = '/login');
//       // }
//       // return message.error(
//       //   translate('Something went wrong. Check your email and password again.')
//       // );
//       // return notification.error({
//       //   message: 'Error while logging in!',
//       //   description: 'No user with this credentials.',
//       // });
//         return error
//     }
//     // return Error object with Promise
//     // message.destroy();
//     // return error ? message.error(error.response.data.message) : null;
//     // return Promise.reject(error);
//     // return error;
//   }
// );
export default instance;
