import { LOCALES } from '../locales';

export default {
  [LOCALES.SERBIAN]: {
    'No bench to show': 'Klupe ne postoje',

    Search: 'Pretraga',
    'Change language': 'Promijeni jezik',
    'United Kingdom': 'Velika Britanija',
    'Bosnia and Herzegovina': 'Bosna i Hercegovina',
    Notifications: 'Obavještenja',
    Options: 'Opcije',
    'Clear all': 'Obriši sve',
    'Mark all as read': 'Označi sve kao pročitane',
    'Profile options': 'Profilne opcije',
    'Change password': 'Promijeni lozinku',
    Logout: 'Odjavi se',
    Alarms: 'Alarmi',
    Requests: 'Zahtjevi',
    History: 'Istorija',
    'There are no alarms to show!': 'Trenutno nema alarma za prikazati!',
    'There are no requests to show!': 'Trenutno nema zahtijeva za prikazati!',
    'There are no activities to show!':
      'Trenutno nema aktivnosti za prikazati!',
    Home: 'Početna',
    Users: 'Korisnici',
    Benches: 'Klupe',
    Content: 'Sadržaj',
    'Add Bench': 'Dodaj klupu',
    'ADD BENCH': 'DODAJ KLUPU',
    'Reset Password': 'Promijeni lozinku',
    Password: 'Lozinka',
    'New password': 'Nova lozinka',
    'Confirm new password': 'Potvrdi novu lozinku',
    'Please enter your password!': 'Molimo unesite vašu lozinku!',
    'Please enter your new password!': 'Molimo unesite vašu novu lozinku!',
    'Password has to contain at least 6 characters.':
      'Lozinka mora sadržavati najmanje 6. karaktera.',
    'Passwords have to match!': 'Lozinke se moraju podudarati!',
    'Please confirm your password!': 'Molimo potvrdite vašu lozinku!',
    Login: 'Prijava',
    'E-mail': 'E-pošta',
    'Please enter a valid E-mail.': 'Molimo unesite ispravan E-mail.',
    'Please enter your E-mail.': 'Molimo unesite vaš E-mail.',
    'Please enter your password.': 'Molimo unesite vašu lozinku.',
    'Forgot password?': 'Zaboravili ste lozinku?',
    'Enter the email address you used when you joined and we’ll send you instructions to reset your password.':
      'Unesite adresu e-pošte koju ste koristili kada ste se pridružili i poslaćemo vam uputstva za resetovanje lozinke.',
    'Email Address': 'Adresa E-pošte',
    Send: 'Pošalji',
    'Are you sure you want to delete this client?':
      'Da li želite da izbrišete ovog klijenta?',
    'Add Client': 'Dodaj klijenta',
    'ADD CLIENT': 'DODAJ KLIJENTA',
    'Add Branch': 'Dodaj filijalu',
    'ADD BRANCH': 'DODAJ FILIJALU',
    'If you want to add or remove branch for a certain client, find wanted client in clents list, go to options button and select "Add/Remove Branch" in drop down menu.':
      'Ako želite da dodate ili uklonite filijalu za određenog klijenta, pronađite željenog klijenta u listi, idite na dugme sa opcijama i u padajućem meniju izaberite „Dodaj / ukloni filijalu“.',
    'If you want to add or remove a bench for a certain branch, find wanted branch in clents list, go to options button and select "Add/Remove Bench" in drop down menu.':
      'Ako želite da dodate ili uklonite klupu za određenu filijalu, pronađite željenu filijalu u listi, idite na dugme sa opcijama i u padajućem meniju izaberite „Dodaj / ukloni klupu“.',
    CLIENTS: 'KLIJENTI',
    BRANCHES: 'FILIJALE',
    'Delete Client': 'Obriši klijenta',
    'Edit Client': 'Uredi klijenta',
    'Add/Remove Branch': 'Dodaj/ukloni filijalu',
    branches: 'filijale',
    'No users found.': 'Korisnici nisu pronađeni.',
    Active: 'Aktivan',
    Inactive: 'Neaktivan',
    'Loading clients...': 'Učitavam klijente...',
    'Loading branches...': 'Učitavam filijale...',
    'Delete Branch': 'Obriši filijalu',
    'Edit Branch': 'Uredi filijalu',
    'Add/Remove Bench': 'Dodaj/ukloni klupu',
    benches: 'klupe',
    'No branches found.': 'Filijale nisu pronađene.',
    'Active Bench': 'Aktivna',
    'Inactive Bench': 'Neaktivna',
    'ADD CONTENT': 'DODAJ SADRŽAJ',
    'Content Name': 'Naziv sadržaja',
    'Please enter content name.': 'Molimo unesite naziv sadržaja.',
    'Please select start and end time.': 'Molimo unesite trajanje sadržaja.',
    Branch: 'Filijala',
    'Drop content here or': 'Prevucite sadržaj ili',
    browse: 'pretražite',
    Notes: 'Zabilješke',
    'Start and end time': 'Vrijeme trajanja sadržaja',
    'No branch selected.': 'Nema odabranih filijala.',
    'No benches added.': 'Nema dodatih klupa.',
    'Delete Content': 'Obriši sadržaj',
    'Move Content': 'Pomjeri sadržaj',
    'No content found.': 'Sadržaj nije pronađen.',
    'Loading content...': 'Učitavanje sadržaja...',
    'Bench list': 'Lista klupa',
    'Please select bench(benches) you want to add content to.':
      'Molimo odaberite klupu/klupe kojima želite da dodijelite sadržaj.',
    Pending: 'Na čekanju',
    'Content request': 'Zahtjev za sadržaj',
    'List is empty.': 'Lista je prazna.',
    'You have to select branch or bench first!':
      'Morate prvo odabrati filijalu ili klupu!',
    Approved: 'Odobreno',
    Declined: 'Odbijeno',
    'Loading...': 'Učitavanje...',
    Comment: 'Komentar',
    'Edit Content': 'Izmijeni sadržaj',
    'Ad Blocker warning!': 'Upozorenje za Ad Blocker!',
    'Warning!': 'Upozorenje!',
    'If you have your Ad Blocker active or any similar extension, please disable it and click on "Reload" button to refresh the page.':
      'Ako Vam je aktiviran Ad Blocker ili neka slična ekstenzija, molimo Vas da je deaktivirate i kliknete na „Osvježi“ dugme da biste osvježili stranicu',
    Reload: 'Osvježi',
    'Select Period': 'Odaberite period',
    'CPU Temperature': 'Temperatura procesora',
    Export: 'Eksport',
    'Export Reports': 'Eksportuj izvještaje',
    '°C Offset': '°C Odstupanje',
    Accept: 'Prihvati',
    'Bench ID': 'ID klupe',
    'Bench ID must be atleast 3 characters':
      'Bench ID mora sadržavati najmanje 3 karaktera',
    Type: 'Tip',
    Wood: 'Drvena',
    Solar: 'Solarna',
    Wheel: 'Točak',
    Sensors: 'Senzori',
    'Air (temperature, pressure, humidity)':
      'Vazduh (temperatura, pritisak, vlažnost)',
    'GPS (bench location)': 'GPS (lokacija klupe)',
    'CO (Carbon Monoxide)': 'CO (Karbon monoksid)',
    Power: 'Napon',
    'Clear All Fields': 'Očisti sva polja',
    'Show benches': 'Prikaži klupe',
    'Generate config file for bench':
      'Generisanje konfiguracionog fajla za klupu',
    Configuration: 'Konfiguracija',
    Shuffle: 'Naizmjenično',
    Unavailable: 'Nedostupno',
    Temperature: 'Temperatura',
    'Wireless chargings': 'Bežična punjenja',
    'Wi-fi users': 'Wifi korisnici',
    'Wh consumed': 'Wh potrošeni',
    'Download config': 'Preuzmi config',
    'There is no branch with this name.': 'Nema filijale sa ovim nazivom',
    'WiFi monitor': 'WiFi nadzor',
    Download: 'Preuzmi',
    Cancel: 'Otkaži',
    'Download as:': 'Preuzmi kao:',
    'WiFi Users': 'Korisnici WiFi',
    Humidity: 'Vlažnost vazduha',
    'Air pressure': 'Vazdušni pritisak',
    'Air quality': 'Kvalitet vazduha',
    'Solar power': 'Solarna energija',
    'USB chargins': 'USB punjenja',
    'E-chargings': 'Električna punjenja',
    'Wireless charging': 'Bežično punjenje',
    'Sunny Hours': 'Sunčani sati',
    'Battery status': 'Stanje baterije',
    'Electricity generation': 'Proizvodnja električne energije',
    'Electricity consumption': 'Potrošnja električne energije',
    'Last day': 'Poslednji dan',
    'Last 7 days': 'Poslednjih 7. dana',
    Offset: 'Odstupanje',
    'Temperature offset': 'Temperaturno odstupanje',
    'WiFi Charging offset': 'Odstupanje Wi-Fi punjenja',
    'USB Charging offset': 'Odstupanje USB punjenja',
    'E Charging offset': 'Odstupanje električnog punjenja',
    Location: 'Lokacija',
    Owner: 'Vlasnik',
    Status: 'Stanje',
    'Air Quality': 'Kvalitet vazduha',
    'Usage summary': 'Pregled korišćenja',
    'There is no bench with this ID.': 'Ne postoji klupa sa ovim ID-em.',
    'Loading benches...': 'Učitavanje klupa...',
    'There are no benches under this branch.': 'Nema klupa pod ovom filijalom',
    Name: 'Ime',
    Confirm: 'Potvrdi',
    'Error uploading image!': 'Greška pri učitavanju slike',
    'This image is too big. Try with a smaller image(1920x1080 or less).':
      'Slika je prevelika. Pokušajte sa manjom slikom(1920x1080 ili manje).',
    'Error uploading file!': 'Greška pri učitavanju dokumenta',
    'This file is too big. Try with the smaller file(less then 50MB).':
      'Dokument je prevelik. Pokušajte sa manjim dokumentom(manje od 50MB).',
    'Are you sure you want to delete this content?':
      'Da li ste sigurni da želite da izbrišete ovaj sadržaj?',
    'sent content request': 'je poslao/la zahtjev za odobrenje sadržaja',
    Close: 'Zatvori',
    'E-mail is not valid': 'E-mail nije dobro unet',
    'Please enter your E-mail!': 'Unesite svoju e-mail adresu!',
    'Something went wrong!!!': 'Nešto nije u redu!!!',
    'Error loading maps...': 'Greška pri učitavanju mape...',
    'Loading map...': 'Učitavanje mape...',
    'There are no new notifications!': 'Nema novih obaveštenja!',
    'Something went wrong!': 'Nešto nije u redu!',
    'First name': 'Ime',
    'Please enter your first name': 'Molimo unesite svoje ime',
    'Last name': 'Prezime',
    'Please enter your last name': 'Molimo unesite svoje prezime',
    'Please enter a password!': 'Molimo unesite lozinku!',
    'Confirm Password': 'Potvrdite Lozinku',
    Register: 'Registrujte se',
    'There are no branches within this client.': 'Nema filijala za ovaj nalog',
    'Clear all fields': 'Očisti sva polja',
    'Please enter your full name!': 'Molimo unesite puno ime!',
    PIB: 'PIB',
    'Please enter your PIB!': 'Molimo unesite svoj PIB!',
    Address: 'Adresa',
    'Please enter your address!': 'Molimo unesite svoju adresu!',
    'Name and surname (RP)': 'Ime i prezime (RP)',
    'Name of the RP.': 'Ime RP-a.',
    'Please enter RP full name here!': 'Molimo unesite puno ime RP-a!',
    City: 'Grad',
    'Please enter your city!': 'Molimo unesite svoj grad!',
    State: 'Država',
    'Please enter your state!': 'Molimo unesite svoju državu!',
    Phone: 'Telefon',
    'Phone number is required': 'Telefonski broj je obavezno polje',
    'E-mail (RP)': 'E-mail adresa predstavnika',
    'Email of the RP.': 'E-mail adresa RP-a',
    'Login Email': 'E-mail naloga',
    'Please enter your email!': 'Molimo unesite Vašu e-mail adresu!',
    JIB: 'JIB',
    'Please enter your JIB! At least 13 characters is required':
      'Molimo unesite Vaš JIB! Barem 13. karaktera je potrebno',
    'Receive a newsletter?': 'Želite li da primate obaveštenja?',
    'Please enter branch name!': 'Molimo unesite ime filijale!',
    "Please enter branch owner's name!":
      'Molimo unesite ime vlasnika filijale!',
    'Error while logging in!': 'Greška prilikom prijave!',
    'Error occured!': 'Došlo je do greške!',
    'Success!': 'Uspešno izvršeno!',
    'Error loading benches!': 'Greška pri učitavanju klupa!',
    'Error loading bench!': 'Greška pri učitavanju klupe!',
    'Error adding bench!': 'Greška pri dodavanju klupe!',
    'Error updating bench!': 'Greška pri ažuriranju klupe!',
    'Error exporting reports!': 'Greška pri eksportu izveštaja!',
    'Please select a date period': 'Izaberite period',
    'Error retrieving aws certificates!': 'Greška pri čitanju aws sertifikata!',
    'Error loading content!': 'Greška pri učitavanju sadržaja!',
    'Error uploading content!': 'Greška pri slanju sadržaja!',
    'Error deleting content!': 'Greška pri brisanju sadržaja!',
    'Error loading users!': 'Greška pri učitavanju korisnika!',
    'Error loading branches!': 'Greška pri učitavanju filijala!',
    'Error loading user branches!':
      'Greška pri učitavanju korisnikovih filijala!',
    'Error!': 'Greška!',
    'Error loading branch benches!': 'Greška pri učitavanju klupa filijale!',
    'Error loading user!': 'Greška pri učitavanju korisnika!',
    'Error adding user!': 'Greška pri dodavanju korisnika!',
    'Error adding branch!': 'Greška pri dodavanju filijale!',
    'Error updating user!': 'Greška pri ažuriranju korisnika!',
    'Error updating branch!': 'Greška pri ažuriranju filijale!',
    'Error deleting user!': 'Greška pri brisanju korisnika!',
    'Error deleting branch!': 'Greška pri brisanju filijale!',
    'Tooltip.': 'Tooltip.',
    'No benches found.': 'Nema pronađenih klupa.',
    'No user with this credentials.': 'Nema korisnika sa ovim podacima.',
    'There is an error occured while trying to reset password. Please check your passwords again':
      'Greška pri obnavljanju lozinke. Molimo proverite lozinke ponovo',
    'Your request is sent successfully! Check your inbox to see new password.':
      'Vaš zahtev je poslat uspešno! Proverite e-mail da biste videli novu lozinku.',
    'Please check your email!': 'Molimo proverite Vaš e-mail!',
    'Please log in first!': 'Molimo prvo se prijavite!',
    'Something went wrong while loading benches! It might be up to server.':
      'Došlo je do greške pri učitavanju klupa! Moguće je da je do servera.',
    'Something went wrong while loading bench! It might be up to server.':
      'Došlo je do greške pri učitavanju klupe! Moguće je da je do servera',
    'There is an error occured while adding a bench. Try again?':
      'Došlo je do greške prilikom dodavanja klupe. Pokušajte ponovo?',
    'Something went wrong while updating bench! It might be up to server.':
      'Došlo je do greške prilikom ažuriranja klupe! Moguće je da je do servera.',
    'Something went wrong while exporting reports! It might be up to server.':
      'Došlo je do greške prilikom eksportovanja izveštaja! Moguće je da je do servera.',
    'Something went wrong while retrieving aws cetrificates for this bench! It might be up to server.':
      'Došlo je do greške prilikom učitavanja aws sertifikata za ovu klupu! Moguće je da je do servera.',
    'Something went wrong while loading content! It might be up to server.':
      'Došlo je do greške prilikom učitavanja sadržaja! Moguće je da je do servera.',
    'There is an error occured while trying to upload this content item.':
      'Došlo je do greške pri učitavanju sadržaja za predmet',
    'There is an error occured while trying to delete this content item.':
      'Došlo je do greške pri brisanju sadržaja za predmet',
    'Something went wrong while loading users! It might be up to server.':
      'Došlo je do greške prilikom učitavanja korisnika! Moguće je da je do servera.',
    'Something went wrong while loading branches! It might be up to server.':
      'Došlo je do greške prilikom učitavanja filijala! Moguće je da je do servera.',
    'Something went wrong while loading user branches! It might be up to server.':
      'Došlo je do greške prilikom učitavanja korisnikovih filijala! Moguće je da je do servera.',
    'Something went wrong while adding user branch.':
      'Došlo je do greške prilikom dodavanja korisnikove filijale.',
    'Something went wrong while deleting user branch.':
      'Došlo je do greške prilikom brisanja korisnikove filijale.',
    'Something went wrong while loading branch benches! It might be up to server.':
      'Došlo je do greške pri učitavanju klupa filijale! Moguće je da je do servera',
    'Something went wrong while adding branch bench.':
      'Došlo je do greške pri dodavanju klupe filijale.',
    'Something went wrong while deleting branch bench.':
      'Došlo je do greške pri brisanju klupe filijale.',
    'Error occured while trying to retrieve user data! It might be up to server.':
      'Došlo je do greške pri učitavanju korisničkih podataka! Moguće je da je do servera.',
    'Error occured while trying to retrieve branch data! It might be up to server.':
      'Došlo je do greške pri učitavanju podataka filijale! Moguće je da je do servera.',
    'Most likely you tried to add an user that already exists. Please check your user data again.':
      'Najverovatnije ste pokušali da dodate korisnika koji već postoji. Molimo proverite Vaše podatke o korisniku.',
    'Most likely you tried to add a branch that already exists. Please check your branch data again.':
      'Najverovatnije ste pokušali da dodate filijalu koja već postoji. Molimo proverite Vaše podatke o filijali.',
    'There is an error occured while trying to update this user.':
      'Došlo je do greške pri ažuriranju ovog korisnika.',
    'There is an error occured while trying to update this branch.':
      'Došlo je do greške pri ažuriranju ove filijale.',
    'There is an error occured while trying to delete this user.':
      'Došlo je do greške pri brisanju ovog korisnika.',
    'There is an error occured while trying to delete this branch.':
      'Došlo je do greške pri brisanju ove filijale.',
    Bench: 'Klupa',
    Save: 'Sačuvaj',
    Add: 'Dodaj',
    'Schuko socket': 'Šuko utičnica',
    'E-charging (Solar)': 'E-punjenje (Solarno)',
    'E-charging (Battery)': 'E-punjenje (Baterija)',
    'E-charging (USB)': 'E-punjenje (USB)',
    'E-charging (Wireless)': 'E-punjenje (Wireless)',
    'Please select type!': 'Molimo odaberite tip!',
    'Please select owner!': 'Molimo odaberite vlasnika!',
    'Please select location!': 'Molimo odaberite lokaciju!',
    'You are currently not allowed to add any content. Please, contact administrator for more deatils':
      'Trenutno nemate odobrenje da postavljate sadržaj. Za više informacija, molimo kontaktirajte administratora.',
    'New branch/branches added to your profile.':
      'Nova poslovnica/ce je dodata na profil.',
    'Your profile is created.': 'Vaš profil je kreiran.',
    'Your content request is approved': 'Vaš sadržaj je odobren.',
    'Your content request is declined': 'Vaš sadržaj je odbijen.',
    Client: 'Klijent',
    'Super Admin': 'Administrator',
    'CPU Temperature / °C': 'CPU Temperatura / °C',
    'Temperature / °C': 'Temperatura / °C',
    'Air Pressure / Mbar': 'Vazdušni Pritisak / Mbar',
    'Air Quality / Ppm': 'Kvalitet Vazduha / Ppm',
    'Wireless Charging / Wh': 'Bežično punjenje / Wh',
    'USB Charging / Wh': 'USB Punjenje / Wh',
    'Wi-Fi Users / h': 'Wi-Fi Korisnici / h',
    'E-Charging / Wh': 'Električno Punjenje / Wh',
    'Humidity / %': 'Vlažnost / %',
    'Electricity Consumption / Wh': 'Eletrična Potrošnja / Wh',
    'Battery / V': 'Akumulator / V',
    'Solar / kW': 'Solar / kW',
    'Are you sure you want to delete this branch?':
      'Da li ste sigurni da želite da obrišete ovu granu?',
    'View Content': 'Pregledaj sadržaj',
    'Edit': 'Izmjeni',
    'Delete': 'Izbriši',
    'Delete bench': 'Izbriši klupu',
    'Are you sure you want to delete this bench?': 'Da li ste sigurni da želite izbrisati ovu klupu?',
    'Bench successfully deleted': 'Klupa uspješno izbrisana',
    'Bench successfully updated': 'Klupa uspješno ažurirana',
    'Edit bench': 'Izmjeni klupu',
    'Set': 'Snimi',
    'Hide': 'Sakrij',
    'wrongPassword': 'Pogrešna lozinka',
    'internalServerError': 'Desio se problem na serveru',
    'Type your password in order to delete this bench.': 'Ukucajte svoju lozinku da biste izbrisali ovu klupu.',
    'benchGivenIdNotUnique': 'ID klupe je zauzet',
    'day': 'dan',
    'days': 'dana',
    'Type bench ID': 'Ukucajte ID klupe',
    'Please add at least one bench': 'Odaberite jednu ili više klupa',
    'Please choose at least one sensor': 'Odaberite jedan ili više senzora',
    'exportReportsBenchTooltip': 'Da biste izvršili eksport za jednu od klupa odaberite ponuđenu klupu iz padajućeg menija ispod.',
    'Problem': 'Problem',
    'Sensor type': 'Tip senzora',
    "Bench ID can't be negative": 'ID klupe ne moze biti negativan',
    'GPS': 'GPS senzor',
    'CPU': 'CPU senzor',
    'TEMPERATURE_PRESSURE_HUMIDITY': 'Temperaturni pritisak i vlažnost senzor',
    'ELECTRIC_POWER': 'Električna jačina senzor',
    'AIR_CO': 'Nivo CO u vazduhu senzor',
    'WIFI': 'WiFi senzor',
    'ELECTRIC_ACS712': 'Električni ACS712 senzor',
    'ELECTRIC_INA219_SOLAR': 'Električni INA219 solarni senzor',
    'ELECTRIC_INA219_BATTERY': 'Električni INA219 baterijski senzor',
    'ELECTRIC_INA219_USB': 'Električni INA219 usb senzor',
    'ELECTRIC_INA219_WIRELESS': 'Električni INA219 bežični senzor',
    'NOISE': 'Buka senzor',
    'benchDeactivatedDueToLackOfData': 'Deaktivirana zbog odsustva podataka',
    'Noise': 'Buka',
    'Noise / dB': 'Buka / dB',
    'Noise offset': 'Odstupanje buke',
    'UV': 'UL',
    'UV offset': 'Odstupanje UL'
  },
};
