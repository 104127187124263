import React from 'react';
import { Row, Col } from 'antd';
import translate from '../../../../../i18n/translate';

import Button from '../../../../ui-elements/Button/button.component';
import './reports-form-footer.style.scss';

const ReportsFormFooter = (props) => {
  return (
    <div className="reports-form__modal-footer">
      <Row align="middle" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xl={8} lg={8} md={9} sm={12} xs={12}>
          <Button
            loading={props.loading}
            size="large"
            type="primary"
            btnText={translate('Export')}
            block
            disabled={props.disabled}
            htmlType="submit"
          />
        </Col>
        <Col xl={4} lg={4} md={4} sm={8} xs={8}>
          <span
            onClick={props.closeModalHandler}
            className="reports-form__modal-footer-cancel"
          >
            {translate('Cancel')}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default ReportsFormFooter;
