import React from "react";
import { Spin as AntSpin } from "antd";
import PropTypes from "prop-types";

import "./spinner.style.scss";

const Spinner = ({
  delay,
  indicator,
  size,
  spinning,
  tip,
  wrapperClassName,
  className,
  style,
  ...props
}) => (
  <AntSpin
    data-test="reusable-spinner"
    delay={delay}
    indicator={indicator}
    size={size}
    spinning={spinning}
    tip={tip}
    wrapperClassName={wrapperClassName}
    className={className}
    style={style}
  >
    {props.children}
  </AntSpin>
);

Spinner.propTypes = {
  delay: PropTypes.number,
  indicator: PropTypes.node,
  size: PropTypes.string,
  spinner: PropTypes.bool,
  tip: PropTypes.any,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Spinner;
