import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import translate from '../i18n/translate';
import AuthStore from '../stores/auth/authStore';
import ContentStore from '../stores/content/contentStore';
import BenchStore from '../stores/bench/benchStore';

import Button from '../components/ui-elements/Button/button.component';
import Modal from '../components/ui-elements/Modal/modal.component';
import ContentForm from '../components/containers/Content/ContentForm/content-form.component';
import Content from '../components/containers/Content/content.component';
import '../assets/styles/content.scss';

const ContentScreen = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    AuthStore.getProfile().then(() => setProfile(AuthStore.logedUser));
  }, []);

  const openModalHandler = () => setShowModal(true);

  const closeModalHandler = () => setShowModal(false);
  return (
    <div className="content-screen">
      <Modal
        className="content-screen__add-content-modal"
        visible={showModal}
        closable={false}
        maskClosable={false}
        centered
        onCancel={closeModalHandler}
        title={
          <h3 className="content-screen__add-content-modal-title">
            {translate('Content')}
          </h3>
        }
        footer={false}
      >
        <ContentForm
          authStore={AuthStore}
          contentStore={ContentStore}
          benchStore={BenchStore}
          closeModalHandler={closeModalHandler}
        />
      </Modal>
      {profile && profile.active === true && (
        <div className="content-form__add-content-button-container">
          <Button
            btnStyle={{ float: 'right' }}
            className="content-form__add-content-button"
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            btnText={<span>{translate('ADD CONTENT')}</span>}
            onClick={openModalHandler}
          />
        </div>
      )}
      {profile && profile.active === false && (
        <div className="content-form__add-content-button-container">
          <Tooltip
            color="#005450"
            title={translate(
              'You are currently not allowed to add any content. Please, contact administrator for more deatils'
            )}
          >
            <InfoCircleOutlined className="hint-icon" />
          </Tooltip>
          <Button
            btnStyle={{ float: 'right' }}
            className="content-form__add-content-button"
            disabled={true}
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            btnText={<span>{translate('ADD CONTENT')}</span>}
            onClick={openModalHandler}
          />
        </div>
      )}
      <Content store={ContentStore} />
    </div>
  );
};

export default ContentScreen;
