import { runInAction, action, observable, decorate } from 'mobx';

class LocalesStore {
  language;

  loadLanguage = () => {
    try {
      const locale = localStorage.getItem('language') || 'english';
      if (locale) {
        runInAction(() => {
          this.language = locale;
          this.setLanguage(locale);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  setLanguage = (language) => {
     localStorage.setItem('language', language);
  };
}

decorate(LocalesStore, {
  language: observable,
  setLanguage: action,
});

export default new LocalesStore();
