import React, { useState } from "react";
import translate from "../../../../i18n/translate";
import { Form, Input, notification } from "antd";
import Button from "../../../ui-elements/Button/button.component";
import authStore from "../../../../stores/auth/authStore";
import './delete-bench.scss';
import { useIntl } from "react-intl";
import benchStore from "../../../../stores/bench/benchStore";


const DeleteBench = ({bench, onClose, onFinish}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();

    const onDeleteSubmit = async (values) => {
        setIsLoading(true);
        try {
            // check if the password is correct, and then perform the call
            await authStore.checkPassword(values.password);
            await benchStore.deleteBench(bench.id);

            await notification.success({
                message: formatMessage({ id: 'Success!' }),
                description: formatMessage({ id: 'Bench successfully deleted' }),
            });
            // close the dialog perform finish action
            setIsLoading(false);
            onClose();
            await onFinish();
        } catch (err) {
            const translatedError = formatMessage({id: err});
            form.setFields([
                {
                    name: 'password',
                    errors: [`${translatedError}!`]
                }
            ]);
            setIsLoading(false);
        }
    }

    return (
        <div className="delete-bench-container">
            <p>{translate('Type your password in order to delete this bench.')}</p>

            <Form
                className="delete-bench-form"
                layout="vertical"
                form={form}
                onFinish={onDeleteSubmit}
            >
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: formatMessage({id: 'Please enter your password!'}),
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <div className="delete-bench-form-footer">
                        <Button loading={isLoading} btnText={translate('Delete')} className="delete-bench-submit-btn"
                                danger type="primary" htmlType="submit"/>
                        <Button btnText={translate('Cancel')} className="ant-btn" type="primary" htmlType="button"
                                onClick={onClose}/>
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}

export default DeleteBench;
