import React, {useRef, useState} from "react";
import { IMAGE_TYPE } from "./mediaTypes";
import './media.scss';


const Media = ({ source, alt = undefined, type = IMAGE_TYPE }) => {
    const videoRef = useRef(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const handleVideoPress = () => {
        if (isVideoPlaying) {
            videoRef.current.pause();
            setIsVideoPlaying(false);
        } else {
            videoRef.current.play();
            setIsVideoPlaying(true);
        }
    };

    return (
        <div className="media-container">
            {type === IMAGE_TYPE ? (
                <img src={source} alt={alt} />
            ) : (
                <video
                    loop
                    onClick={handleVideoPress}
                    ref={videoRef}
                    src={source}
                />
            )}
        </div>
    );
}

export default Media;