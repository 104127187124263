import React from 'react';
import { Row, Col } from 'antd';
import If from '../../../../../utils/If/If';
import translate from '../../../../../i18n/translate';

import Button from '../../../../ui-elements/Button/button.component';
import './clients-form-footer.style.scss';

const ClientsFormFooter = (props) => {
  return (
    <div className="clients-form__modal-footer">
      <Row align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xl={6} lg={6} md={5} sm={7} xs={12}>
          <If condition={!props.editing}>
            <Button
              size="large"
              block
              type="primary"
              htmlType="submit"
              //   btnText="Add Client"
              btnText={translate('Add Client')}
            />
          </If>
          <If condition={props.editing}>
            <Button
              size="large"
              block
              type="primary"
              htmlType="submit"
              btnText={translate('Save')}
            />
          </If>
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={0}>
          <span
            onClick={async () => {
              if (props.editing) {
                await props.finishEditing();
              }
              await props.resetFields();
              await props.closeModalHandler();
            }}
            className="clients-form__modal-footer-cancel"
          >
            {translate('Cancel')}
          </span>
        </Col>
        <Col xl={4} lg={4} md={4} sm={2} xs={0}></Col>
        <Col xl={3} lg={3} md={4} sm={2} xs={0}></Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={0}></Col>
        <Col xl={5} lg={5} md={5} sm={6} xs={10}>
          <span
            className="clients-form__modal-footer-clear-fields"
            onClick={props.resetFields}
          >
            {translate('Clear All Fields')}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default ClientsFormFooter;
