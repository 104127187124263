import React, { useState, useEffect } from 'react';
import { Layout, Menu, Divider, Dropdown, Modal as AntModal } from 'antd';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Auth from '../../../utils/auth';
import translate from '../../../i18n/translate';
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Avatar from '../../ui-elements/Avatar/avatar.component';
import SearchBox from '../../ui-elements/SearchBox/search-box.component';
import Notifications from '../../containers/Notifications/notifications.component';
import LanguagePicker from '../../containers/LanguagePicker/language-picker.component';
import './header.style.scss';
import {USERS_SCREEN_NAME} from "../../../stores/users/availableScreens";

const { Header } = Layout;

const NavHeader = (props) => {
  const [profile, setProfile] = useState({});
  const [changedState, setChangedState] = useState(false);
  const history = useHistory();
  let location = useLocation();

  useEffect(() => {
    props.authStore
      .getProfile()
      .then(() => setProfile(props.authStore.logedUser));
  }, [props.authStore, changedState]);

  const logOut = async () => {
    Auth.removeTokens();
    localStorage.clear();
    return history.push('/');
  };

  const onSearchSubmit = async (value = '') => {
    switch (location.pathname) {
      case '/benches':
        await props.benchStore.searchBench(value);
        break;
      case '/users':
        if(props.usersStore.currentActiveScreen === USERS_SCREEN_NAME) {
          await props.usersStore.searchUser(value);
        } else {
          await props.usersStore.searchAllBranches(value);
        }
        break;
      case '/content':
        await props.contentStore.searchContent(value);
        break;
      default:
        return;
    }
  }

  const clearNotifications = async () => {
    await props.usersStore.deleteNotifications();
    await setChangedState(!changedState);
  };

  const markAllAsRead = async () => {
    const notificationsData = {
      notifications: profile.notifications.map((notification) => {
        return {
          id: notification.id,
          read: true,
        };
      }),
    };

    await props.usersStore.markNotificationsAsRead(
      profile.id,
      notificationsData
    );
    await setChangedState(!changedState);
  };

  const confirm = (id) => {
    AntModal.confirm({
      title: 'Are you sure you want to clear all notifications?',
      className: 'users-list__deletion-confirmation-modal',
      icon: <ExclamationCircleOutlined />,
      okText: <span>Confirm</span>,
      cancelText: <span>Cancel</span>,
      maskClosable: true,
      centered: true,
      onOk: clearNotifications,
    });
  };

  const userActions = (
    <Menu className="notifications__more">
      <Menu.Item key="1">
        <Link to="reset_password">{translate('Change password')}</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Divider className="notifications__more-divider" />
      </Menu.Item>
      <Menu.Item key="3" onClick={logOut}>
        <div>{translate('Logout')}</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      <div className="header__logo-container">
        <img src={require('../../../assets/images/logo.png')} alt="" />
      </div>
      <div className="header__content-container">
        <div className="header__search-box-container">
          {location.pathname !== '/home' && (
            <SearchBox
              onSubmit={onSearchSubmit}
            />
          )}
        </div>
        <div className="header__user-info-container">
          <LanguagePicker store={props.localesStore} />
          <div className="header__notifications-container">
            {profile && profile.role !== 'superAdmin' && (
              <Notifications
                confirm={confirm}
                markAllAsRead={markAllAsRead}
                store={props.authStore}
                profile={profile}
              />
            )}
          </div>
          <div className="header__user-info-text">
            <div className="header__user-info-text-name">{profile.name}</div>
            <div className="header__user-info-text-role">
              {props.authStore.logedUser.role === 'superAdmin'
                ? translate('Super Admin')
                : props.authStore.logedUser.role === 'client'
                ? translate('Client')
                : null}
            </div>
          </div>
          <Avatar
            src={null}
            className="header__user-avatar"
            withBadge={false}
            size="large"
            icon={<UserOutlined />}
            shape="round"
          />
          <Dropdown.Button
            style={{ opacity: '0' }}
            trigger="click"
            overlay={userActions}
          />
        </div>
      </div>
    </Header>
  );
};

NavHeader.defaultProps = {
  avatar: '',
  firstName: 'Noel',
  lastName: 'Johnston',
  experience: 'Super Admin',
};

NavHeader.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  experience: PropTypes.string.isRequired,
};

export default NavHeader;
