import React, { useState, useEffect } from 'react';
import {
  PlusOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Tabs, Tooltip } from 'antd';
import UsersStore from '../stores/users/usersStore';
import BenchStore from '../stores/bench/benchStore';
import If from '../utils/If/If';
import translate from '../i18n/translate';
import AuthStore from '../stores/auth/authStore';

import Button from '../components/ui-elements/Button/button.component';
import Modal from '../components/ui-elements/Modal/modal.component';
import Spinner from '../components/ui-elements/Spinner/spinner.component';
import ClientsForm from '../components/containers/UsersList/ClientsForm/clients-form.component';
import UsersList from '../components/containers/UsersList/users-list.component';
import BranchForm from '../components/containers/Branch/BranchForm/branch-form.component';
import BranchList from '../components/containers/Branch/branch-list.component';
import '../assets/styles/users.scss';
import {BRANCHES_SCREEN_NAME, USERS_SCREEN_NAME} from "../stores/users/availableScreens";
import usersStore from "../stores/users/usersStore";

const { TabPane } = Tabs;

const UsersScreen = (props) => {
  const [showClientModal, setShowClientModal] = useState(false);
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [clientList, setClientList] = useState(1);
  const [profile, setProfile] = useState({});

  useState(() => {
    window.scrollTo(0, 0);
    AuthStore.getProfile().then(() => setProfile(AuthStore.logedUser));
  }, [AuthStore]);

  const openClientModalHandler = () => setShowClientModal(true);

  const closeClientModalHandler = () => setShowClientModal(false);

  const openBranchModalHandler = () => setShowBranchModal(true);

  const closeBranchModalHandler = () => setShowBranchModal(false);

  const navigateToTab = (tabKey) => {
      setClientList(tabKey);
      const screenName = tabKey === 1 ? USERS_SCREEN_NAME : BRANCHES_SCREEN_NAME;
      usersStore.setCurrentActiveScreen(screenName);
  }

  return (
    <React.Fragment>
      <Modal
        className="clients-form__modal"
        visible={showClientModal}
        onCancel={closeClientModalHandler}
        closable={false}
        maskClosable={false}
        centered
        title={
          <h3 className="clients-form__modal-title">
            {translate('Add Client')}
          </h3>
        }
        footer={false}
      >
        <div className="clients-form__tabs">
          <ClientsForm
            closeModalHandler={closeClientModalHandler}
            store={UsersStore}
          />
        </div>
      </Modal>
      <Modal
        className="branch-form__modal"
        visible={showBranchModal}
        onCancel={closeBranchModalHandler}
        closable={false}
        maskClosable={false}
        centered
        title={
          <h3 className="branch-form__modal-title">
            {translate('Add Branch')}
          </h3>
        }
        footer={false}
      >
        <BranchForm
          store={UsersStore}
          closeModalHandler={closeBranchModalHandler}
        />
      </Modal>
      {profile.role && (
        <div>
          <If condition={profile.role === 'superAdmin' && clientList === 1}>
            <div className="clients-form__add-user-button-container">
              <Tooltip
                color="#005450"
                title={translate(
                  'If you want to add or remove branch for a certain client, find wanted client in clents list, go to options button and select "Add/Remove Branch" in drop down menu.'
                )}
              >
                <InfoCircleOutlined className="hint-icon" />
              </Tooltip>
              <Button
                btnStyle={{ float: 'right' }}
                className="clients-form__add-user-button"
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                btnText={<span>{translate('ADD CLIENT')}</span>}
                onClick={openClientModalHandler}
              />
            </div>
          </If>
          <If condition={profile.role === 'superAdmin' && clientList === 2}>
            <div className="clients-form__add-user-button-container">
              <Tooltip
                color="#005450"
                title={translate(
                  'If you want to add or remove a bench for a certain branch, find wanted branch in clents list, go to options button and select "Add/Remove Bench" in drop down menu.'
                )}
              >
                <InfoCircleOutlined className="hint-icon" />
              </Tooltip>
              <Button
                btnStyle={{ float: 'right' }}
                className="clients-form__add-user-button"
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                btnText={<span>{translate('ADD BRANCH')}</span>}
                onClick={openBranchModalHandler}
              />
            </div>
          </If>
        </div>
      )}

      {profile.role ? (
        <Tabs
          defaultActiveKey="1"
          className="users-list__tabs"
          animated
          onChange={(key) => navigateToTab(parseInt(key))}
        >
          {profile.role === 'superAdmin' && (
            <TabPane tab={<h3>{translate('CLIENTS')}</h3>} key="1">
              <UsersList store={UsersStore} />
            </TabPane>
          )}

          <TabPane tab={<h3>{translate('BRANCHES')}</h3>} key="2">
            <BranchList
              authStore={AuthStore}
              userStore={UsersStore}
              benchStore={BenchStore}
            />
          </TabPane>
        </Tabs>
      ) : (
        <div className="users-screen__spinner-container">
          <Spinner
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
            size="large"
            className="loader"
            tip={translate('Loading...')}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default UsersScreen;
