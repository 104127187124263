import * as React from "react";

function SvgUploadIcon(props) {
  return (
    <svg width={30} height={40} fill="none" {...props}>
      <path
        d="M25.267 0H10.53c-.03 0-.06 0-.09.007-.016 0-.046.008-.07.008h-.03c-.03.007-.06.007-.075.014h-.008c-.023.007-.053.015-.076.03h-.007c-.03.007-.053.021-.076.036-.008 0-.008.007-.008.007a.31.31 0 01-.068.036c-.008 0-.008.008-.015.008-.015.007-.038.029-.053.036L9.93.197a.532.532 0 01-.068.05l-.008.008L.206 9.53c0 .008-.008.008-.008.015a.222.222 0 00-.038.05c0 .008-.008.008-.008.015-.015.022-.03.044-.038.066 0 .007-.007.007-.007.007a.335.335 0 00-.038.073v.007c-.008.022-.015.051-.03.073v.007a.25.25 0 00-.016.073v.03c0 .014-.007.043-.007.065 0 .029-.008.065-.008.087v25.344c0 2.512 2.117 4.543 4.734 4.543h20.533c2.617 0 4.733-2.038 4.733-4.543V4.543C30.001 2.04 27.884 0 25.268 0zM9.59 3.102v3.363c-.008 1.507-1.275 2.723-2.845 2.73H3.24l6.349-6.093zM28.112 35.45c-.008 1.507-1.274 2.723-2.845 2.73H4.734c-1.563-.007-2.837-1.223-2.837-2.73V11.008h4.84c2.616 0 4.733-2.038 4.733-4.543V1.82h13.79c1.57.007 2.837 1.223 2.844 2.73v30.907h.008v-.007z"
        fill="#005450"
      />
      <path
        d="M21.648 19.556l-6.045-4.864c-.008-.007-.015-.022-.03-.022-.008-.007-.03-.014-.038-.029-.008-.007-.015-.007-.03-.021-.008-.008-.031-.015-.039-.03-.007-.007-.03-.007-.038-.021-.007-.008-.03-.008-.038-.015-.007-.007-.03-.007-.037-.015-.016-.007-.03-.007-.046-.014-.008 0-.03-.008-.038-.008-.015 0-.03-.007-.045-.007-.008 0-.03-.007-.038-.007-.015 0-.038-.007-.061-.007h-.03a.76.76 0 00-.182 0h-.03c-.016 0-.039.007-.054.007-.007 0-.03.007-.038.007-.015 0-.03.008-.045.008-.008 0-.03.007-.038.007-.015.007-.03.007-.046.014-.007.008-.03.008-.038.015-.022.007-.03.007-.038.014-.007.008-.03.008-.037.022-.016.008-.03.015-.038.03-.008.007-.016.007-.03.021-.016.008-.031.015-.039.03-.007.007-.015.007-.03.021l-6.046 4.864a.88.88 0 00-.113 1.281c.333.386.933.43 1.335.11l4.49-3.612v13.287c0 .503.425.91.948.91.524 0 .949-.407.949-.91V17.335l4.49 3.611a.977.977 0 001.335-.116.905.905 0 00-.152-1.274z"
        fill="#005450"
      />
    </svg>
  );
}

export default SvgUploadIcon;
