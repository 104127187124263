import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col, Tooltip, Checkbox } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import translate from '../../../../i18n/translate';

import ClientFormFooter from './ClientsFormFooter/clients-form-footer.component';
import './clients-form.style.scss';

const { Option } = Select;

const ClientsForm = (props) => {
  const [form] = Form.useForm();
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const getSelectedLanguage = async () => {
    const selected = await localStorage.getItem('language');
    setSelectedLanguage(selected);
  };

  useEffect(() => {
    if (props.editing) {
      props.store
        .getUser(props.userId)
        .then(() => form.setFieldsValue(props.store.user));
    }

    return () => {
      form.resetFields();
    };
  }, [form, props.editing, props.store, props.userId]);

  useEffect(() => {
    getSelectedLanguage();
  }, [localStorage.language]);

  const initialValues = {
    name: '',
    email: '',
    phone: '+387',
    jib: '',
    pib: '',
    responsiblePerson: '',
    responsiblePersonMail: '',
    address: '',
    note: '',
    newsLetter: true,
    city: '',
    state: '',
  };

  const initialValuesEditing = {
    name: props.store.user.name,
    email: props.store.user.email,
    phone: props.store.user.phone,
    jib: props.store.user.jib,
    pib: props.store.user.pib,
    responsiblePerson: props.store.user.responsiblePerson,
    responsiblePersonMail: props.store.user.responsiblePersonMail,
    address: props.store.user.address,
    note: props.store.user.note,
    newsLetter: props.store.user.newsLetter,
    city: props.store.user.city,
    state: props.store.user.state,
  };

  const addUser = async (values) => {
    let user = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      jib: values.jib,
      pib: values.pib,
      city: values.city,
      state: values.state,
      responsiblePerson: values.responsiblePerson,
      newsLetter: values.newsLetter,
      note: values.note,
      address: values.address,
    };
    await props.store.addUser(user);
    await props.store.loadUsers();
    form.resetFields();
    props.closeModalHandler();
  };

  const updateUser = async (values) => {
    let user = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      jib: values.jib,
      pib: values.pib,
      city: values.city,
      state: values.state,
      responsiblePerson: values.responsiblePerson,
      newsLetter: values.newsLetter,
      note: values.note,
      address: values.address,
    };
    await props.store.updateUser(props.store.user.id, user);
    await props.store.loadUsers();
    props.closeModalHandler();
    form.resetFields();
    props.finishEditing();
  };

  return (
    <Form
      form={form}
      initialValues={props.editing ? initialValuesEditing : initialValues}
      className="clients-form"
      onFinish={props.editing ? updateUser : addUser}
    >
      <Input.Group>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              name="name"
              label={translate('Name')}
              rules={[
                {
                  required: true,
                  message: translate('Please enter your full name!'),
                  min: 1,
                  max: 99,
                },
              ]}
            >
              <Input
                placeholder={
                  selectedLanguage === 'english'
                    ? 'Enter your name here'
                    : 'Unesite svoje ime'
                }
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              name="pib"
              label={translate('PIB')}
              rules={[
                {
                  required: true,
                  message: translate('Please enter your PIB!'),
                  min: 1,
                  max: 99,
                },
              ]}
            >
              <Input
                placeholder={
                  selectedLanguage === 'english'
                    ? 'Enter your PIB here'
                    : 'Unesti vaš PIB'
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Input.Group>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              name="address"
              label={translate('Address')}
              rules={[
                {
                  required: true,
                  message: translate('Please enter your address!'),
                  min: 2,
                  max: 99,
                },
              ]}
            >
              <Input
                placeholder={
                  selectedLanguage === 'english'
                    ? 'Enter your address here'
                    : 'Unesite vašu adresu'
                }
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row clients-form__rp-container"
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={24}
          >
            <Form.Item
              name="responsiblePerson"
              label={
                <div className="clients-form__rp-label">
                  <span>{translate('Name and surname (RP)')}</span>
                  <Tooltip color="#005450" title={translate('Name of the RP.')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: translate('Please enter RP full name here!'),
                  min: 1,
                  max: 99,
                },
              ]}
            >
              <Input
                placeholder={
                  selectedLanguage === 'english'
                    ? 'Enter name and surname of RP here'
                    : 'Unesite ime odgovorne osobe'
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Input.Group>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xl={12} lg={12} sm={12} xs={24}>
            <Input.Group>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                justify="space-between"
              >
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    name="city"
                    label={translate('City')}
                    rules={[
                      {
                        required: true,
                        message: translate('Please enter your city!'),
                        min: 1,
                        max: 99,
                      },
                    ]}
                  >
                    <Input
                      placeholder={
                        selectedLanguage === 'english'
                          ? 'Enter your city here'
                          : 'Unesite grad'
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    name="state"
                    label={translate('State')}
                    rules={[
                      {
                        required: true,
                        message: translate('Please enter your state!'),
                        min: 1,
                        max: 99,
                      },
                    ]}
                  >
                    <Input
                      placeholder={
                        selectedLanguage === 'english'
                          ? 'Enter your state here'
                          : 'Unesite državu'
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item label={translate('Phone')} rules={[{ required: true }]}>
              <Input.Group className="clients-form__input-group" compact>
                <Form.Item name={['BA', 'SRB']} noStyle>
                  <Select
                    defaultValue="BA"
                    className="clients-form__phone-select"
                    onChange={(value) => {
                      if (value === 'BA') {
                        form.setFieldsValue({
                          phone: '+387',
                        });
                      } else {
                        form.setFieldsValue({
                          phone: '+381',
                        });
                      }
                    }}
                  >
                    <Option value="BA">BA</Option>
                    <Option value="SRB">SRB</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="phone"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: translate('Phone number is required'),
                      min: 4,
                      max: 99,
                    },
                  ]}
                >
                  <Input
                    placeholder={
                      selectedLanguage === 'english'
                        ? 'Phone number'
                        : 'Broj telefona'
                    }
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              name="responsiblePersonMail"
              label={
                <div className="clients-form__rp-label">
                  <span>{translate('E-mail (RP)')}</span>
                  <Tooltip color="#005450" title={translate('Email of the RP.')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              }
              rules={[{ type: "email" }]}
            >
              <Input disabled placeholder="Enter your RP mail here" />
            </Form.Item>
          </Col> */}
        </Row>
      </Input.Group>
      <Input.Group>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {/* <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item name="loginMail" label={translate('Login Email')}>
              <Input disabled placeholder="Enter your login mail here" />
            </Form.Item>
          </Col> */}
        </Row>
      </Input.Group>

      <Input.Group>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
            <Input.Group>
              <Form.Item
                name="email"
                label={translate('E-mail')}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: translate('Please enter your email!'),
                  },
                ]}
              >
                <Input
                  placeholder={
                    selectedLanguage === 'english'
                      ? 'Enter your E-mail here'
                      : 'Vaš E-mail'
                  }
                />
              </Form.Item>
              <Form.Item
                name="jib"
                label={translate('JIB')}
                rules={[
                  {
                    required: true,
                    message: translate(
                      'Please enter your JIB! At least 13 characters is required'
                    ),
                    min: 13,
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder={
                    selectedLanguage === 'english'
                      ? 'Enter your JIB here'
                      : 'Unesite vaš JIB'
                  }
                />
              </Form.Item>
            </Input.Group>
          </Col>
          <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item name="note" label={translate('Notes')}>
              <Input.TextArea
                placeholder={
                  selectedLanguage === 'english'
                    ? 'Enter your note (optional)'
                    : 'Upišite bilješke (opcionalno)'
                }
                rows={3}
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Form.Item>
        <div className="ant-modal-footer" style={{ padding: 0 }}>
          <ClientFormFooter
            resetFields={() => {
              Object.assign(initialValuesEditing, initialValues);
              form.resetFields();
            }}
            editing={props.editing}
            closeModalHandler={() => {
              props.closeModalHandler();
              setTimeout(() => form.resetFields(), 500);
            }}
            store={props.store}
            finishEditing={props.finishEditing}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default ClientsForm;
