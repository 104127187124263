import React from 'react';
import { useIntl } from 'react-intl';

import './search-box.style.scss';

const SearchBox = ({ onSubmit }) => {
  const { formatMessage } = useIntl();

  const searchHandler = async (e) => {
    // if the "enter" key is pressed, call the onSubmit prop function
    if(e.key === 'Enter') {
      onSubmit(e.target.value);
    }
  };

  return (
    <div className="search__container">
      <input
        className="search__input"
        type="text"
        placeholder={formatMessage({ id: 'Search' })}
        onKeyDown={searchHandler}
      />
    </div>
  );
};

export default SearchBox;
