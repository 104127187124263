import React from 'react';
import AuthStore from '../stores/auth/authStore';

import Login from '../components/containers/Login/login.component';

const LoginScreen = props => {
    return (
        <React.Fragment>
            <Login store={AuthStore} />
        </React.Fragment>
    );
};

export default LoginScreen;