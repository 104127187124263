import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Select, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import translate from '../../../../i18n/translate';
import ContentStore from '../../../../stores/content/contentStore';
import { inject, observer } from 'mobx-react';

import Spinner from '../../../ui-elements/Spinner/spinner.component';
import RequestFormFooter from './RequestFormFooter/request-form-footer.component';
import './request-form.style.scss';

const { Option } = Select;

const RequestForm = (props) => {
  const [form] = Form.useForm();
  const [disabledButton, setDisabledButton] = useState(true);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const videoRef = useRef(null);

  useEffect(() => {
    ContentStore.getContentForRequest(props.req.key).then(() => {
      if (ContentStore.requestContent) {
        if (props.req.type === 'image') {
          setImage(ContentStore.requestContent);
        } else {
          setVideo(ContentStore.requestContent);
        }
      }
    });
  }, [props.req]);

  const handleVideoPress = () => {
    if (playing) {
      // if video is playing, stop it
      videoRef.current.pause();
      setPlaying(false);
    } else {
      // if its not playing, play it
      videoRef.current.play();
      setPlaying(true);
    }
  };

  const approveDeclineContent = async (values) => {
    setLoading(true);
    const statusData = {
      status: values.status,
      comment: values.comment ? values.comment : '',
    };
    await ContentStore.updateContent(props.req.id, statusData);
    await props.store.getProfile();
    setLoading(false);
    props.closeModalHandler();
    form.resetFields();
    setDisabledButton(true);
    setShowComment(false);
    setImage(null);
    setVideo(null);
  };

  return (
    <Form form={form} onFinish={approveDeclineContent} className="request-form">
      {!props.req && (
        <div
          style={{ width: '100%' }}
          className="users-list__spinner-container"
        >
          <Spinner
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
            size="large"
            tip={translate('Loading...')}
          />
        </div>
      )}
      {props.req && (
        <div>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" xl={18} lg={18} md={17} sm={17} xs={16}>
              <h3 className="request-form__content-title">{props.req.name}</h3>
            </Col>
            <Col className="gutter-row" xl={6} lg={6} md={7} sm={7} xs={8}>
              <Form.Item name="status">
                <Select
                  placeholder={translate('Pending')}
                  className="clients-form__type-select"
                  onChange={(value) => {
                    setDisabledButton(false);
                    if (value === 'decline') {
                      setShowComment(true);
                    } else {
                      setShowComment(false);
                    }
                  }}
                >
                  <Option value="active">{translate('Approved')}</Option>
                  <Option value="decline">{translate('Declined')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <div className="request-form__preview-file-container">
              {image && <img src={image ? image : ''} alt={props.req.name} />}
              {video && (
                <video
                  loop
                  onClick={handleVideoPress}
                  ref={videoRef}
                  src={video ? video : ''}
                />
              )}
            </div>
          </Row>
          {showComment && (
            <Form.Item name="comment" label={translate('Comment')}>
              <Input.TextArea placeholder="Enter your comment" rows={3} />
            </Form.Item>
          )}
        </div>
      )}
      <div className="ant-modal-footer" style={{ padding: 0 }}>
        <RequestFormFooter
          closeModalHandler={() => {
            setDisabledButton(true);
            setVideo(null);
            setImage(null);
            setShowComment(false);

            props.closeModalHandler();
            form.resetFields();
          }}
          disabled={disabledButton}
          loading={loading}
        />
      </div>
    </Form>
  );
};

export default inject('ContentStore')(observer(RequestForm));
