import React, { useEffect } from "react";
import AuthStore from "../stores/auth/authStore";

import ResetPassword from "../components/containers/ResetPassword/reset-password.component";

const ResetPasswordScreen = (props) => {
  useEffect(() => window.scrollTo(0, 0), []);
  
  return (
    <React.Fragment>
      <ResetPassword store={AuthStore} />
    </React.Fragment>
  );
};

export default ResetPasswordScreen;
