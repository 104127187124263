import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import translate from '../../../i18n/translate';
import BenchStore from '../../../stores/bench/benchStore';
import AuthStore from '../../../stores/auth/authStore';
import Card from '../../ui-elements/Card/card.component';
import Modal from '../../ui-elements/Modal/modal.component';
import Spinner from '../../ui-elements/Spinner/spinner.component';
import Button from '../../ui-elements/Button/button.component';
import DashboardCardList from './DashboardCardList/dashboard-card-list.component';
import Map from '../Map/map.component';
import RequestForm from '../../containers/Dashboard/RequestForm/request-form.component';
import './dashboard.style.scss';
import userRoles from '../../../utils/user-roles';
import { useIntl } from "react-intl";

const Dashboard = () => {
  const [benches, setBenches] = useState(null);
  const [requests, setRequests] = useState(null);
  const [alarms, setAlarms] = useState(null);
  const [history, setHistory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [req, setReq] = useState(null);
  const [showAdWarn, setShowAdWarn] = useState(false);
  const { formatMessage, locale } = useIntl();

  useEffect(() => {
    BenchStore.loadBenches().then(() => {
      if (BenchStore.benches) {
        setBenches(BenchStore.benches);
      }
    });

    AuthStore.getProfile().then(() => {
      if (AuthStore.logedUser && AuthStore.logedUser.requests) {
        setRequests(AuthStore.logedUser.requests);
      }

      if (AuthStore.logedUser && AuthStore.logedUser.__history__) {
        const _history = [];

        AuthStore.logedUser.__history__.map((activity) => {
          var d = new Date(activity.timestamp);
          _history.push({
            timestamp: d.toLocaleString('en-GB'),
            name: activity.user.name,
          });
        });

        setHistory(_history);
      }
    });
  }, []);

  useEffect(() => {
    const fetchAlarms = async () => {
      const initialAlarms = await BenchStore.getAlarms() || [];

      const alarms = initialAlarms.map((alarm) => {
        const text = formAlarmText(alarm);
        return {
          ...alarm,
          text
        };
      });
      setAlarms(alarms);
    }
    fetchAlarms();
  }, [locale]);

  useEffect(() => {
    if (AuthStore.logedUser && AuthStore.logedUser.requests) {
      setRequests(AuthStore.logedUser.requests);
    }
  }, []);

  useEffect(() => {
    setShowAdWarn(localStorage.getItem('show-adblock-warning') === 'show');
  }, []);

  const translateError = (type) => {
    const translatableErrors = ['benchDeactivatedDueToLackOfData'];
    if(translatableErrors.includes(type)) {
      return formatMessage({ id: type });
    }
    return type;
  }

  const formAlarmText = (alarm) => {
    const alarmTimestamp = new Date(alarm.timestamp);
    let message;
    if(alarm?.message && (alarm?.message === 'sensorFailure' && alarm?.payload?.sensorFailureType)) {
      message = `${alarm.message}, ${formatMessage({ id: 'Sensor type' })}: 
      ${formatMessage({ id: alarm?.payload?.sensorFailureType })}`;
    } else {
      message = translateError(alarm.message);
    }

    return `${formatMessage({ id: 'Bench' })} ${alarm.givenId}, 
      ${message}, ${alarmTimestamp.toLocaleString('en-GB')}`;
  }

  const closeModalHandler = () => {
    setShowModal(false);
  };

  const openModalHandler = async (item) => {
    await setReq(item);
    setShowModal(true);
  };

  const reloadPage = async () => {
    await localStorage.setItem('show-adblock-warning', 'hide');
    await window.location.reload();
  };

  const closeAdblockerModal = async () => {
    setShowAdWarn(false);
    await localStorage.setItem('show-adblock-warning', 'hide');
  };

  return (
    <div className="dashboard-container">
      {!AuthStore.logedUser.role && (
        <div className="dashboard__spinner-container">
          <Spinner
            className="loader"
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
            size="large"
            tip={translate('Loading...')}
          />
        </div>
      )}
      <Modal
        className="dashboard__reload-page-modal"
        visible={showAdWarn}
        centered
        maskClosable={false}
        closable={false}
        onCancel={closeAdblockerModal}
        footer={false}
      >
        <div className="dashboard__reload-page-modal-content">
          <h1 className="dashboard__reload-page-modal-title">
            {translate('Warning!')}
          </h1>
          <p>
            {translate(
              'If you have your Ad Blocker active or any similar extension, please disable it and click on "Reload" button to refresh the page.'
            )}
          </p>
          <Button
            type="primary"
            size="large"
            btnText={translate('Reload')}
            block
            onClick={reloadPage}
          />
          <div className="dashboard__reload-page-modal-close">
            <span onClick={closeAdblockerModal}>{translate('Close')}</span>
          </div>
        </div>
      </Modal>
      <Modal
        className="dashboard__add-content-modal"
        visible={showModal}
        closable={false}
        maskClosable={false}
        centered
        onCancel={closeModalHandler}
        destroyOnClose={true}
        title={
          <h3 className="dashboard__add-content-modal-title">
            {translate('Content request')}
          </h3>
        }
        footer={false}
      >
        <RequestForm
          store={AuthStore}
          req={req}
          closeModalHandler={closeModalHandler}
        />
      </Modal>
      {AuthStore.logedUser && AuthStore.logedUser.role === userRoles.SUPER_ADMIN && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            xl={8}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className="dashboard-container__alarms-card-container"
          >
            <Card
              className="dashboard-container__alarms-card"
              headStyle={{ borderBottom: 0 }}
            >
              <h1>{translate('Alarms')}</h1>
              <DashboardCardList type="alarms" listItems={alarms} />
            </Card>
          </Col>
          <Col
            xl={8}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className="dashboard-container__requests-card-container"
          >
            <Card
              className="dashboard-container__requests-card"
              headStyle={{ borderBottom: 0 }}
            >
              <h1>{translate('Requests')}</h1>
              <DashboardCardList
                type="requests"
                listItems={requests}
                openModalHandler={openModalHandler}
              />
            </Card>
          </Col>
          <Col
            xl={8}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className="dashboard-container__history-card-container"
          >
            <Card
              className="dashboard-container__history-card"
              headStyle={{ borderBottom: 0 }}
            >
              <h1>{translate('History')}</h1>
              <DashboardCardList type="history" listItems={history} />
            </Card>
          </Col>
        </Row>
      )}
      {AuthStore.logedUser.role && (
        <Row className="dashboard-container__map-card-container">
          <Col span={24}>
            <Map benches={benches} height="600px" />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Dashboard;
