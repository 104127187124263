import React, { useState } from "react";
import { Row, Col, Select, Form, Tooltip } from "antd";
import jsPDF from "jspdf";
import translate from '../../../../../i18n/translate';

import Button from "../../../../ui-elements/Button/button.component";
import "./download-reports-form-footer.style.scss";

const { Option } = Select;

const DownloadReportsFormFooter = (props) => {
  const [type, setType] = useState(undefined);
  const csvDataTest = [
    {
      BenchID: "1123",
    },
    {
      BenchID: "1124",
    },
  ];

  const generatePdfHandler = () => {
    const doc = jsPDF("p", "pt");

    doc.text(20, 20, "This is a test PDF file.");

    doc.setFont("courier");
    doc.setFontType("normal");

    doc.save("test-report.pdf");
  };

  const generateCsvFile = () => {
    let csvRow = [];
    let A = [["id", "BenchID"]];
    let re = csvDataTest;

    for (let i = 0; i < re.length; i++) {
      A.push([i, re[i].BenchID]);
    }

    for (let i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(","));
    }

    let csvString = csvRow.join("%0A");

    let a = document.createElement("a");
    a.href = `data:attachment/csv,${csvString}`;
    a.target = "_blank";
    a.download = "test-report.csv";
    document.body.appendChild(a);
    a.click();

    console.warn(csvString);
  };

  const downloadFileHandler = () => {
    type === "pdf" ? generatePdfHandler() : generateCsvFile();
    props.closeModalHandler();
  };

  return (
    <div className="download-reports-form__modal-footer">
      <Row align="middle" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xl={8} lg={8} md={12} sm={12} xs={12}>
          <Button
            size="large"
            type="primary"
            btnText={
              <Tooltip color="#005450" title={translate('Download')}>
                <span>{translate('Download')}</span>
              </Tooltip>
            }
            block
            disabled={!type}
            onClick={downloadFileHandler}
          />
        </Col>
        <Col
          xl={8}
          lg={8}
          md={12}
          sm={12}
          xs={12}
          className="download-reports-form__modal-footer-cancel-container"
        >
          <Tooltip color="#005450" title={translate('Cancel')}>
            <span
              onClick={props.closeModalHandler}
              className="download-reports-form__modal-footer-cancel"
            >
              {translate('Cancel')}
            </span>
          </Tooltip>
        </Col>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <Form.Item label={translate('Download as:')}>
            <Select
              onChange={(value) => setType(value)}
              placeholder="Select type"
              className="download-reports-form__select-period"
            >
              <Option value="pdf">PDF</Option>
              <Option value="excel">Excel</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default DownloadReportsFormFooter;
