import React from 'react';
import { Menu, Divider, Dropdown } from 'antd';

import './language-picker.style.scss';

const LanguagePicker = (props) => {
  const changeLanguageHandler = async (language) => {
    await props.store.setLanguage(language);
    await props.store.loadLanguage();
  };
  const languageList = (
    <Menu className="language-picker__language-list">
      <Menu.Item key="1" onClick={() => changeLanguageHandler('english')}>
        <div className="language-picker__language-label-container">
          <span>EN</span>
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <Divider className="language-picker__language-list-divider" />
      </Menu.Item>
      <Menu.Item key="3" onClick={() => changeLanguageHandler('bosnian')}>
        <div className="language-picker__language-label-container">
          <span>BA</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const flagIcon =
    props.store.language && props.store.language === 'bosnian' ? (
      <div className="language-picker__flag-icon-container">
        <span>BA</span>
      </div>
    ) : (
      <div className="language-picker__flag-icon-container">
        <span>EN</span>
      </div>
    );
  return (
    <div className="language-picker__container">
      <Dropdown.Button
        className="language-picker__dropdown-button"
        size="large"
        icon={flagIcon}
        trigger="click"
        overlay={languageList}
      />
    </div>
  );
};

export default LanguagePicker;
