import React, { useState, useEffect } from 'react';
import {
  Popover,
  Button,
  Badge,
  Dropdown,
  Menu,
  Divider,
  Tooltip,
  Spin,
} from 'antd';
import { BellOutlined } from '@ant-design/icons';
import translations from '../../../i18n/translate';

import NotificationsList from './NotificationsList/notifications-list.component';
import './notifications.style.scss';

const Notifications = (props) => {
  const [unreadNotifications, setUnreadNotifications] = useState(null);

  useEffect(() => {
    if (props.profile.notifications) {
      const _count = props.profile.notifications
        .map((notification) => {
          return notification.read;
        })
        .filter((read) => read === false);

      setUnreadNotifications(_count.length);
    }
  }, [props.store, props.profile]);

  const notificationsActions = (
    <Menu className="notifications__more">
      <Menu.Item key="1" onClick={props.confirm}>
        <div>{translations('Clear all')}</div>
      </Menu.Item>
      <Menu.Item key="2">
        <Divider className="notifications__more-divider" />
      </Menu.Item>
      <Menu.Item key="3" onClick={props.markAllAsRead}>
        <div>{translations('Mark all as read')}</div>
      </Menu.Item>
    </Menu>
  );

  const title = (
    <div className="notifications__title">
      <span>{translations('Notifications')}</span>
      <Tooltip color="#005450" title={translations('Options')}>
        <Dropdown.Button trigger="click" overlay={notificationsActions} />
      </Tooltip>
    </div>
  );

  return (
    <div className="notifications__container">
      {props.profile.notifications ? (
        <Popover
          className="notifications__list"
          placement="bottomRight"
          title={title}
          content={() => (
            <NotificationsList
              store={props.store}
              notifications={props.profile.notifications}
              unreadNotifications={unreadNotifications}
              profile={props.profile}
            />
          )}
          trigger="click"
        >
          <Badge count={unreadNotifications}>
            <Button
              shape="circle"
              size="large"
              className="notifications__bell-button"
            >
              <BellOutlined />
            </Button>
          </Badge>
        </Popover>
      ) : (
        <Spin spinning />
      )}
    </div>
  );
};

export default Notifications;
