import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Empty,
  Tooltip,
  Dropdown,
  Menu,
  Divider,
  Modal as AntModal,
} from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import translate from '../../../i18n/translate';
import If from '../../../utils/If/If';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import Tag from '../../ui-elements/Tag/tag.component';
import Spinner from '../../ui-elements/Spinner/spinner.component';
import Card from '../../ui-elements/Card/card.component';
import Modal from '../../ui-elements/Modal/modal.component';
import EditContentForm from './EditContentForm/edit-content-form.component';
import './content.style.scss';
import ViewContent from "./ViewContent/view-content.component";

const Content = (props) => {
  const [content, setContent] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [contentName, setContentName] = useState('');
  const [startEndDate, setStartEndDate] = useState({});
  const [contentId, setContentId] = useState(null);
  const [contentKey, setContentKey] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);

  useEffect(() => {
    props.store.loadContent().then(() => setContent(props.store.contentList));
  }, []);

  useEffect(() => {
    setContent(props.store.contentList);
  }, [props.store.contentList]);

  const confirm = (id) => {
    AntModal.confirm({
      title: 'Are you sure you want to delete this content?',
      className: 'users-list__deletion-confirmation-modal',
      icon: <ExclamationCircleOutlined />,
      okText: <span>Confirm</span>,
      cancelText: <span>Cancel</span>,
      maskClosable: true,
      centered: true,
      onOk: () => {
        return Promise.resolve(props.store.removeContent(id)).then(() =>
          props.store.loadContent()
        );
      },
    });
  };

  const openEditModal = () => setShowEditModal(true);

  const closeEditModal = () => setShowEditModal(false);

  const openViewModal = () => setShowViewModal(true);

  const closeViewModal = () => setShowViewModal(false);

  const contentListItemActions = (id) => (
    <Menu className="users-list__actions">
      <Menu.Item key="1" onClick={() => confirm(id)}>
        {translate('Delete Content')}
      </Menu.Item>
      <Menu.Item key="2">
        <Divider className="users-list__actions-divider" />
      </Menu.Item>
      <Menu.Item key="3" onClick={openViewModal}>
          {translate('View Content')}
      </Menu.Item>
      <Menu.Item key="4" onClick={openEditModal}>
        {translate('Edit Content')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <If condition={content && content.length === 0}>
        <div className="users-list__spinner-container">
          <Empty description={<span>{translate('No content found.')}</span>} />
        </div>
      </If>
      <Modal
        className="edit-content-form__modal"
        visible={showEditModal}
        onCancel={closeEditModal}
        footer={false}
        closable={false}
        maskClosable={false}
        centered
        title={
          <h3 className="edit-content-form__modal-title">{contentName}</h3>
        }
      >
        <EditContentForm
          closeModalHandler={closeEditModal}
          startEndDate={startEndDate}
          store={props.store}
          contentId={contentId}
        />
      </Modal>
      <Modal
          className="edit-content-form__modal"
          visible={showViewModal}
          onCancel={closeViewModal}
          footer={false}
          closable
          maskClosable={false}
          centered
          title={
              <h3 className="edit-content-form__modal-title">{contentName}</h3>
          }
      >
          <ViewContent
              contentId={contentId}
              contentKey={contentKey}
              contentType={contentType}
              contentName={contentName}
          />
      </Modal>
      <div className="users-list__cards-container">
        {content && content.length > 0
          ? content.map((item) => {
              const startDate = new Date(item.startDate);
              const endDate = new Date(item.endDate);

              return (
                <Card className="users-list__card" key={item.id}>
                  <Row
                    className="users-list__table"
                    align="middle"
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  >
                    <Col xl={6} lg={6} md={18} sm={18} xs={16}>
                      <span className="users-list__table-cell">
                        {item.name}
                      </span>
                    </Col>
                    <Col xl={4} lg={4} md={18} sm={18} xs={16}>
                      <span className="users-list__table-cell">
                        {item.user.name}
                      </span>
                    </Col>
                    <Col xl={2} lg={2} md={18} sm={18} xs={16}>
                      <span className="users-list__table-cell" title="Benches IDs">
                          {item.benches?.map((bench, i) => (
                              <span key={i}>{bench.givenId}<br /></span>
                          ))}
                      </span>
                    </Col>
                    <Col xl={4} lg={4} md={18} sm={18} xs={16}>
                      <span className="users-list__table-cell">
                        {(startDate.getMonth() > 8
                          ? startDate.getMonth() + 1
                          : '0' + (startDate.getMonth() + 1)) +
                          '/' +
                          (startDate.getDate() > 9
                            ? startDate.getDate()
                            : '0' + startDate.getDate()) +
                          '/' +
                          startDate.getFullYear()}
                      </span>
                    </Col>
                    <Col xl={4} lg={4} md={18} sm={18} xs={16}>
                      <span className="users-list__table-cell">
                        {(endDate.getMonth() > 8
                          ? endDate.getMonth() + 1
                          : '0' + (endDate.getMonth() + 1)) +
                          '/' +
                          (endDate.getDate() > 9
                            ? endDate.getDate()
                            : '0' + endDate.getDate()) +
                          '/' +
                          endDate.getFullYear()}
                      </span>
                    </Col>
                    <Col
                      xl={2}
                      lg={2}
                      md={20}
                      sm={20}
                      xs={16}
                      className="users-list__tag-container"
                    >
                      <span className="users-list__table-cell">
                        <span
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tag
                            borderWidth={0}
                            minWidth
                            color={
                              item.status === 'active'
                                ? 'cyan'
                                : item.status === 'pending'
                                ? 'blue'
                                : 'red'
                            }
                            text={
                              item.status === 'active'
                                ? translate('Active')
                                : item.status === 'pending'
                                ? translate('Pending')
                                : item.status === 'decline'
                                ? translate('Declined')
                                : translate('Inactive')
                            }
                          />
                        </span>
                      </span>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={8}>
                      <span className="users-list__table-cell">
                        <div
                          className="users-list__actions-dropdown-button"
                          onClick={() => {
                            setContentName(item.name);
                            setContentId(item.id);
                            setContentKey(item.key);
                            setContentType(item.type);
                            setStartEndDate({
                              startDate: moment(startDate, 'DD/MM/YYYY'),
                              endDate: moment(endDate, 'DD/MM/YYYY'),
                            });
                          }}
                        >
                          <Tooltip
                            placement="topRight"
                            color="#005450"
                            title={translate('Options')}
                          >
                            <Dropdown.Button
                              trigger="click"
                              overlay={contentListItemActions(item.id)}
                            />
                          </Tooltip>
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Card>
              );
            })
          : null}
        <If condition={!content}>
          <div className="users-list__spinner-container">
            <Spinner
              indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
              size="large"
              tip={translate('Loading content...')}
            />
          </div>
        </If>
      </div>
    </div>
  );
};

export default inject('ContentStore')(observer(Content));
