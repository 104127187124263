import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import translate from '../../../i18n/translate';
import Card from '../../ui-elements/Card/card.component';
import Button from '../../ui-elements/Button/button.component';

import './landing-page-style.scss';

const LandingComponent = () => {
  return (
    <div className="landing-page-container">
      <Row justify="center">
        <Col xxl={16} xl={20} lg={20} md={22} sm={20} xs={20}>
          <Card className="landing-page__card">
            <Row style={{ height: '80vh' }}>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={0}
                xs={0}
                className="landing-page-about-section"
              ></Col>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className="landing-page-to-map-section"
              >
                <div className="login-button-wrapper">
                  <Link to="/login">
                    <Button
                      className="to-login-button"
                      size="large"
                      type="primary"
                      block
                      btnText={<span>{translate('Login')}</span>}
                      htmlType="submit"
                    />
                  </Link>
                </div>
                <div className="to-map-button-wrapper">
                  <Link to="/public-map">
                    <Button
                      className="to-map-btn"
                      size="large"
                      type="primary"
                      block
                      btnText={<span>{translate('Show benches')}</span>}
                      htmlType="submit"
                    />
                  </Link>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LandingComponent;
