import React, { useEffect, useState } from 'react';
import If from '../../../utils/If/If';
import { Modal, Menu, Divider, Dropdown, Empty, Tooltip } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import translate from '../../../i18n/translate';
import { useIntl } from 'react-intl';

import Spinner from '../../ui-elements/Spinner/spinner.component';
import Tag from '../../ui-elements/Tag/tag.component';
import Card from '../../ui-elements/Card/card.component';
import BranchForm from './BranchForm/branch-form.component';
import BranchBenchForm from './BranchBenchForm/branch-bench-form.component';
import './branch-list.style.scss';
import benchStatus from "../../../constants/benchStatus";
import {BRANCHES_SCREEN_NAME} from "../../../stores/users/availableScreens";

const BranchList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [branchId, setBranchId] = useState(undefined);
  const [editing, setEditing] = useState(false);
  const [showBenchModal, setShowBenchModal] = useState(false);
  const [branches, setBranches] = useState([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    props.userStore.loadAllBranches().then(() => {
      setBranches(props.userStore.branches);
    });
}, [props.userStore]);

  useEffect(() => {
    setBranches(props.userStore.branches);
  }, [props.userStore.branches, branches]);

  useEffect(() => {
    if (branchId) {
      props.userStore.getBranch(branchId);
    }
  }, [branchId, props.userStore]);

  useEffect(() => {
    props.userStore.setCurrentActiveScreen(BRANCHES_SCREEN_NAME);
  });

  const getBenchLabelColor = (type) => {
      switch (type) {
          case benchStatus.ACTIVE:
              return 'cyan';
          case benchStatus.PROBLEM:
              return 'gold';
          case benchStatus.INACTIVE:
              return 'red';
          default:
              return 'red';
      }
  }

  const getBenchLabelText = (type) => {
      let translateKey;
      switch (type) {
          case benchStatus.ACTIVE:
              translateKey = 'Active';
              break;
          case benchStatus.PROBLEM:
              translateKey = 'Problem';
              break;
          case benchStatus.INACTIVE:
              translateKey = 'Inactive';
              break;
          default:
              translateKey = 'Inactive';
      }
      return translate(translateKey);
  }

  const openModalHandler = () => setShowModal(true);

  const closeModalHandler = () => setShowModal(false);

  const openBenchModalHandler = () => setShowBenchModal(true);

  const closeBenchModalHandler = () => setShowBenchModal(false);

  const startEditing = () => setEditing(true);

  const finishEditing = () => setEditing(false);

  const confirm = (id) => {
    Modal.confirm({
      title: (
        <span>
          {formatMessage({
            id: 'Are you sure you want to delete this branch?',
          })}
        </span>
      ),
      className: 'users-list__deletion-confirmation-modal',
      icon: <ExclamationCircleOutlined />,
      okText: <span>{formatMessage({ id: 'Confirm' })}</span>,
      cancelText: <span>{formatMessage({ id: 'Cancel' })}</span>,
      maskClosable: true,
      centered: true,
      onOk: async () => {
        await props.userStore.removeBranch(id);
        await props.userStore.loadBranches();
        await props.userStore.loadUsers();
      },
    });
  };

  const branchListItemActions = (id) => (
    <Menu className="users-list__actions">
      {props.authStore.logedUser &&
      props.authStore.logedUser.role === 'superAdmin' ? (
        <Menu.Item key="1" onClick={() => confirm(id)}>
          {translate('Delete Branch')}
        </Menu.Item>
      ) : null}
      {props.authStore.logedUser &&
      props.authStore.logedUser.role === 'superAdmin' ? (
        <Menu.Item key="2">
          <Divider className="users-list__actions-divider" />
        </Menu.Item>
      ) : null}
      <Menu.Item
        key="3"
        onClick={() => {
          startEditing();
          openModalHandler();
        }}
      >
        {translate('Edit Branch')}
      </Menu.Item>
      {props.authStore.logedUser &&
      props.authStore.logedUser.role === 'superAdmin' ? (
        <Menu.Item key="4">
          <Divider className="users-list__actions-divider" />
        </Menu.Item>
      ) : null}
      {props.authStore.logedUser &&
      props.authStore.logedUser.role === 'superAdmin' ? (
        <Menu.Item
          key="5"
          onClick={() => {
            openBenchModalHandler();
          }}
        >
          {translate('Add/Remove Bench')}
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <div className="branch-list__container">
      <Modal
        className="branch-form__modal"
        visible={showModal}
        onCancel={closeModalHandler}
        maskClosable={false}
        closable={false}
        centered
        title={
          <h3 className="branch-form__modal-title">
            {translate('Edit Branch')}
          </h3>
        }
        footer={false}
      >
        <div className="clients-form__tabs">
          <BranchForm
            closeModalHandler={closeModalHandler}
            store={props.userStore}
            branchId={branchId}
            editing={editing}
            finishEditing={finishEditing}
          />
        </div>
      </Modal>
      <Modal
        className="branch-client-form__modal"
        visible={showBenchModal}
        onCancel={closeBenchModalHandler}
        maskClosable={false}
        closable={false}
        centered
        title={
          props.userStore.branch.name ? (
            <h3 className="branch-client-form__modal-title">
              {props.userStore.branch.name} {translate('benches')}
            </h3>
          ) : (
            <Spinner
              indicator={<LoadingOutlined style={{ fontSize: 10 }} spin />}
            />
          )
        }
        footer={false}
      >
        <div className="clients-form__tabs">
          <BranchBenchForm
            closeModalHandler={closeBenchModalHandler}
            benchStore={props.benchStore}
            userStore={props.userStore}
            branchId={branchId}
          />
        </div>
      </Modal>
      <If condition={branches && branches.length === 0}>
        <div
          style={{ width: '100%' }}
          className="users-list__spinner-container"
        >
          <Empty description={<span>{translate('No branches found.')}</span>} />
        </div>
      </If>
      <div className="branch-list__cards-container">
        {!branches ? (
          <div
            style={{ width: '100%' }}
            className="users-list__spinner-container"
          >
            <Spinner
              indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
              size="large"
              tip={translate('Loading branches...')}
            />
          </div>
        ) : (
          branches.map((branch) => (
            <div key={branch.id} className="branch-list__card-container">
              <Card className="branch-list__card">
                <div className="branch-list__name-action-btn-container">
                  <span className="branch-list__name">{branch.name}</span>
                  <span className="users-list__table-cell">
                    <div
                      className="users-list__actions-dropdown-button"
                      onClick={() => setBranchId(branch.id)}
                    >
                      <Tooltip
                        placement="topRight"
                        color="#005450"
                        title={translate('Options')}
                      >
                        <Dropdown.Button
                          trigger="click"
                          overlay={branchListItemActions(branch.id)}
                        />
                      </Tooltip>
                    </div>
                  </span>
                </div>
                <div className="branch-list__benches-label">
                  <span>{translate('Benches')}:</span>
                </div>
                <div className="branch-list__benches-container">
                  {branch.benches?.length > 0 ? (
                    branch.benches?.sort((a, b) => a.givenId - b.givenId)
                      .map((bench) => (
                      <div key={bench.id} className="branch-list__benches-list">
                        <span className="branch-list__benchId">
                          {bench.givenId}
                        </span>
                        <span className="branch-list__status-tag">
                          <Tag
                            borderWidth={0}
                            color={getBenchLabelColor(bench.status)}
                            text={getBenchLabelText(bench.status)}
                          />
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="branch-list__empty-bench-list">
                      <span>
                        {translate('There are no benches under this branch.')}
                      </span>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default inject('UsersStore')(observer(BranchList));
