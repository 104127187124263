import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "mobx-react-lite/batchingForReactDom";
import { Provider } from "mobx-react";
// import { I18nProvider, LOCALES } from "./i18n";

import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import UsersStore from "./stores/users/usersStore";
import AuthStore from "./stores/auth/authStore";
import BenchStore from "./stores/bench/benchStore";
import LocalesStore from "./stores/locales/localesStore";
import ContentStore from "./stores/content/contentStore";

const stores = {
  UsersStore,
  BenchStore,
  AuthStore,
  LocalesStore,
  ContentStore,
};

ReactDOM.render(
  <Provider {...stores}>
    <Router>
      {/* <I18nProvider locale={LOCALES.ENGLISH}> */}
      <App />
      {/* </I18nProvider> */}
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
