import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Auth from '../../../../utils/auth';
import {
    Form,
    Input,
    Upload,
    Row,
    Col,
    DatePicker,
    Tree,
    notification,
    Progress,
    ConfigProvider,
} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import translate from '../../../../i18n/translate';
import {inject, observer} from 'mobx-react';
import UploadIcon from '../../../../Icons/UploadIcon';
import {useIntl} from 'react-intl';
import 'moment/locale/sr';

import localeEN from 'antd/lib/locale/en_US';
import localeSR from 'antd/lib/locale/sr_RS';

import ContentFormFooter from './ContentFormFooter/content-form-footer.component';
import './content-form.style.scss';

const {RangePicker} = DatePicker;
const {Dragger} = Upload;

const ContentForm = (props) => {
    const [form] = Form.useForm();
    const [treeData, setTreeData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [disabledButton, setDisabledButton] = useState(true);
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [checkedBenches, setCheckedBenches] = useState(null);
    const [showItem, setShowItem] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const {formatMessage, locale} = useIntl();
    const MAX_CONTENT_IMAGE_WIDTH = 2580;
    const MAX_CONTENT_IMAGE_HEIGHT = 1440;

    const languageMap = {
        'en-us': localeEN,
        'sr-sp': localeSR,
    };

    const getSelectedLanguage = async () => {
        const selected = await localStorage.getItem('language');
        setSelectedLanguage(selected);
    };

    useEffect(() => {
        if (props.authStore.logedUser) {
            const branches = props.authStore.logedUser.branches.map((branch) => {
                const benches = branch.benches.map((bench) => {
                    return {
                        title: bench.givenId,
                        key: bench.id,
                    };
                }).sort((firstBench, secondBench) => firstBench.title - secondBench.title);

                return {
                    title: branch.name,
                    key: branch.name,
                    children: benches,
                    disableCheckbox: benches.length === 0,
                };
            });

            setTreeData(branches);
        }

        return () => {
        };
    }, [props.authStore.logedUser]);

    useEffect(() => {
        getSelectedLanguage();
    }, [localStorage.language]);

    const removeUploadFile = () => {
        let formValues = form.getFieldsValue();
        formValues.upload = undefined;
        setFileList([]);
        setShowItem(false);
        form.setFieldsValue(formValues);
        setDisabledButton(true);
    };

    const uploadContentHandler = async (e) => {
        var _URL = window.URL || window.webkitURL;
        var _file = e.file;

        const token = Auth.getToken();
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

                if (percent < 100) {
                    setProgress(percent);
                }
            },
        };

        const fileData = new FormData();

        if (_file.type.includes('image')) {
            const img = new Image();
            img.src = _URL.createObjectURL(_file);

            img.onload = function () {
                if (this.width > MAX_CONTENT_IMAGE_WIDTH || this.height > MAX_CONTENT_IMAGE_HEIGHT) {
                    let formValues = form.getFieldsValue();
                    formValues.upload = undefined;
                    form.setFieldsValue(formValues);
                    setTimeout(() => setFileList([]), 110);
                    notification.error({
                        message:
                            selectedLanguage === 'english'
                                ? 'Error uploading image!'
                                : 'Problem prilikom učitavanja slike',
                        description:
                            selectedLanguage === 'english'
                                ? `This image is too big. Try with a smaller image(${MAX_CONTENT_IMAGE_WIDTH}x${MAX_CONTENT_IMAGE_HEIGHT} or less).`
                                : `Format slike je prevelik. Molimo izaberite sliku manju od (${MAX_CONTENT_IMAGE_WIDTH}x${MAX_CONTENT_IMAGE_HEIGHT} ili manju)`,
                    });
                } else {
                    fileData.append('file', _file);

                    axios
                        .post(
                            `${process.env.REACT_APP_API_HOST}/uploads`,
                            fileData,
                            options
                        )
                        .then((res) => {
                            if (res.data.key) {
                                props.contentStore.awsContentKey = res.data.key;
                            }
                            setProgress(100);
                            setTimeout(() => {
                                setProgress(0);
                                setDisabledButton(false);
                            }, 1000);
                            setTimeout(() => setShowItem(true), 1500);
                        });
                }
            };
        } else {
            fileData.append('file', _file);

            axios
                .post(`${process.env.REACT_APP_API_HOST}/uploads`, fileData, options)
                .then((res) => {
                    if (res.data.key) {
                        props.contentStore.awsContentKey = res.data.key;
                    }
                    setProgress(100);
                    setTimeout(() => {
                        setProgress(0);
                        setDisabledButton(false);
                    }, 1000);
                    setTimeout(() => setShowItem(true), 1500);
                });
        }
    };

    const handleChange = (info) => {
        setTimeout(() => {
            let fileList = [...info.fileList];

            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-2);

            // 2. Read from response and show file link
            fileList = fileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                }
                return file;
            });

            setFileList([...fileList]);
        }, 100);
    };

    const saveContent = async (values) => {
        const _branches = props.authStore.logedUser.branches.map(
            (branch) => branch.name
        );
        console.log("values", values)
        const _benches = {
            benches: checkedBenches
                .filter((bench) => !_branches.includes(bench))
                .map((bench) => {
                    return {
                        id: bench,
                    };
                }),
        };

        setLoading(true);
        if (props.contentStore.awsContentKey) {
            const contentData = {
                name: values.contentName,
                startDate: values.startEndTime[0],
                endDate: values.startEndTime[1],
                key: props.contentStore.awsContentKey,
                type: values.upload.file.type.split('/')[0],
            };

            await props.contentStore.addContent(contentData);

            if (props.contentStore.contentId) {
                const _contentId = props.contentStore.contentId;

                await props.contentStore.addContentOnBenches(_contentId, _benches);
            }

            await setTimeout(() => {
                form.getFieldsValue().upload = undefined;
                props.closeModalHandler();
                form.resetFields();
                setFileList([]);
                setProgress(0);
                setDisabledButton(true);
                setCheckedBenches(null);
                setLoading(false);
            }, 1000);

            await props.contentStore.loadContent();
        }
    };

    const cancelUpload = async () => {
        await props.closeModalHandler();
        form.resetFields();
        setFileList([]);
        setProgress(0);
        setCheckedBenches([]);
        setDisabledButton(true);
        setShowItem(false);
    };

    return (
        <div className="content-form">
            <ConfigProvider locale={languageMap[locale.toLowerCase()]} form={form}>
                <Form form={form} onFinish={saveContent}>
                    <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                        <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                name="contentName"
                                label={translate('Content Name')}
                                rules={[
                                    {
                                        required: true,
                                        message: translate('Please enter content name.'),
                                        min: 1,
                                        max: 99,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={
                                        selectedLanguage === 'english'
                                            ? 'Enter your name here'
                                            : 'Unesite vaše ime'
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="startEndTime"
                                style={{width: '100%'}}
                                label={translate('Start and end time')}
                                rules={[
                                  {
                                    required: true,
                                    message: translate('Please select start and end time.'),
                                  },
                                ]}
                            >
                                <RangePicker picker="date" size="middle"
                                />
                            </Form.Item>
                            <Form.Item style={{marginBottom: 0}} name="upload">
                                <Dragger
                                    disabled={
                                        form.getFieldsValue().upload ||
                                        !(checkedBenches && checkedBenches.length > 0)
                                    }
                                    beforeUpload={(fileList, file) => {
                                        if (file[0].size / 1024 / 1024 > 50) {
                                            notification.error({
                                                message:
                                                    selectedLanguage === 'english'
                                                        ? 'Error uploading file!'
                                                        : 'Problem prilikom učitavanja datoteke',
                                                description:
                                                    selectedLanguage === 'english'
                                                        ? 'This file is too big. Try with the smaller file(less then 50MB).'
                                                        : 'Datoteka je prevelika. Molimo izaberite manju (manju od 50MB)',
                                            });
                                            throw Error;
                                        }
                                    }}
                                    onChange={handleChange}
                                    fileList={fileList}
                                    showUploadList={false}
                                    customRequest={uploadContentHandler}
                                    accept=".png,.jpg,.jpeg,.avi,.mp4,.gif"
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UploadIcon/>
                                    </p>
                                    <p className="content-form__dragger-paragraph">
                                        {translate('Drop content here or')}{' '}
                                        <span>{translate('browse')}</span>
                                    </p>
                                    {!(
                                        (checkedBenches && checkedBenches.length > 0) ||
                                        fileList.length > 0
                                    ) && (
                                        <small className="content-form__dragger-warning">
                                            ({translate('You have to select branch or bench first!')})
                                        </small>
                                    )}
                                </Dragger>
                            </Form.Item>
                            <div style={{height: '20px'}}>
                                {progress > 0 && (
                                    <Progress
                                        style={{marginBottom: '24px'}}
                                        strokeColor={{
                                            '0%': '#33cc6669',
                                            '100%': '#33CC66',
                                        }}
                                        showInfo={true}
                                        percent={progress}
                                    />
                                )}
                                {fileList &&
                                fileList.length > 0 &&
                                fileList.length < 2 &&
                                showItem ? (
                                    <div className="content-form__file-name-container">
                                        <p
                                            style={{marginBottom: 0}}
                                            className="content-form__dragger-paragraph"
                                        >
                                            {fileList[0].name}
                                        </p>
                                        <DeleteOutlined onClick={removeUploadFile}/>
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                        <Col className="gutter-row" xl={12} lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                className="content-form__tree-container"
                                name="benches"
                                label={translate('Bench list')}
                            >
                                {treeData ? (
                                    <Tree
                                        checkable
                                        selectable={false}
                                        treeData={treeData}
                                        onCheck={(checkedKeys, info) =>
                                            setCheckedBenches(checkedKeys)
                                        }
                                    />
                                ) : (
                                    <p>{translate('No bench to show')}</p>
                                )}
                            </Form.Item>
                            {fileList &&
                            fileList.length > 0 &&
                            checkedBenches &&
                            checkedBenches.length === 0 && (
                                <small className="content-form__dragger-warning">
                                    ({translate('You have to select branch or bench first!')})
                                </small>
                            )}
                        </Col>
                    </Row>
                    <Form.Item>
                        <div className="ant-modal-footer" style={{padding: 0}}>
                            <ContentFormFooter
                                closeModalHandler={cancelUpload}
                                disabled={disabledButton || checkedBenches.length === 0}
                                loading={loading}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </ConfigProvider>
        </div>
    );
};

export default inject('ContentStore')(observer(ContentForm));
