import React, { useState, useEffect } from 'react';
import { Form, Row, Col, DatePicker } from 'antd';

import EditContentFormFooter from './EditContentFormFooter/edit-content-form-footer.component';
import './edit-content-form.style.scss';
import translate from '../../../../i18n/translate';

const { RangePicker } = DatePicker;

const EditContentForm = (props) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(props.startEndDate);

    return () => form.resetFields();
  }, [form, props.startEndDate]);

  const initialValues = {
    startEndDate: [props.startEndDate.startDate, props.startEndDate.endDate],
  };

  const closeModalHandler = async () => {
    props.closeModalHandler();
    setDisabled(true);
    await form.resetFields();
  };

  const updateContent = async (values) => {
    setLoading(true);
    const contentData = {
      startDate: values.startEndDate[0],
      endDate: values.startEndDate[1],
    };

    await props.store.updateContent(props.contentId, contentData);
    await props.store.loadContent();
    await closeModalHandler();
    await setLoading(false);
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      className="edit-content-form"
      onFinish={updateContent}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            className="edit-content-form__range-picker-container"
            name="startEndDate"
            label={translate('Start and end time')}
          >
            <RangePicker
              onChange={(date) => {
                if (date) {
                  setDisabled(false);
                } else {
                  setDisabled(true);
                }
              }}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <div className="ant-modal-footer" style={{ padding: 0 }}>
          <EditContentFormFooter
            loading={loading}
            disabled={disabled}
            closeModalHandler={closeModalHandler}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditContentForm;
