import BenchForm from "./bench-form.component";
import {Modal as AntModal} from "antd";
import React from "react";
import {useIntl} from "react-intl";

const BenchFormModal = ({ onCancel, visible = false, bench = null }) => {
    const { formatMessage } = useIntl();
    const title = bench ?
        (`${formatMessage({ id: 'Edit bench' })} #${bench.givenId}`) :
        formatMessage({ id: 'Add Bench' });

    return (
        <AntModal
            className="benches-screen__add-bench-modal"
            visible={visible}
            onCancel={onCancel}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            centered
            title={
                <h3 className="benches-form__add-bench-modal-title">{title}</h3>
            }
            footer={false}
        >
            <BenchForm bench={bench} closeModalHandler={onCancel} />
        </AntModal>
    );
}

export default BenchFormModal;
