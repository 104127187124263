import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import translate from '../../../../i18n/translate';
import NotificationList from './NotificationList';

import './notifications-list.style.scss';

const NotificationsList = (props) => {
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    if (props.notifications) {
      setNotifications(props.notifications);
    }
  }, [
    props.store.logedUser,
    props.notifications,
    props.unreadNotifications,
    props.profile,
  ]);

  return (
    <div className="list__container">
      <List
        size="small"
        className="notifications-list"
        bordered
        dataSource={
          notifications && notifications.length > 0
            ? notifications
            : [translate('There are no new notifications!')]
        }
        renderItem={(item) => (
          <List.Item
            style={{
              borderBottom:
                notifications && notifications.length === 0 ? 0 : false,
            }}
            className="notifications-list__item"
          >
            {item && item.read === false ? (
              <strong>{translate(NotificationList[item.message])}</strong>
            ) : item && item.read === true ? (
              translate(NotificationList[item.message])
            ) : (
              translate('There are no new notifications!')
            )}
          </List.Item>
        )}
      />
    </div>
  );
};

export default NotificationsList;
