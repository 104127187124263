import React from 'react';
import { Col, Row } from 'antd';
import translate from '../../../../../i18n/translate';

import Button from '../../../../ui-elements/Button/button.component';
import './edit-content-form-footer.style.scss';

const EditContentFormFooter = (props) => {
  return (
    <div className="edit-content-form__modal-footer">
      <Row align="middle" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xl={6} lg={6} md={7} sm={12} xs={12}>
          <Button
            size="large"
            type="primary"
            disabled={props.disabled}
            loading={props.loading}
            btnText={translate('Save')}
            block
            htmlType="submit"
          />
        </Col>
        <Col xl={4} lg={4} md={4} sm={8} xs={8}>
          <span
            onClick={props.closeModalHandler}
            className="edit-content-form__modal-footer-cancel"
          >
            {translate('Cancel')}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default EditContentFormFooter;
