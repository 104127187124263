import React from "react";
import { Form, Input, Row, Col } from "antd";
import { inject, observer } from "mobx-react";
import translate from "../../../i18n/translate";

import Card from "../../ui-elements/Card/card.component";
import Button from "../../ui-elements/Button/button.component";

import "./forgot-password.style.scss";
import { useHistory } from "react-router-dom";

const ForgotPassword = (props) => {
  const {push} = useHistory();
  const onFinish = (values) => {
    const userData = {
      email: values.email,
    };
    props.store.forgotPassword(userData).then(()=>push("/login"));
  };


  return (
    <div className="forgot-password__container">
      <Row justify="center">
        <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={23}>
          <Card className="forgot-password__card">
            <Row style={{ height: "100%" }}>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={0}
                xs={0}
                className="forgot-password__about-section"
              ></Col>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className="forgot-password__form-container"
              >
                <h1 className="forgot-password__login-title">
                  {translate("Forgot password?")}
                </h1>
                <p className="forgot-password__action-description">
                  {translate(
                    'Enter the email address you used when you joined and we’ll send you instructions to reset your password.'
                  )}
                </p>
                <Form
                  name="forgot-password"
                  onFinish={onFinish}
                >
                  <Input.Group className="forgot-password__input-group">
                    <Form.Item
                      name="email"
                      label={translate("Email Address")}
                      rules={[
                        {
                          type: "email",
                          message: translate('Please enter a valid E-mail.'),
                        },
                        {
                          required: true,
                          message: translate('Please enter your E-mail!'),
                        },
                      ]}
                    >
                      <Input
                        className="forgot-password__input"
                        placeholder="Enter your email here"
                      />
                    </Form.Item>
                    <Row
                      className="forgot-password__footer"
                      justify="space-between"
                    >
                      <Col xl={6} lg={7} md={7} sm={7} xs={8}>
                        <Button
                          btnStyle={{ float: "right" }}
                          size="large"
                          type="primary"
                          block
                          btnText={<span>{translate('Send')}</span>}
                          htmlType="submit"
                        />
                      </Col>
                    </Row>
                  </Input.Group>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default inject("AuthStore")(observer(ForgotPassword));
