import cookie from 'js-cookie';
import instance from '../../lib/axiosConfig';
import { message, notification } from 'antd';
import { runInAction, action, observable, decorate } from 'mobx';
import translate from '../../i18n/translate';

class AuthStore {
  logedUser = {};

  loginUser = async (userData) => {
    try {
      const result = await instance.post('/auth/login', userData);
      console.log('>>>', result);
      if (result && result.data) {
        const { access_token } = result.data;

        if (result.data.access_token) {
          localStorage.setItem('show-adblock-warning', 'show');
          cookie.set('token', access_token);
          // cookie.set('refresh_token', refresh_token);

          // let decodedToken = jwt.decode(access_token);

          return (window.location.href = '/home');
        }
      }
    } catch (err) {
      console.log(err.response);
      const {
        status,
        data: { error },
      } = err.response;
      if (status === 401 && error === 'wrongcredentials') {
        return {
          error: status,
          message: translate('No user with this credentials.'),
        };
      }
    }
  };

  resetPassword = async (userId, userData) => {
    try {
      await message.loading('Reseting...');
      const result = await instance.put(
        `/users/${userId}/resetpassword`,
        userData
      );

      if (result) {
        await message.success('You have changed your password successfully!');
        return (window.location.href = '/home');
      }
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error occured!',
          description:
            'There is an error occured while trying to reset password. Please check your passwords again',
        });
      });
    }
  };

  forgotPassword = async (userData) => {
    try {
      await message.loading('Sending...');
      const result = await instance.post(`/users/forgetpassword`, userData);
    } catch(e){
      console.log(e);
    } finally {
      await notification.success({
        message: 'Success!',
        description:
          'Your request is sent successfully! Check your inbox to see new password.',
      });
    }
  };

  checkPassword = async (password) => {
    try {
      await instance.post('/users/check-password', {
        password
      });
    } catch (err) {
      if(err.response.status === 400) {
        return Promise.reject('wrongPassword');
      }
      notification.error({
        message: 'Error occurred!',
        description: 'Error occurred while trying to check the password, it could be up to the server.',
      });
      return Promise.reject('internalServerError');
    }
  }

  getProfile = async () => {
    try {
      const result = await instance.get('/users/profile');
      let benches = [];
      if (result && result.data) {
        result.data.branches.map((branch) => {
          branch.benches.map((bench) => {
            benches.push({ id: bench.id, givenId: bench.givenId });
          });
        });
        runInAction(() => {
          this.logedUser = { ...result.data, benches };
        });

        return result.data.branches;
      }
    } catch (err) {
      runInAction(() => {
        notification.error({
          message: 'Error occured!',
          description: 'Please log in first!',
        });
        if (
          window.location.pathname !== '/login' ||
          window.location.pathname !== '/forgot-password'
        ) {
          return (window.location.href = '/login');
        }
      });
    }
  };
}

decorate(AuthStore, {
  loggedUser: observable,
  loginUser: action,
  resetPassword: action,
  forgotPassword: action,
});

export default new AuthStore();
