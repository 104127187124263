import React, { useEffect, useState } from 'react';
import {
  Menu,
  Divider,
  Dropdown,
  Col,
  Row,
  Modal,
  Empty,
  Tooltip,
  Select,
} from 'antd';
import { inject, observer } from 'mobx-react';
import If from '../../../utils/If/If';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import translate from '../../../i18n/translate';
import { useIntl } from 'react-intl';

import Card from '../../ui-elements/Card/card.component';
import Spinner from '../../ui-elements/Spinner/spinner.component';
import ClientsForm from './ClientsForm/clients-form.component';
import ClientBranchForm from './ClientBranchForm/client-branch-form.component';
import './users-list.style.scss';
import {USERS_SCREEN_NAME} from "../../../stores/users/availableScreens";

const UsersList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(undefined);
  const [editing, setEditing] = useState(false);
  const [showBranchModal, setShowBranchModal] = useState(false);

  const { formatMessage } = useIntl();

  useEffect(() => {
    props.store.loadUsers();
  }, [props.store]);

  useEffect(() => {
      props.store.setCurrentActiveScreen(USERS_SCREEN_NAME);
  });

  const openModalHandler = () => setShowModal(true);

  const closeModalHandler = () => setShowModal(false);

  const openBranchModalHandler = () => setShowBranchModal(true);

  const closeBranchModalHandler = () => setShowBranchModal(false);

  const startEditing = () => setEditing(true);

  const finishEditing = () => setEditing(false);

  const updateUserStatus = async (value, userId) => {
    const userData = {
      active: value,
    };
    try {
      await props.store.updateUser(userId, userData);
    } catch (error) {
      console.log(error);
    }
  };

  const { Option } = Select;

  const confirm = (id) => {
    Modal.confirm({
      title: formatMessage({
        id: 'Are you sure you want to delete this client?',
      }),
      className: 'users-list__deletion-confirmation-modal',
      icon: <ExclamationCircleOutlined />,
      okText: <span>Confirm</span>,
      cancelText: <span>Cancel</span>,
      maskClosable: true,
      centered: true,
      onOk: () => {
        return Promise.resolve(props.store.removeUser(id)).then(() =>
          props.store.loadUsers()
        );
      },
    });
  };

  const userListItemActions = (id) => (
    <Menu className="users-list__actions">
      <Menu.Item key="1" onClick={() => confirm(id)}>
        {translate('Delete Client')}
      </Menu.Item>
      <Menu.Item key="2">
        <Divider className="users-list__actions-divider" />
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          startEditing();
          openModalHandler();
        }}
      >
        {translate('Edit Client')}
      </Menu.Item>
      <Menu.Item key="4">
        <Divider className="users-list__actions-divider" />
      </Menu.Item>
      <Menu.Item key="5" onClick={() => openBranchModalHandler()}>
        {translate('Add/Remove Branch')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Modal
        className="clients-form__modal"
        visible={showModal}
        onCancel={closeModalHandler}
        closable={false}
        maskClosable={false}
        centered
        title={
          <h3 className="clients-form__modal-title">
            {translate('Edit Client')}
          </h3>
        }
        footer={false}
      >
        <div className="clients-form__tabs">
          <ClientsForm
            closeModalHandler={closeModalHandler}
            store={props.store}
            userId={userId}
            editing={editing}
            finishEditing={finishEditing}
          />
        </div>
      </Modal>
      <Modal
        className="client-branch-form__modal"
        visible={showBranchModal}
        onCancel={closeBranchModalHandler}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        centered
        title={
          props.store.user.name ? (
            <h3 className="client-branch-form__modal-title">
              {props.store.user.name} {translate('branches')}
            </h3>
          ) : (
            <Spinner
              indicator={<LoadingOutlined style={{ fontSize: 10 }} spin />}
            />
          )
        }
        footer={false}
      >
        <div className="clients-form__tabs">
          <ClientBranchForm
            closeModalHandler={closeBranchModalHandler}
            store={props.store}
            userId={userId}
          />
        </div>
      </Modal>
      <If condition={props.store.users && props.store.users.length === 0}>
        <div className="users-list__spinner-container">
          <Empty description={<span>{translate('No users found.')}</span>} />
        </div>
      </If>
      <div className="users-list__cards-container">
        {props.store.users ? (
          props.store.users.map((user) => (
            <Card className="users-list__card" key={user.id}>
              <Row
                className="users-list__table"
                align="middle"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              >
                <Col xl={5} lg={4} md={18} sm={18} xs={16}>
                  <span className="users-list__table-cell">{user.name}</span>
                </Col>
                <Col xl={5} lg={5} md={18} sm={18} xs={16}>
                  <span className="users-list__table-cell">{user.address}</span>
                </Col>
                <Col xl={4} lg={3} md={18} sm={18} xs={16}>
                  <span className="users-list__table-cell">{user.phone}</span>
                </Col>
                <Col xl={6} lg={7} md={18} sm={18} xs={16}>
                  <span className="users-list__table-cell">{user.email}</span>
                </Col>
                <Col
                  xl={2}
                  lg={2}
                  md={20}
                  sm={20}
                  xs={16}
                  className="users-list__tag-container"
                >
                  <div className="user_status_container">
                    <Select
                      className="select_user_status"
                      dropdownClassName="select_status_wrapper"
                      showArrow={false}
                      defaultValue={
                        user && user.active === true
                          ? translate('Active')
                          : translate('Inactive')
                      }
                      onSelect={(value) => updateUserStatus(value, user.id)}
                    >
                      <Option
                        value={true}
                        key="1"
                        className="select_option_element"
                      >
                        {translate('Active')}
                      </Option>
                      <Option value={false} key="2">
                        {translate('Inactive')}
                      </Option>
                    </Select>
                  </div>
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={8}>
                  <span className="users-list__table-cell">
                    <div
                      className="users-list__actions-dropdown-button"
                      onClick={() => setUserId(user.id)}
                    >
                      <Tooltip
                        placement="topRight"
                        color="#005450"
                        title={translate('Options')}
                      >
                        <Dropdown.Button
                          trigger="click"
                          overlay={userListItemActions(user.id)}
                        />
                      </Tooltip>
                    </div>
                  </span>
                </Col>
              </Row>
            </Card>
          ))
        ) : (
          <div className="users-list__spinner-container">
            <Spinner
              indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
              size="large"
              tip={translate('Loading clients...')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default inject('UsersStore')(observer(UsersList));
