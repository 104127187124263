import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Input } from 'antd';
import translate from '../../../../i18n/translate';
// import { InfoCircleOutlined } from "@ant-design/icons";

import BranchFormFooter from './BranchFormFooter/branch-form-footer.component';
import './branch-form.style.scss';

const BranchForm = (props) => {
  const [form] = Form.useForm();
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const getSelectedLanguage = async () => {
    const selected = await localStorage.getItem('language');
    setSelectedLanguage(selected);
  };

  useEffect(() => {
    if (props.editing) {
      form.setFieldsValue(props.store.branch);
    }

    return () => {
      form.resetFields();
    };
  });

  useEffect(() => {
    getSelectedLanguage();
  }, [localStorage.language]);

  const initialValues = {
    name: '',
    note: '',
  };

  const initialValuesEditing = {
    name: props.store.branch.name,
    note: props.store.branch.note,
  };

  const addBranch = async (values) => {
    const branch = {
      name: values.name,
      note: values.note,
    };

    await props.store.addBranch(branch);
    await props.store.loadBranches();
    await props.store.loadUsers();
    form.resetFields();
    props.closeModalHandler();
  };

  const updateBranch = async (values) => {
    const branch = {
      name: values.name,
      note: values.note,
    };

    await props.store.updateBranch(props.store.branch.id, branch);
    await props.store.loadBranches();
    form.resetFields();
    props.closeModalHandler();
  };

  return (
    <Form
      form={form}
      initialValues={props.editing ? initialValuesEditing : initialValues}
      className="branch-form"
      onFinish={props.editing ? updateBranch : addBranch}
    >
      <Input.Group>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              name="name"
              label={translate('Name')}
              rules={[
                {
                  required: true,
                  message: translate('Please enter branch name!'),
                  min: 1,
                  max: 99,
                },
              ]}
            >
              <Input
                placeholder={
                  selectedLanguage === 'english'
                    ? 'Enter branch name here'
                    : 'Unesite ime poslovnice'
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      {/* <Input.Group>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              name="owner"
              label={translate('Owner')}
              rules={[
                {
                  //required: true,
                  message: translate('Please enter branch owner\'s name!'),
                  min: 1,
                  max: 99,
                },
              ]}
            >
                <Input placeholder="Enter branch owner's name here" />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group> */}
      <Input.Group>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <Form.Item name="note" label={translate('Notes')}>
              <Input.TextArea
                placeholder={
                  selectedLanguage === 'english'
                    ? 'Enter your notes (optional)'
                    : 'Unesite bilješke (opcionalno)'
                }
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Form.Item>
        <div className="ant-modal-footer" style={{ padding: 0 }}>
          <BranchFormFooter
            closeModalHandler={props.closeModalHandler}
            resetFields={() => {
              Object.assign(initialValuesEditing, initialValues);
              form.resetFields();
            }}
            editing={props.editing}
            store={props.store}
            finishEditing={props.finishEditing}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default BranchForm;
