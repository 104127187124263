import React from 'react';
import { Row, Col } from 'antd';
import translate from '../../../../../i18n/translate';

import Button from '../../../../ui-elements/Button/button.component';
import './bench-form-footer.style.scss';

const BenchFormFooter = (props) => {
  return (
    <div className="bench-form__modal-footer">
      <Row align="middle" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xl={8} lg={8} md={9} sm={10} xs={12}>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={props.loading}
            btnText={translate('Save')}
            block
          />
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={0}>
          <span
            onClick={props.closeModalHandler}
            className="bench-form__modal-footer-cancel"
          >
            {translate('Cancel')}
          </span>
        </Col>
        <Col xl={4} lg={4} md={3} sm={2} xs={0}></Col>
        <Col xl={7} lg={7} md={7} sm={7} xs={12}>
          <span
            className="bench-form__modal-footer-clear-fields"
            onClick={props.resetFields}
          >
            {translate('Clear All Fields')}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default BenchFormFooter;
