import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { LoadingOutlined } from '@ant-design/icons';
import benchStore from '../../../stores/bench/benchStore';
import translate from '../../../i18n/translate';

import Spinner from '../../ui-elements/Spinner/spinner.component';
import mapStyles from './mapStyles';
import mapStylesNight from './mapStylesNight';
import './style.scss';
import { CloseOutlined } from '@ant-design/icons';

const libraries = ['places'];

const activeBench = require('../../../assets/icons/green_dot.png');
const benchNotWorking = require('../../../assets/icons/bench-not-working.png');
const benchProblem = require('../../../assets/icons/bench-problem.png');

const center = {
  lat: 44.7826623,
  lng: 17.1989874,
};

const timeCheck = new Date().getHours();

const options = {
  styles: timeCheck >= 20 || timeCheck < 5 ? mapStylesNight : mapStyles,
  disableDefaultUI: true,
  minZoom: 2,
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
  },
};

const Map = (props) => {
  //TODO: We had problem when use google-maps and google-places api on one place
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   libraries,
  // });

  const [selected, setSelected] = useState(null);
  const [selectedBenchTemperature, setSelectedBenchTemperature] = useState(0);
  const [selectedbenchAirPressure, setSelectedBecnhAirPressure] = useState(0);

  const [selectedBenchumidity, setSelectedBenchHumidity] = useState(0);

  const mapRef = useRef();

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(17);
  }, []);

  const closeInfoWindow = () => setSelected(null);

  useEffect(() => {
    if (props.pan && props.lat && props.lng) {
      panTo({ lat: props.lat, lng: props.lng });
    }
    return () => {};
  }, [props, panTo]);

  const getbenchData = async (id) => {
    try {
      await benchStore.getBench(id);
    } catch (error) {
      console.log(error);
    }

    setSelected(benchStore.bench);

    const temperature = benchStore.bench.temperature;
    const airPressure = benchStore.bench.pressure;
    const humidity = benchStore.bench.humidity;

    const currenTemeprature =
      temperature && temperature[temperature.length - 1];
    setSelectedBenchTemperature(currenTemeprature);

    const currentAirPressure =
      airPressure && airPressure[airPressure.length - 1];
    setSelectedBecnhAirPressure(currentAirPressure);

    const currentHumidity = humidity && humidity[humidity.length - 1];
    setSelectedBenchHumidity(currentHumidity);
  };

  //TODO: We had problem when use google-maps and google-places api on one place
  // if (loadError) {
  //   return translate('Error loading maps...');
  // }
  // if (!isLoaded) {
  //   return (
  //     <div className="map__spinner-container">
  //       <Spinner
  //         indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
  //         size="large"
  //         tip={translate('Loading map...')}
  //       />
  //     </div>
  //   );
  // }

  const iconUrl = (status) => {
    switch (status) {
      case 'inactive':
        return benchNotWorking;
      case 'problem':
        return benchProblem;
      case 'active':
        return activeBench;
      default:
        return benchNotWorking;
    }
  };

  return (
    <div>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: props.height,
          borderRadius: '16px',
          outline: 'none',
        }}
        zoom={8}
        center={center}
        options={options}
        onLoad={onMapLoad}
      >
        {props.benches &&
          props.benches.map((marker) => {
            return (
              marker.location !== null &&
              <Marker
                key={marker.location.id}
                position={{
                  lat: marker.location.latitude,
                  lng: marker.location.longitude,
                }}
                icon={{
                  url: iconUrl(marker.status),
                  origin: new window.google.maps.Point(0, 0),
                }}
                onClick={() => {
                  const { latitude, longitude } = marker.location;
                  getbenchData(marker.id);
                  panTo({ lat: latitude, lng: longitude });
                }}
              />
            );
          })}
        {selected && (
          <InfoWindow
            position={{
              lat: selected.location.latitude,
              lng: selected.location.longitude,
            }}
            onCloseClick={closeInfoWindow}
          >
            <div className="pin-info-wrapper">
              <button className="close-button" onClick={closeInfoWindow}>
                <CloseOutlined />
              </button>
              <p className="pin-bench-id">Bench ID: {selected.givenId}</p>
              <p className="pin-info">
                <span>{translate('Temperature')}:</span>{' '}
                {selectedBenchTemperature ? (
                  ` ${Math.floor(selectedBenchTemperature.temp)}`
                ) : (
                  <span>{translate('Unavailable')}</span>
                )}
              </p>
              <p className="pin-info">
                <span>{translate('Air pressure')}:</span>{' '}
                {selectedbenchAirPressure ? (
                  ` ${selectedbenchAirPressure.pressure}`
                ) : (
                  <span>{translate('Unavailable')}</span>
                )}
              </p>
              <p className="pin-info">
                <span>{translate('Humidity')}:</span>{' '}
                {selectedBenchumidity ? (
                  ` ${selectedBenchumidity.humidity}`
                ) : (
                  <span>{translate('Unavailable')}</span>
                )}
              </p>
              <p className="pin-info">{selected.location.address}</p>
              <p className="pin-info-type">
                {translate(
                  selected.type.charAt(0).toUpperCase() + selected.type.slice(1)
                )}
              </p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;
